import Container from "~/components/Container.tsx";
import Typography from "~/components/Typography.tsx";
import HomePickupCard from "~/pages/home/components/HomePickupCard.tsx";
import BusCard from "~/pages/home/components/BusCard.tsx";
import useKintStore from "~kint/store";
import useTeam from "~/pages/home/hooks/useTeam.ts";
import useKupStore from "~kup/store";

export default function TourInfoSection() {
  const {reservation} = useKintStore((state) => ({
    reservation: state.reservation,
  }));

  const {locale} = useKupStore((state) => ({
    locale: state.auth?.locale ?? 'en-US'
  }))

  const team = useTeam();

  const departureTime = reservation?.departureDate?.getTime() ?? (new Date()).getTime();
  const isStarted = ( departureTime + (15 * 60 * 1000)) < Date.now();
  const showBus = isStarted && !!(team && team.bus && team.bus.number);
  const showPickupInfo = !showBus && !!(reservation && reservation.pickupPlace && reservation.pickupInfo && reservation.pickupInfo.lat && reservation.pickupInfo.lng);
  if (!showPickupInfo && !showBus) return null;
  return (
      <Container
          background={'paper'}
          sx={{
            marginTop: '-12px',
            borderTopLeftRadius: '16px',
            borderTopRightRadius: '16px'
          }}
      >
        <Container
            pt={'16px'}
        >
          <Typography variant={'Caption'} color={'primary'}>
            Tour Information
          </Typography>
        </Container>
        <Container
            stackProps={{
              column: true,
              gap: '20px',
            }}
        >
          {
              (showPickupInfo && reservation && reservation.pickupInfo && showPickupInfo) &&
              <HomePickupCard pickupInfo={reservation.pickupInfo} locale={locale}/>
          }
          {
              (showBus && team && team.bus && team.bus.number) &&
              <BusCard bus={team.bus}/>
          }
        </Container>
      </Container>
  )
}