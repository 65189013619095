import {Slice} from "~common/store";
import Basket from "~kup/models/Basket.ts";
import {BasketChecks, Contact, DeliveryAddress} from "~kup/models/types.ts";
import { PAYMENT_METHOD } from '~/pages/shop/constants';

export interface ShopSlice {
  currency: string,
  freeDeliveryThreshold: number,
  deliveryCutOff: number,
  deliveryFee: number,
  basket: Basket,
  basketChecks: BasketChecks,
  deliveryAddress: DeliveryAddress | null,
  customerName: string,
  customerEmail: string,
  paymentMethod: string,
  inputAddress: string | null,
  contact: Contact | null,
  checkOrderPopup:boolean,
  setCheckOrderPopup: (check:boolean)=>void,
  setCustomerName: (customerName: string) => void,
  setCustomerEmail: (customerName: string) => void,
  setPaymentMethod: (paymentMethod: string) => void,
  setInputAddress: (inputAddress: string) => void,
  setContact: (contact: Contact) => void;
  setDeliveryAddress: (address: DeliveryAddress) => void;
  setBasket: (basket: Basket) => void;
  setBasketCheck: (key: string, check: boolean) => void;
  setBasketChecks: (checks: BasketChecks) => void;
  syncBasket: () => Promise<void>;
}

export interface PersistedShopSlice {
  currency: string,
  freeDeliveryThreshold: number,
  inputAddress: string | null,
  deliveryCutOff: number,
  deliveryFee: number,
  deliveryAddress: DeliveryAddress | null,
  contact: Contact | null,
  basketChecks: BasketChecks,
  customerName: string,
  customerEmail: string,
  checkOrderPopup:boolean,
}

export const persistShopSlice: (state: ShopSlice) => PersistedShopSlice = (state) => ({
  currency: state.currency,
  freeDeliveryThreshold: state.freeDeliveryThreshold,
  inputAddress: state.inputAddress,
  deliveryAddress: state.deliveryAddress,
  contact: state.contact,
  deliveryFee: state.deliveryFee,
  deliveryCutOff: state.deliveryCutOff,
  basketChecks: state.basketChecks,
  customerName: state.customerName,
  customerEmail: state.customerEmail,
  checkOrderPopup:state.checkOrderPopup,
})

export const createShopSlice: Slice<ShopSlice> = (set, get) => ({
  currency: 'KRW',
  freeDeliveryThreshold: 100000,
  deliveryCutOff: 17,
  deliveryFee: 5000,
  customerName: '',
  customerEmail: '',
  paymentMethod: PAYMENT_METHOD[0].value,
  inputAddress: '',
  deliveryAddress: null,
  contact: null,
  basket: Basket.EMPTY_BASKET,
  basketChecks: {},
  checkOrderPopup:false,
  setCheckOrderPopup: (check:boolean)=>set(()=>({checkOrderPopup:check})),
  setCustomerName: (customerName: string) => set(() => ({customerName})),
  setCustomerEmail: (customerEmail: string) => set(() => ({customerEmail})),
  setPaymentMethod: (paymentMethod: string) => set(() => ({paymentMethod})),
  setInputAddress: (inputAddress: string) => set(() => ({inputAddress})),
  setDeliveryAddress: (deliveryAddress: DeliveryAddress) => set(() => ({deliveryAddress})),
  setContact: (contact: Contact) => {
    set(() => ({contact}));
  },
  setBasket: (basket: Basket) => {
    set((state) => {
      const basketItemKeys = basket.items.map((item) => item.key);
      const validBasketChecks = Object.fromEntries(Object.entries(state.basketChecks ?? {}).filter(([key]) => basketItemKeys.includes(key)));
      const newBasketChecks = {...Object.fromEntries(basket.items.map((item) => [`${item.key}`, true])), ...validBasketChecks};
      return ({
        basket,
        basketChecks: newBasketChecks
      });
    })
  },
  setBasketCheck: (itemKey: string, check: boolean) => {
    set((state) => ({basketChecks: {...state.basketChecks, [itemKey]: check}}))
  },
  setBasketChecks: (checks: BasketChecks) => {
    set((state) => ({basketChecks: {...state.basketChecks, ...checks}}));
  },
  syncBasket: async () => {
    const syncedBasket = await get().basket.sync();
    set(() => ({basket: syncedBasket}))
  },

})


