import {kintFunctions} from '~kint/firebase/functions';
import {IChatting, IChattingParams, IChattingParticipant, IChattingTour} from "~kint/models/Chatting";
import Reservation from "~kint/models/Reservation.ts";
import getUserBrowser from "~/utils/getUserBrowser.ts";
import getUserDevice from "~/utils/getUserDevice.ts";
import {encodeToBase64} from "~/utils/base64.ts";


export default async function recallChatting(name:string, reservation: Reservation): Promise<{
  participant: IChattingParticipant | null,
  chatting: IChatting | null
}> {
  if (!reservation) {
    return {participant: null, chatting: null};
  }
  const browser = getUserBrowser()
  const device = getUserDevice()

  const client:IChattingParticipant = {
    type: 'client',
    id: `${reservation.id}::${encodeToBase64(name)}`,
    name: name,
    nameEn: name,  // Kint1 호환성
    photoURL:'https://cdn-icons-png.flaticon.com/512/5894/5894085.png',
    reservationId:reservation.id,
    reservationName:reservation.clientName,
    agent: {
      device,
      browser,
    },
  }
  const chattingParams = parseChatMetadata(client, "CLIENT", {
    team: reservation.teamId,
    date: reservation.date,
    productId: reservation.productId,
  });

  const participant = chattingParams.participant;

  if(reservation.canceledAt) {
    return ({participant, chatting: null,})
  }

  const chatting = await kintFunctions.call<IChatting>('recallComprehensiveTourChat', chattingParams);

  return {participant, chatting}
}


const parseChatMetadata = (
    participant: IChattingParticipant,
    category: string = "CLIENT",
    tour: IChattingTour,
    reservation?: Reservation
): IChattingParams => {
  const {productId, date, team} = tour
  const cId =
      category === "CLIENT-GUIDE" && reservation
          ? `${category}:${productId}:${date}:${team}:${reservation.id}`
          : `${category}:${productId}:${date}:${team}`
  const title =
      category === "CLIENT-GUIDE" && reservation
          ? `${date}:${productId.split("_").pop()}:${reservation.clientName}:GUIDE문의`
          : `${date}:${productId.split("_").pop()}`


  return {
    category,
    participant,
    cId,
    title,
    tour,
  }
}
