import {ReactNode} from "react";
import {Transparent, Paper, isPaper, isTransparent} from "~/components/commons/ColorNames";
import {
    AppBar as MuiAppBar,
    Toolbar as MuiToolbar,
    Box as MuiBox,
    AppBarProps as MuiAppBarProps,
} from "@mui/material";
import useSafeArea from "~/hooks/useSafeArea.ts";

export type Position = {
    position: "absolute" | 'relative' | 'fixed' | 'sticky',
    top?: number | string,
    left?: number | string,
    right?: number | string,
    bottom?: number | string,
    transform?: string,
}

export type NavigationBarProps = {
    position?:Position,
    background?: Paper | Transparent
    lead?: ReactNode,
    trail?: ReactNode,
    center?: ReactNode
} & Omit<MuiAppBarProps, 'position'|'children'>

export default function NavigationBar(props: NavigationBarProps) {
    const {
        position = {position: "sticky", top: 0, left: 0},
        lead,
        center,
        trail,
        background = 'transparent',
        ...restProps
    } = props;
  const {topSafeArea} = useSafeArea();
    return (
        <MuiAppBar
            sx={{
                ...position,
            }}
            {...restProps}
        >
            <MuiToolbar
                sx={(theme) => ({
                    background: isPaper(background) ? theme.palette.background.paper : isTransparent(background) ? 'transparent' : undefined,
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    gap: '12px',
                  paddingTop: topSafeArea ? topSafeArea + 'px' : '0px',
                    paddingBottom: '0px',
                    paddingLeft: '20px',
                    paddingRight: '20px'
                })}
            >
                <MuiBox
                    sx={(theme) => ({
                        flex:1,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        color: theme.palette.typography.primary
                    })}
                >
                    {
                        lead
                    }
                </MuiBox>
                <MuiBox
                    sx={(theme) => ({
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: theme.palette.typography.primary
                    })}
                >
                    {center}
                </MuiBox>
                <MuiBox
                    sx={(theme) => ({
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        color: theme.palette.typography.tertiary
                    })}
                >
                    {trail}
                </MuiBox>
            </MuiToolbar>
        </MuiAppBar>
    )
}