import {useEffect, useState} from "react";
import {ProductCategory} from "~kup/models/Product.ts";
import {getProductCategories} from "~kup/controllers/product.ts";


type UseCategories = { categories: ProductCategory[] };

export default function useProductCategories(): UseCategories {
  const [categories, setCategories] = useState<ProductCategory[]>([]);
  useEffect(() => {
    getProductCategories().then((productCategories) => setCategories(productCategories))
  }, []);
  return {categories}
}
