import {Address} from '~kup/models/types.ts';
import {useCallback, useEffect, useState} from 'react';
import {searchAddress} from '~kup/controllers/search.ts';
import debounce from 'lodash/debounce';

export default function useSearchAddress(search: string): Address[] {
  const [addresses, setAddresses] = useState<Address[]>([]);
  const debouncedSearch = useCallback(debounce((search) => {
    searchAddress(search).then(setAddresses);
  }, 300), []);

  useEffect(() => {
    search && debouncedSearch(search);
  }, [search]);
  return addresses;
}