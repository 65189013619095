import {Slice} from "~common/store";

export interface NotificationSlice {
  token: string | null,
  setToken: (token: string | null) => void,
  isNotificationPermissionRequested: boolean,
  isNotificationDisabled:boolean,
  setIsNotificationDisabled:(disableNotification:boolean)=>void,
  setIsNotificationPermissionRequested: (isNotificationPermissionRequested: boolean) => void,
  stopRequestNotificationPermission: number,
  setStopRequestNotificationPermission: (stopRequestNotificationPermission: number) => void,
}

export interface PersistedNotificationSlice {
  isNotificationPermissionRequested: boolean,
  stopRequestNotificationPermission: number,
  isNotificationDisabled:boolean,
}

export const persistNotificationSlice: (state: NotificationSlice) => PersistedNotificationSlice = (state: NotificationSlice) => ({
  isNotificationPermissionRequested: state.isNotificationPermissionRequested,
  stopRequestNotificationPermission: state.stopRequestNotificationPermission,
  isNotificationDisabled:state.isNotificationDisabled,

})


export const createNotificationSlice: Slice<NotificationSlice, NotificationSlice> = (set) => ({
  token: null,
  isNotificationPermissionRequested: false,
  stopRequestNotificationPermission: 0,
  isNotificationDisabled:false,
  setIsNotificationDisabled:(isNotificationDisabled:boolean)=>set({isNotificationDisabled}),
  setToken: (token: string | null) => set({token}),
  setIsNotificationPermissionRequested: (isRequested: boolean) => set({isNotificationPermissionRequested: isRequested}),
  setStopRequestNotificationPermission: (stopRequestNotificationPermission: number) => set({stopRequestNotificationPermission}),
})
