import { useLayoutEffect, useState } from 'react';
import getTimeRemainUntilKST from '~/utils/getTimeRemainsUntilKST.ts';

type TimeRemains = {
  hours: number,
  minutes: number,
  seconds: number,
  text:string,
}

type CutOffDay = 'today' | 'tomorrow' | number;

type TimeoutCutOff = {
  cutOffTime: number,
  timeUntilCutOff: TimeRemains,
  isPassedTodayCutOff: boolean,
  cutOffDay: CutOffDay,
}

type UseTimeoutCutOff = {
  timeoutCutOff: TimeoutCutOff,
}

type TimeoutCutOffProps = {
  cutOffTime: number,
  cutOffDate: Date,
}

const calcCutOff = (cutOffTime:number): TimeoutCutOff => {
  const {hours, seconds, minutes, isPassedToday,} = getTimeRemainUntilKST(cutOffTime);
  // TODO: 컷오프 날짜에 대한 처리 필요 , cutOffDate: Date
  // console.log('cutOffDate', cutOffDate);
  return {
    cutOffTime,
    timeUntilCutOff: {hours, minutes, seconds, text:`${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`},
    isPassedTodayCutOff: isPassedToday,
    cutOffDay: isPassedToday ? 'tomorrow' : 'today'
  }
};

export default function useTimeoutCutOff(props: TimeoutCutOffProps): UseTimeoutCutOff {
  const { cutOffTime} = props;
  const [timeoutCutOff, setTimeoutCutOff] = useState<TimeoutCutOff>(calcCutOff(cutOffTime));

  useLayoutEffect(() => {
    const interval = () => {
      setTimeoutCutOff(calcCutOff(cutOffTime));
    }

    const intervalId: ReturnType<typeof setInterval> = setInterval(interval, 1000);
    return () => {
      clearInterval(intervalId)
    }
  }, [cutOffTime]);

  return {
    timeoutCutOff
  }
}