import {useState} from "react";
import useKintStore from "~kint/store";
import Page from '~/components/Page';
import Container from "~/components/Container.tsx";
import Headline from "~/components/Headline.tsx";
import TextField from "~/components/TextField.tsx";
import Button from "~/components/Button.tsx";
import Typography from "~/components/Typography.tsx";
// import useNames from "~/pages/reservation/hooks/useNames.ts";
// import List from "~/components/List.tsx";
// import ListItem from "~/components/ListItem.tsx";
// import Icon from "~/components/Icon";
// import Area from "~/components/Area.tsx";

import AgreementsPopup from "~/pages/reservation/partial/AgreementsPopup.tsx";
import {useKeepState} from "~/hooks/useKeepState.ts";


export default function ReservationNamePage() {
  const {reservation, name, setName} = useKintStore((state) => ({
    reservation: state.reservation,
    name: state.name,
    setName: state.setName,
  }));

  const [openAgree, setOpenAgree] = useKeepState<boolean>('openAgree', false);
  const [inputName, setInputName] = useState<string>(name ?? '');

  const handleOpenAgreeTerms = () => {
    setOpenAgree(true);
  }
  const handleCloseAgree = () => {
    setOpenAgree(false);
  }

  const handleConfirm = () => {
    handleSelectName(inputName);
  }

  const handleSelectName = (name: string) => {
    if (!reservation) return;
    if (name) {
      setName(name);
      handleOpenAgreeTerms()
    }
  }


  // const names = useNames(); 이름 제공 일시 제거

  return (
      <>
        <Page
            type={'page'}
            fixedBottom={
              <Container
                  bottom
                  background={'paper'}
                  pt={'8px'}
                  px={'20px'}
              >
                <Button
                    fullWidth
                    disabled={!inputName}
                    variant={'contained'}
                    color={'primary'}
                    onClick={handleConfirm}
                >
                  Confirm
                </Button>
              </Container>
            }
        >
          <Container>
            <Container
                py={'16px'}
                px={'20px'}
            >
              <Headline
                  size={'large'}
                  headline={'Welcome!\nWhat should I call you?'}
                  headlineProps={{whiteSpace: 'pre-wrap'}}
                  description={'This name will be used to distinguish you from other group members.'}/>
            </Container>
            <Container
                py={'8px'}
                px={'20px'}
                stackProps={{
                  gap: '8px'
                }}
            >
              <TextField
                  fullWidth
                  name={"name"}
                  placeholder={'My name is...'}
                  value={inputName}
                  onChange={(e) => {
                    setInputName(e.target.value);
                  }}
                  onEnter={handleConfirm}
                  onClear={
                    inputName
                        ? (() => {
                          setInputName("");
                        })
                        : undefined
                  }
              />
              {
                !inputName || inputName.length < 3
                    ? (
                        <Typography color={'warning.main'}>
                          Please use a name that can be used to identify you.
                        </Typography>
                    ) : null
              }
            </Container>
            {/*<Area*/}
            {/*    py={'8px'}*/}
            {/*    title={*/}
            {/*      <Typography variant={'BaseS_B'} color={'typography.secondary'}>*/}
            {/*        Are you?*/}
            {/*      </Typography>*/}
            {/*    }*/}
            {/*    headerContainerProps={{*/}
            {/*      px: '20px',*/}
            {/*      pt: '12px'*/}
            {/*    }}*/}
            {/*>*/}
            {/*  <List component={'ol'}>*/}
            {/*    {*/}
            {/*      names.map((name) => {*/}
            {/*        const handleClick = () => {*/}
            {/*          handleSelectName(name);*/}
            {/*          setInputName(name);*/}
            {/*        }*/}
            {/*        return (*/}
            {/*            <ListItem*/}
            {/*                disableStrike*/}
            {/*                type={'headline'}*/}
            {/*                key={name}*/}
            {/*                headline={name}*/}
            {/*                onClick={handleClick}*/}
            {/*                headlineProps={{*/}
            {/*                  color: 'typography.tertiary'*/}
            {/*                }}*/}
            {/*                trail={<Icon color={'typography.tertiary'}>chevron_right</Icon>}*/}
            {/*            />)*/}
            {/*      })*/}
            {/*    }*/}
            {/*  </List>*/}
            {/*</Area>*/}
          </Container>
        </Page>
        <AgreementsPopup
            open={openAgree}
            onClose={handleCloseAgree}
        />
      </>
  )
}