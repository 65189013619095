import {ChangeEvent} from "react";
import ButtonBase, {ButtonBaseProps} from '~/components/ButtonBase';

export type FileUploadButtonProps = {
  id:string,
  accept?:string,
  onChange:(files: Blob[] | null, e: ChangeEvent<HTMLInputElement>)=>void,
  children:ButtonBaseProps<'button'>['children']
} & Omit<ButtonBaseProps<'button'>, 'onChange'>

export default function FileUploadButton(props: FileUploadButtonProps){
  const {id, accept = '*', onChange, children, ...restProps} = props;
  const handleChange = (e: ChangeEvent<HTMLInputElement>)=>{
    if(!(e.target.files instanceof FileList)){
      onChange(null, e);
      return;
    }
    const files = e.target.files;
    return onChange([...files], e);
  }
  return(
      <ButtonBase
          component={"label"}
          {...restProps}
      >
        <input
            multiple
            id={id}
            accept={accept}
            style={{display:"none"}}
            onChange={handleChange}
            type={'file'}
        />
        {children}
      </ButtonBase>
  )
}