import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useKupStore from '~kup/store';
import useLoading from '~/hooks/useLoading.tsx';
import { ProductOptionItem, ProductOptionSelection } from '~kup/models/Product';

import { useProductContext } from '../contexts/ProductContext.tsx';

import ModalPopup from '~/components/ModalPopup.tsx';
import Container from '~/components/Container.tsx';
import Stack from '~/components/Stack.tsx';
import ValuePair from '~/components/ValuePair.tsx';
import Button from '~/components/Button.tsx';
import Grow from '~/components/Grow.tsx';

import SelectionItem from './SelectionItem.tsx';

import replaceAt from '~/utils/replaceAt.ts';
import useDeliveryCutOff from '~/pages/shop/hooks/useDeliveryCutOff.ts';
import ProductOptionSelects from '~/pages/shop/components/ProductOptionSelects.tsx';


export type SelectionPopupProps = {
  open: boolean,
  onClose: () => void;
}


export default function SelectionPopup(props: SelectionPopupProps) {
  const { open, onClose } = props;
  const navigate = useNavigate();
  const { currency, basket, setBasket } = useKupStore();
  const { startLoading, stopLoading, loading } = useLoading();
  const {
    product,
    productOptions,
    productSelections,
    productStock,
    setProductSelections,
    total,
    onProductOptionSelectionsCommit,
  } = useProductContext();
  const { deliveryCutOff } = useDeliveryCutOff();
  const [optionSelection, setOptionSelection] = useState<ProductOptionItem[]>([]);

  useEffect(() => {
    if (optionSelection.length === productOptions.length) {
      onProductOptionSelectionsCommit(optionSelection);
      setOptionSelection(optionSelection.slice(0, optionSelection.length - 1));
    }
  }, [optionSelection.length]);

  const handleProductSelectionChange = (changedSelection: ProductOptionSelection) => {
    setProductSelections((prev) => {
      return replaceAt(prev, changedSelection, (p) => p.isEqualTo(changedSelection));
    });
  };
  const handleProductSelectionDelete = (deletedSelection: ProductOptionSelection) => {
    setProductSelections((prev) => {
      const deleteIndex = prev.findIndex((p) => p.isEqualTo(deletedSelection));
      return [...prev.slice(0, deleteIndex), ...prev.slice(deleteIndex + 1)];
    });
  };

  const handleAddBasket = () => {
    if (!product) return;
    const addBaskets = productSelections.map((selection) => ({
      productId: product?.id,
      productName: product?.name,
      productBrand: product?.brand,
      productImageUrls: product?.imageUrls,
      stock: selection.stock,
      isInPromotion: selection.stock.isInPromotion,
      promotionId: product?.promotionId,
      quantity: Math.min(selection.quantity, selection.stock.quantity),
      optionItemSelections: selection.optionItemSelections,
    }));

    startLoading();
    basket.addItems(addBaskets)
      .then(setBasket)
      .then(() => navigate('/shop/basket'))
      .finally(stopLoading);
  };
  if (!productSelections.length || !productStock) return null;

  return (

    <ModalPopup
      open={open}
      onClose={onClose}
      title={product?.name}
      bottomPosition={'sticky'}
      fixedBottom={
        <Container
          sx={() => ({ zIndex: 1 })}
          background={'paper'}
        >
          <ValuePair
            label={'TOTAL'}
            value={`${total.toLocaleString()} ${currency}`}
            subValue={`Order within ${deliveryCutOff.timeUntilCutOff.text} and receive ${deliveryCutOff.deliveryDay}`}
            subValueProps={{ color: 'primary' }}
          />
          <Container
            py={'8px'}
            px={'20px'}
            background={'paper'}
          >
            <Stack
              gap={'10px'}
            >
              <Button
                fullWidth
                loading={loading}
                variant={'contained'}
                onClick={handleAddBasket}
              >
                Add Basket
              </Button>
            </Stack>
          </Container>
        </Container>
      }
    >
      <Container
        py={'8px'}
        px={'20px'}
      >
        {
          productStock && !product?.isInPromotion &&
          <ProductOptionSelects
            productOptions={productOptions}
            productStock={productStock}
            optionSelection={optionSelection}
            onOptionSelection={(productOptionItems) => setOptionSelection(productOptionItems)}
          />
        }
      </Container>
      <Container
        px={'20px'}
      >
        <Stack
          column
          reverse
          gap={'8px'}
        >
          {
            productSelections.map((ps) => (
              <Grow
                key={ps.optionSelectionName}
                in
                onAnimationStart={(e) => e.currentTarget.scrollIntoView()}
              >
                <div>
                  <SelectionItem key={ps.optionSelectionName}
                                 productSelection={ps}
                                 onChangeProductSelection={handleProductSelectionChange}
                                 onDeleteProductSelection={handleProductSelectionDelete} />
                </div>
              </Grow>
            ))
          }
        </Stack>
      </Container>
    </ModalPopup>
  );
}
