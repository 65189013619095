import {useDeferredValue, useState} from "react";
import useKupStore from "~kup/store";
import {useNavigate} from "react-router-dom";
import Page from '~/components/Page';
import Headline from "~/components/Headline.tsx";
import Container from "~/components/Container.tsx";
import TextField, {TextFieldProps} from "~/components/TextField.tsx";
import Typography from "~/components/Typography.tsx";
import List from '~/components/List';
import useSearchAddress from "~/pages/shop/address/hooks/useSearchAddress.ts";
import ListItem from "~/components/ListItem.tsx";
import Icon from "~/components/Icon";


export default function ShopAddressPage() {
  const {inputAddress, setInputAddress} = useKupStore((state) => ({
    setInputAddress: state.setInputAddress,
    inputAddress: state.inputAddress
  }))

  const navigate = useNavigate();
  const [searchAddress, setSearchAddress] = useState<string>(inputAddress ?? '');
  const deferredSearchAddress = useDeferredValue(searchAddress);

  const searchedList = useSearchAddress(deferredSearchAddress);

  const handleInputAddress: TextFieldProps['onChange'] = (e) => {
    setSearchAddress(e.target.value ?? '');
  }

  console.log(searchedList);

  return (
      <Page
          type={'page'}
      >
        <Container
            py={'8px'}
        >
          <Container
              pt={'16px'}
              pb={'20px'}
              px={'20px'}
          >
            <Headline headline={'Where should we deliver it?'}
                      size={'large'}
                      description={'Currently, delivery is only available in the Seoul area'}
            />
          </Container>
          <Container
              p={'8px'}
              px={'20px'}
          >
            <TextField
                fullWidth
                placeholder={'Hotel Name, Address... '}
                value={searchAddress}
                onChange={handleInputAddress}
            />

          </Container>

          {
            searchAddress.length ===0
                ? (
                    <Container
                        py={'16px'}
                        px={'20px'}
                    >
                      <ul>
                        <li>
                          <Typography variant={'FootNote_B'} color={'typography.secondary'} >
                            Name of Hotel or where you stay at
                          </Typography>
                        </li>
                        <li>
                          <Typography variant={'FootNote_B'} color={'typography.secondary'} >
                            Given specific address (ex. Banporo 34gil 32)
                          </Typography>
                        </li>
                      </ul>
                    </Container>
                )
                : (
                    <Container
                        py={'8px'}
                    >
                      <List component={'ol'}>
                        {
                          searchedList.map((address) => {
                            const handleClick = () => {
                              navigate(`/shop/address/${address.id}`, {state: {address, inputAddress:searchAddress}})
                              setInputAddress(searchAddress);
                            }
                            return (<ListItem
                                key={address.id}
                                type={'headline'}
                                headline={address.displayName.text}
                                description={address.formattedAddress}
                                onClick={handleClick}
                                trail={<Icon color={'typography.quaternary'}>chevron_right</Icon>}
                            />)
                          })
                        }
                      </List>

                      <Typography variant={'Caption'} color={'typography.tertiary'} textAlign={'center'} component={'p'}>
                        Powered by Google
                      </Typography>
                    </Container>
                )
          }
        </Container>
      </Page>
  )
}
