import {ReactNode} from "react";
import {Link, Outlet, useLocation} from "react-router-dom";
import {default as MuiPaper} from '@mui/material/Paper';
import {default as MuiBottomNavigation} from '@mui/material/BottomNavigation';
import {default as MuiBottomNavigationAction} from '@mui/material/BottomNavigationAction';

import {navigations} from "~/router.tsx";
import BaseLayout from "~/layouts/BaseLayout.tsx";
import useSafeArea from "~/hooks/useSafeArea.ts";


export default function MainLayout({children, restoreScroll = true, hashPages}: {
  children?: ReactNode,
  restoreScroll?: boolean,
  hashPages?: (hash: string) => ReactNode | undefined
}) {
  const location =  useLocation();
  const pathnameSegments = location.pathname.split('/');
  const {bottomSafeArea} = useSafeArea();

  return (
        <BaseLayout restoreScroll={restoreScroll} hashPages={hashPages}>
          {children ?? <Outlet/>}
          <MuiPaper
              sx={{
                position: 'sticky',
                bottom:0,
                zIndex:500,
                pb:`${bottomSafeArea}px`,
              }}
          >
            <MuiBottomNavigation
                showLabels
                value={pathnameSegments[1]}
            >
              {
                navigations.map((n) => (
                    <MuiBottomNavigationAction
                        replace
                        key={n.path}
                        label={n.label}
                        icon={n.icon}
                        component={Link}
                        to={n.path}
                        value={n.path.split('/')[1]}
                    />
                ))
              }
            </MuiBottomNavigation>
          </MuiPaper>
        </BaseLayout>
  )
}