import {Notification} from '~kup/models/Notification.ts';
import {IChatting} from '~kint/models/Chatting.ts';

import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Page from '~/components/Page';
import Area from '~/components/Area.tsx';
import Card from '~/components/Card.tsx';
import IconButton from '~/components/IconButton.tsx';
import Headline from '~/components/Headline.tsx';
import useListenChattings from '~/pages/message/chat/hooks/useListenChattings.ts';
import Container from '~/components/Container';
import ChatListItem from '~/components/ChattingListItem.tsx';
import Divider from '~/components/Divider.tsx';
import styled from '~/components/styled.tsx';
import ListItem from '~/components/ListItem.tsx';
import Icon from '~/components/Icon';
import CSPopup from '~/components/CSPopup.tsx';
import useKupNotifications from '~/hooks/useKupNotifications.ts';
import CommonFooter from "~/components/CommonFooter.tsx";
import useKupNotificationToken from "~/hooks/useKupNotificationToken.ts";
import useKupNotification from "~/hooks/useKupNotification.ts";
import useChatNotificationToken from "~/hooks/useChatNotificationToken.ts";
import TurnOnNotificationDialog from "~/pages/message/components/TurnOnNotificationDialog.tsx";
import TurnOffNotificationDialog from "~/pages/message/components/TurnOffNotificationDialog.tsx";

const GradientContainer = styled(Container)({
  background: 'linear-gradient(0deg, #FFF -32.32%, #E3EEFF 109.39%)',
});


export default function MessagesMainPage() {
  const chattings = useListenChattings();
  const notifications = useKupNotifications(true);
  const [openCSKup, setOpenCSKup] = useState<boolean>(false);
  const [openCSKtour, setOpenCSKtour] = useState<boolean>(false);
  const [openNotificationDialog, setOpenNotificationDialog] = useState<boolean>(false);
  const [openTurnOffNotificationDialog, setOpenTurnOffNotificationDialog] = useState<boolean>(false);
  const {isNotificationGranted, isNotificationSupported, isNotificationDisabled} = useKupNotificationToken();
  const {isChatNotificationDisabled} = useChatNotificationToken();

  const isNotiDisabled = !isNotificationDisabled && !isChatNotificationDisabled ;
  const notiOn = isNotificationGranted && isNotiDisabled;

  const handleToggleNotificationDialog = () => {
    setOpenNotificationDialog((prev) => !prev);
  }

  const handleToggleTurnOffNotificationDialog = () => {
    setOpenTurnOffNotificationDialog((prev) => !prev);
  }

  const handleToggleCSKup = () => {
    setOpenCSKup((prev) => !prev);
  };

  const handleToggleCSKtour = () => {
    setOpenCSKtour((prev) => !prev);
  };

  return (
      <>
        <Page
            type={'main'}
            navigationBarProps={{
              sx: () => ({
                position: 'absolute',
                borderRadius: '0px',
              }),
              trail: (
                  isNotificationSupported ?
                      <IconButton
                          touchArea={'20px'}
                          iconProps={{
                            size: '1.5rem',
                            color: 'typography.tertiary'
                          }}
                          onClick={
                            notiOn
                                ? handleToggleTurnOffNotificationDialog
                                : handleToggleNotificationDialog
                          }
                      >
                        {
                          notiOn
                              ? 'notifications'
                              : 'notifications_off'
                        }
                      </IconButton>
                      : null
              )
            }}
        >
          <GradientContainer
              pt={'56px'}
              pb={'20px'}
          >
            <NotificationArea notifications={notifications}/>
          </GradientContainer>
          <Container
              sx={{
                mt: '-20px',
                borderRadius: '16px',
                overflow: 'clip',
              }}
          >
            <ChatArea chats={chattings} background={'paper'}/>
          </Container>
          <Divider type={'box'} color={'fill'}/>
          <Container
              stackProps={{
                gap: '4px'
              }}
          >
            <ListItem
                type={'headline'}
                headline={'Need help with your Tour?'}
                onClick={handleToggleCSKtour}
                trail={
                  <Icon
                      size={'20px'}
                      color={'typography.tertiary'}
                  >
                    chevron_right
                  </Icon>
                }
            />
            <ListItem
                type={'headline'}
                headline={'Need help with your Shopping?'}
                onClick={handleToggleCSKup}
                trail={
                  <Icon
                      size={'20px'}
                      color={'typography.tertiary'}
                  >
                    chevron_right
                  </Icon>
                }
            />
          </Container>
          <Divider type={'box'} color={'fill'}/>
          <CommonFooter/>
        </Page>
        <CSPopup open={openCSKup} onClose={handleToggleCSKup} email={'support@kup.travel'}/>
        <CSPopup open={openCSKtour} onClose={handleToggleCSKtour} email={'support@ktourstory.com'}/>
        <TurnOnNotificationDialog open={openNotificationDialog} onClose={handleToggleNotificationDialog}/>
        <TurnOffNotificationDialog open={openTurnOffNotificationDialog} onClose={handleToggleTurnOffNotificationDialog}/>
      </>
  );
}

//participants id 에따른 채팅창 불러오기

function ChatArea(props: { chats: IChatting[], background: 'default' | 'paper' }) {
  const {chats, background} = props;
  const sortChats = (aChat: IChatting, bChat: IChatting) => {
    return (bChat.updatedAt ?? bChat.createdAt).toDate().getTime() - (aChat.updatedAt ?? aChat.createdAt).toDate().getTime()
  };
  return (
      <Area
          py={'8px'}
          px={'0px'}
          title={'Chat'}
          background={background}
          containerProps={{
            px: '0px',
          }}
      >
        {
          chats.sort(sortChats).map((c) => {
            return <ChatItem key={c.id} chat={c}/>;
          })
        }
      </Area>
  );
}

function ChatItem(props: { chat: IChatting }) {
  const {chat} = props;
  return (
      <ChatListItem chat={chat} key={chat.lastMessage?.id}/>
  );
}

function NotificationArea(props: { notifications: Notification[] }) {
  const {notifications} = props;
  const {markAsRead} = useKupNotification();
  const navigate = useNavigate();
  const handleClickNotifications = () => {
    navigate('/message/notification');
  };

  const handleClickNotification = (notification: Notification) => () => {
    if (notification.payload?.path) {
      navigate(notification.payload?.path);
    } else {
      navigate(`/message/notification/${notification.id}`);
    }
    markAsRead(notification.id);
  };

  // if (notifications.length < 1) {
  //   return null;
  // }

  return (
      <Area
          pb={'12px'}
          background={'tr'}
          title={'Notification'}
          trail={
            <IconButton
                iconProps={{size: '1.5rem', color: 'typography.tertiary'}}
                onClick={handleClickNotifications}
            >
              chevron_right
            </IconButton>
          }
          containerProps={{
            sx: {
              overflowY: 'scroll',
            },
            stackProps: {
              row: true,
              gap: '14px',
              px: '20px',
              pb: '8px',
            },
          }}
      >
        {notifications.map(n => (
            <Card key={n.id} sx={{flexShrink: 0, maxWidth: '85vw'}} onClick={handleClickNotification(n)}>
              <Headline headline={n.title.slice(0, 32) + (n.title.length > 32 ? '...' : '')}
                        description={n.description}/>
            </Card>
        ))}
      </Area>
  );
}

