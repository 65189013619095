import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './locales/en';
// import translationZHT from './locales/zht';

const resources = {
    en: {
        translation: translationEN
    },
    zht: {
        translation: translationEN
    },
    zh: {
        translation: translationEN
    },
    english: {
        translation: translationEN
    },
    chinese: {
        translation: translationEN
    }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;