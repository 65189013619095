import {ReactNode} from "react";
import {Palette} from "@mui/material";
import {default as Box, BoxProps as MuiBoxProps} from "@mui/material/Box";
import {ColorNames, Inherit, Fill, isColorName, isFill, isInherit} from "~/components/commons/ColorNames";
import {Default} from "~/components/commons/ValueTypes";


export type FillBoxProps = {
  fullWidth?: boolean,
  type?: 'border' | 'fill'
  color?: ColorNames | Inherit | Fill | Default,
  lead?: ReactNode,
  trail?: ReactNode,
  layoutBoxProps?: MuiBoxProps,
  innerBoxProps?: MuiBoxProps,
} & MuiBoxProps

export default function FillBox(props: FillBoxProps) {
  const {
    fullWidth = false,
    type = 'fill',
    color = 'default',
    lead,
    trail,
    children,
    layoutBoxProps = {},
    innerBoxProps = {},
    px = '20px',
    py = '12px',
    p,
    pt,
    pl,
    pb,
    pr,
    gap = '8px',
    borderRadius = '8px',
    ...restProps
  } = props;
  return (
      <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'stretch'}
          alignItems={'center'}
          border={type === 'border' ? 'solid 1px' : 'none'}
          sx={(theme) => ({
            width: fullWidth ? '100%' : 'auto',
            borderColor:
                isInherit(color)
                    ? 'inherit'
                    : isFill(color)
                        ? (theme.palette.fill.primary)
                        : isColorName(color)
                            ? (theme.palette[color] as Palette['primary']).light
                            : theme.palette.divider,

            backgroundColor:
                isInherit(color)
                    ? 'inherit'
                    : isFill(color)
                        ? (theme.palette.fill.primary)
                        : isColorName(color)
                            ? (theme.palette[color] as Palette['primary']).light
                            : 'transparent',
          })}
          {...{px, py, p, pl, pr, pb, pt, gap, borderRadius}}
          {...layoutBoxProps}
      >
        {
            lead && (
                <Box
                    sx={{
                      flex: 0,
                      display: "inline-flex",
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                >
                  {lead}
                </Box>
            )
        }
        <Box
            sx={{
              flex: 1,
              width: '100%',
              display: 'flex',
            }}
            {...innerBoxProps}
            {...restProps}
        >
          {children}
        </Box>
        {
            trail && (

                <Box
                    sx={{
                      flex: 0,
                      display: "inline-flex",
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      alignItems: 'center'
                    }}
                >
                  {trail}
                </Box>
            )
        }
      </Box>
  )
}