import Typography from "~/components/Typography.tsx";
import Container from "~/components/Container.tsx";

export default function CommonFooter(){
  return(
      <Container
          background={'paper'}
          px={'20px'}
          py={'16px'}
          stackProps={{
            gap: '4px'
          }}
      >
        <Container
            py={'4px'}
            stackProps={{
              row: true,
              gap: '16px'
            }}
        >
          <a href={'/terms'} target={'_blank'} style={{textDecoration: 'none'}}>
            <Typography variant={'FootNote_B'} color={'typography.secondary'}>
              Terms & Condition
            </Typography>
          </a>
          <a href={'/privacy'} target={'_blank'} style={{textDecoration: 'none'}}>
            <Typography variant={'FootNote_B'} color={'typography.secondary'}>
              Privacy Policy
            </Typography>
          </a>
          <a href={'/orderagreements'} target={'_blank'} style={{textDecoration: 'none'}}>
            <Typography variant={'FootNote_B'} color={'typography.secondary'}>
              Order Agreements
            </Typography>
          </a>
        </Container>

        <Typography variant={'FootNote'} color={'typography.tertiary'} component={'p'}>
          Company: Ktourstory Ltd.
        </Typography>
        <Typography variant={'FootNote'} color={'typography.tertiary'} component={'p'}>
          Address: 10th Floor, 12 Supyo-ro, Jung-gu, Seoul, Korea
        </Typography>
        <Typography variant={'FootNote'} color={'typography.tertiary'} component={'p'}>
          Representative: Kang-Hyun Park
        </Typography>
        <Typography variant={'FootNote'} color={'typography.tertiary'} component={'p'}>
          Business Registration Number: 597-81-00842
        </Typography>
        <Typography variant={'FootNote'} color={'typography.tertiary'} component={'p'}>
          Online Sales Registration Number: 2023-SeoulJunggu-1420
        </Typography>

        <Typography variant={'FootNote'} color={'typography.tertiary'} component={'p'}>
          Customer Service for Tour : <a href="mailto:support@ktourstory.com" style={{color:"inherit"}}>support@ktourstory.com</a>
        </Typography>
        <Typography variant={'FootNote'} color={'typography.tertiary'} component={'p'}>
          Customer Service : <a href="mailto:support@kup.travel" style={{color: "inherit"}}>support@kup.travel</a>
        </Typography>
      </Container>
  )
}