import {ElementType, ReactNode} from "react";
import {default as MuiModal, ModalProps as MuiModalProps} from '@mui/material/Modal';
import {default as MuiSlide} from '@mui/material/Slide';
import {default as MuiPaper} from '@mui/material/Paper';
import {BaseArea, BaseAreaProps} from './Area';
import IconButton from './IconButton';
import Typography from './Typography';
import {BoxProps as MuiBoxProps, default as MuiBox} from "@mui/material/Box/Box";
import {PaperProps as MuiPaperProps} from "@mui/material/Paper/Paper";

export type ModalPopupProps<E extends ElementType> = {
  title?: ReactNode,
  onClose: () => void,
  fixedBottom?: ReactNode,
  bottomPosition?: 'absolute' | 'sticky' | 'relative',
  bottomProps?: MuiBoxProps,
  modalProps?: Omit<MuiModalProps, 'children'>,
  popupProps?: Omit<MuiPaperProps, 'children'>,

} & Omit<MuiModalProps<E> & BaseAreaProps<E>, 'onClose'>
    & Pick<MuiBoxProps, 'width' | 'maxWidth' | 'minWidth' | 'height' | 'maxHeight' | 'minHeight'>

function isStringTitle(title: ReactNode): title is string {
  return typeof title === 'string';
}

export default function ModalPopup<E extends ElementType>(props: ModalPopupProps<E>) {
  const {
    open, onClose, title,
    fixedBottom,
    bottomPosition = 'relative',
    bottomProps = {},
    children,
    modalProps = {},
    popoverProps = {},
  } = props;


  const dimensions = {
    width: props.width,
    maxWidth: props.maxWidth,
    minWidth: props.minWidth ?? '100vw',
    height: props.height,
    minHeight: props.minHeight,
    maxHeight: props.maxHeight ?? '87.5vh'
  };

  const titleNode = isStringTitle(title)
      ? <Typography variant={'BaseS_B'} sx={{display: 'inline-flex', alignItems: 'center'}}>{title}</Typography>
      : title;

  const handleClose = () => {
    onClose();
  }
  return open ? (
      <MuiModal
          disableAutoFocus
          closeAfterTransition
          open={open}
          onClose={handleClose}
          {...modalProps}
      >
        <MuiSlide
            in={open}
            direction={'up'}
            timeout={500}
        >
          <MuiPaper
              onClick={(e) => e.stopPropagation()}
              sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                paddingTop:'8px',
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0,
                overflow: 'scroll',
                ...dimensions
              }}
              {...popoverProps}
          >
            <BaseArea
                bottom
                title={titleNode}
                trail={
                  <IconButton
                      disableRipple
                      edge
                      touchArea={'8px'}
                      onClick={handleClose}
                      color={'typography.tertiary'}
                      iconProps={{
                        width:'20px',
                      }}
                  >
                    close
                  </IconButton>
                }
            >
              {children}
              {
                fixedBottom
                    ? (
                        <MuiBox
                            style={{
                              bottom: 0,
                              left: 0,
                              right: 0,
                              position: bottomPosition,
                            }}
                            {...bottomProps}
                        >
                          {
                            fixedBottom
                          }
                        </MuiBox>
                    )
                    : null
              }
            </BaseArea>
          </MuiPaper>
        </MuiSlide>
      </MuiModal>
  ) : null
}