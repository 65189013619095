import ModalPopup from "~/components/ModalPopup.tsx";
import Container from "~/components/Container.tsx";
import Headline from "~/components/Headline.tsx";
import Typography from "~/components/Typography.tsx";
import Checkbox from "~/components/Checkbox.tsx";
import Button from "~/components/Button.tsx";
import Box from '~/components/Box';
import recallChatting from "~kint/controllers/recallChatting.ts";
import useKintStore from "~kint/store";
import {useLocation, useNavigate} from "react-router-dom";
import useLoading from "~/hooks/useLoading.tsx";
import useKupAuthWithKint from "~/pages/reservation/hooks/useKupAuthWithKint.tsx";
import {CheckboxProps} from "@mui/material";
import IconButton from "~/components/IconButton.tsx";
import {useKeepState} from "~/hooks/useKeepState.ts";


export type AgreementsPopupProps = {
  open: boolean,
  onClose: () => void,
}

export default function AgreementsPopup(props: AgreementsPopupProps) {
  const {reservation, name, setTourChatting, setParticipant} = useKintStore((state) => ({
    reservation: state.reservation,
    name: state.name,
    setTourChatting: state.setTourChatting,
    setParticipant: state.setParticipant
  }));
  const navigate = useNavigate();
  const location = useLocation();
  const prevState = location.state as {
    checkTermsAndCondition:boolean,
    checkPrivacyPolicies:boolean,
    open:boolean
  }
  const {open, onClose} = props;
  const {startLoading, stopLoading, loading} = useLoading();
  const [checkTermsAndCondition, setCheckTermsAndCondition] = useKeepState<boolean>('termsAndCondition', prevState?.checkTermsAndCondition ?? true);
  const [checkPrivacyPolicies, setCheckPrivacyPolicies] = useKeepState<boolean>('privacy', prevState?.checkPrivacyPolicies ?? true);
  const signIn = useKupAuthWithKint();

  const siteState = {
    checkTermsAndCondition,
    checkPrivacyPolicies,
    open
  }

  const handleClose = ()=>{
    setCheckTermsAndCondition(true);
    setCheckPrivacyPolicies(true);
    onClose();
  }

  const handleCheckTermsAndCondition: CheckboxProps['onChange'] = (_, value) => {
    setCheckTermsAndCondition((value));
  }

  const handleCheckPrivacyPolicies: CheckboxProps['onChange'] = (_, value) => {
    setCheckPrivacyPolicies((value));
  }

  const handleAgreeTerms = () => {
    if (!reservation) return;
    if (!name) return;
    (async () => {
      startLoading();
      await signIn(name);
      return await recallChatting(name, reservation);
    })()
        .then(({chatting, participant}) => {
          setParticipant(participant);
          setTourChatting(chatting);
          if (!chatting) {
            throw 'No Chatting';
          }
          navigate(`/`, {state: {fromEntry: true}, replace: true})
          // navigate(`/message/chatting/${chatting!.id}`, {state: {fromEntry: true}, replace: true})
        })
        .catch(() => {
          navigate(`/`, {state: {fromEntry: true}, replace: true})
        })
        .finally(stopLoading)
  }

  return (
      <ModalPopup
          open={siteState?.open ?? open}
          onClose={handleClose}
          fixedBottom={
            <Container
                py={'8px'}
                px={'20px'}
                stackProps={{
                  column: true,
                  alignItems: 'center'
                }}
            >
              <Button
                  fullWidth
                  loading={loading}
                  disabled={!(checkPrivacyPolicies && checkTermsAndCondition)}
                  onClick={handleAgreeTerms}
              >
                Agree and Start
              </Button>
              <Button variant={'text'} size={'small'} color={'secondary'} onClick={()=>{
                handleClose();
                navigate(-1);
              }}>
                No, I don't want to use this service
              </Button>
            </Container>
          }
      >
        <Container
            py={'8px'}
        >
          <Container
              py={'16px'}
              px={'20px'}
          >
            <Headline headline={`Hi ${name},\nLet's get started!`}
                      headlineProps={{
                        whiteSpace:'pre-wrap'
                      }}
                      description={"We value your trust. Please review and agree to the terms below to continue."}
                      size={'large'}>

            </Headline>
          </Container>
          <Container
              pt={'12px'}
              pb={'8px'}
              px={'20px'}
          >
            <Typography variant={'BaseS_B'} component={'p'}>
              Please check the agreements below to proceed
            </Typography>
          </Container>
          <Container
              px={'20px'}
              stackProps={{
                column: true,
              }}
          >

            <Box
                display={'flex'}
                justifyContent={'stretch'}
                alignItems={'center'}
                gap={'8px'}
                py={'12px'}
            >

              <Box
                  flex={1}
              >
                <Checkbox type={'line'}
                          label={
                            <Headline size={'small'} headline={'Privacy Policies'}
                                      description={'We collect and use only essential information for our services'}/>
                          }
                          checked={checkPrivacyPolicies}
                          onChange={handleCheckPrivacyPolicies}
                          labelProps={{
                            sx: {
                              display: 'flex',
                              flex: 1,
                              width: '100%'
                            }
                          }}
                />
              </Box>
              <IconButton
                  edge
                  touchArea={'8px'}
                  iconProps={{
                    size:'1.25rem',
                    color:'typography.quaternary'
                  }}
                  onClick={()=>navigate('/privacy')}
              >
                chevron_right
              </IconButton>
            </Box>
            <Box
                display={'flex'}
                justifyContent={'stretch'}
                alignItems={'center'}
                gap={'8px'}
                py={'12px'}
            >
              <Box
                  flex={1}
              >
                <Checkbox type={'line'} label={
                  <Headline size={'small'} headline={'Terms & Conditions'}
                            description={'We provide tours and info services, terms apply'}/>
                }
                          checked={checkTermsAndCondition}
                          onChange={handleCheckTermsAndCondition}
                          labelProps={{
                            sx: {
                              display: 'flex',
                              flex: 1,
                              width: '100%'
                            }
                          }}
                />
              </Box>

              <IconButton
                  edge
                  touchArea={'8px'}
                  iconProps={{
                    size:'1.25rem',
                    color:'typography.quaternary'
                  }}
                  onClick={()=>navigate('/terms')}
              >
                chevron_right
              </IconButton>
            </Box>

          </Container>
        </Container>
      </ModalPopup>
  )
}