import isEquivalentSetArray from '~/utils/isEquivalentSetArray.ts';
import isSubSetArray from '~/utils/isSubSetArray.ts';

export interface ProductResponse {
  id: string,
  slug: string,
  brand: string,
  name: string,
  description: string,
  categoryName: string,
  originalPrice: number,
  price: number
  activeVersion: string,
  imageUrls: string[],
  detailImageUrls?: string[],
  metaDetailImageUrls?: string[],
  productOptions?: ProductOptionResponse[],
  stocks: OptionStockResponse[],
  tags?: string[],
  isInPromotion?: boolean;
  promotionId?: string;
  purchasableCount?: number;
  best?: boolean;
  delivery?: boolean;
  currency?: string;

}

export interface ProductCategoryResponse {
  id: string,
  slug: string,
  name: string,
  createdAt: string,
  updatedAt: string
}


export interface ProductTagResponse {
  id:string,
  name:string,
  headline:string,
  orderWeight:number,
  isHighlight:boolean,
  headImageUrl?: {url:string, name:string}[] // todo 수정 필요
  headImageUrls?: {url:string, name:string}[] // todo 수정 필요
}


export class ProductTag {
  id:string;
  name:string;
  headline:string;
  orderWeight:number;
  isHighlight:boolean;
  headImageUrls:{url:string, name:string}[]

  constructor(raw:ProductTagResponse) {
    this.id = raw.id;
    this.name = raw.name;
    this.headline = raw.headline;
    this.orderWeight = raw.orderWeight;
    this.headImageUrls = raw.headImageUrl ?? raw.headImageUrls ?? [];
    this.isHighlight = raw.isHighlight;
  }
}

export class Product {
  id: string;
  slug: string;
  brand: string;
  name: string;
  description: string;
  categoryName: string;
  originalPrice: number;
  price: number;
  activeVersion: string;
  imageUrls: string[];
  detailImageUrls?: string[];
  metaDetailImageUrls?: string[];
  productOptions?: ProductOption[];
  stocks: ProductStock;
  tags: string[];
  isInPromotion?: boolean;
  promotionId?: string;
  purchasableCount?: number;
  best?: boolean;
  delivery?: boolean;

  currency?: string;

  constructor(raw: ProductResponse) {
    this.id = raw.id;
    this.brand = raw.brand;
    this.slug = raw.slug;
    this.name = raw.name;
    this.description = raw.description;
    this.categoryName = raw.categoryName;
    this.originalPrice = raw.originalPrice;
    this.price = raw.price;
    this.activeVersion = raw.activeVersion;
    this.imageUrls = raw.imageUrls;
    this.detailImageUrls = raw.detailImageUrls;
    this.metaDetailImageUrls = raw.metaDetailImageUrls;
    this.productOptions = raw.productOptions?.map((po) => new ProductOption(po));
    this.stocks = new ProductStock(raw.stocks);
    this.best = raw.best ?? false;
    this.currency = raw.currency ?? 'KRW';
    this.delivery = raw.delivery ?? true;
    this.tags = [...(raw.tags ?? [])];
    this.isInPromotion = raw.isInPromotion ?? false;
    this.promotionId = raw.promotionId;
    this.purchasableCount = raw.purchasableCount;
  }

  get discountRate(): number {
    return (this.originalPrice - this.price) / this.originalPrice;
  }
}

export class ProductCategory {
  static CATEGORY_ALL = 'all' as const;

  id: string;
  slug: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;

  constructor(raw: ProductCategoryResponse) {
    this.id = raw.id;
    this.slug = raw.slug;
    this.name = raw.name;
    this.createdAt = new Date(raw.createdAt);
    this.updatedAt = new Date(raw.updatedAt);
  }
}


export interface ProductOptionItemResponse {
  id: string;
  slug: string,
  name: string,
  sub?: string,
  thumbnailUrl?: string,
  description?: string,
  orderWeight: number,
}

export interface ProductOptionResponse {
  id: string;
  slug: string;
  name: string;
  orderWeight: number;
  optionItems: ProductOptionItemResponse[];
}


export class ProductOption {
  id: string;
  slug: string;
  name: string;
  orderWeight: number;
  optionItems: ProductOptionItem[];

  constructor(raw: ProductOptionResponse) {
    this.id = raw.id;
    this.slug = raw.slug;
    this.name = raw.name;
    this.orderWeight = raw.orderWeight;
    this.optionItems = raw.optionItems?.map((i) => new ProductOptionItem(i));
  }
}

export class ProductOptionItem {
  id: string;
  slug: string;
  name: string;
  sub?: string;
  description?: string;
  thumbnail?: string;
  orderWeight: number;

  constructor(raw: ProductOptionItemResponse) {
    this.id = raw.id;
    this.slug = raw.slug;
    this.name = raw.name;
    this.sub = raw.sub;
    this.description = raw.description;
    this.thumbnail = raw.thumbnailUrl;
    this.orderWeight = raw.orderWeight;
  }
}


export interface OptionStockResponse {
  id: string;
  optionItemSlugs: string[],
  quantity: number;
  price: number;
  originalPrice: number;
  isInPromotion?: boolean;
}

export interface ProductStockResponse {
  optionStock: { [slugs: string]: OptionStockResponse };
}

export class OptionStock {
  id: string;
  optionItemSlugs: string[];
  quantity: number;
  price: number;
  originalPrice: number;
  isInPromotion?: boolean;

  constructor(raw: OptionStockResponse) {
    this.id = raw.id;
    this.optionItemSlugs = raw.optionItemSlugs;
    this.quantity = raw.quantity;
    this.price = raw.price;
    this.originalPrice = raw.originalPrice;
    this.isInPromotion = raw.isInPromotion ?? false;
  }

  get discountRate() {
    return (this.originalPrice - this.price) / this.originalPrice;
  }
}

export class ProductStock {
  optionStock: {
    [slugs: string]: OptionStock
  };

  constructor(raws: OptionStockResponse[]) {
    this.optionStock = Object.fromEntries(
        raws?.map((os) => [os.optionItemSlugs, new OptionStock(os)]) ?? []
    );
  }

  get optionStockList() {
    return this.optionStock && Object.values(this.optionStock);
  }

  get quantity() {
    return this.optionStockList?.reduce((result, stock) => result + stock.quantity, 0);
  }

  getMatchingOptionStocks(slugs: string[]): OptionStock[] {
    if (!this.optionStockList) return [];
    return this.optionStockList.filter((optionStock) => isSubSetArray(optionStock.optionItemSlugs, slugs));
  }

  getMatchingOptionStockQuantity(slugs: string[]): number {
    return this.getMatchingOptionStocks(slugs).map((os) => os.quantity).reduce((a, b) => a + b, 0);
  }

  getExactOptionStock(slugs: string[]): OptionStock | null {
    if (!this.optionStockList) return null;
    return this.optionStockList.find((optionStock) => isEquivalentSetArray(optionStock.optionItemSlugs, slugs)) ?? null;
  }
}


export class ProductOptionSelection {
  stock: OptionStock;
  optionItemSelections: ProductOptionItem[];
  quantity: number;

  constructor(stock: OptionStock, optionItems: ProductOptionItem[], quantity: number = 1) {
    this.stock = stock;
    this.optionItemSelections = optionItems;
    this.quantity = quantity;
    Object.freeze(this);
  }

  get thumbnail(): string | undefined {
    return this.optionItemSelections.map((item) => item.thumbnail).filter(thumbnail => !!thumbnail).at(-1);
  }

  get slugs(): string[] {
    return this.optionItemSelections.map((item) => item.slug);
  }

  get optionSelectionNames(): string[] {
    return this.optionItemSelections.map((item) => `${item.name}`);
  }

  get optionSelectionName(): string {
    return this.optionSelectionNames.join(', ');
  }

  get amount(): number {
    return this.stock.price * this.quantity;
  }

  setQuantity(quantity: number) {
    return new ProductOptionSelection(this.stock, this.optionItemSelections, quantity);
  }

  isEqualTo(selection: ProductOptionSelection) {
    return this.isSameOptionFor(selection.slugs);
  }

  isSameOptionFor(slugs: string[]) {
    return isEquivalentSetArray(this.slugs, slugs);
  }
}