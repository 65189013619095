import {Link} from 'react-router-dom';
import Logo, {LogoProps} from "~/components/Logo";

interface LogoLinkProps {
  to?: string,
  color?: LogoProps['color']
}


export default function LogoKupLink(props: LogoLinkProps) {
  const {to = '/', color} = props;
  return (
      <Link to={to}
            style={{
              display: 'inline-flex',
              alignItems: 'center',
            }}
      >
        <Logo
            color={color}
            height={'20px'}
        >
          kup
        </Logo>
      </Link>
  )
}
