import {useEffect, useRef, useState} from 'react';
import Box from "@mui/material/Box";
import ButtonBase from "~/components/ButtonBase.tsx";

declare global {
  interface Window {
    naver: any
  }
}

export type LatLng = {
  lat: number;
  lng: number;
}

export type NaverMapProps = {
  placeName: string;
  locale: string;
  zoom?: number;
  center: LatLng;
  disableTouch?: boolean
  containerStyles?: Record<string, string>;
}

export default function NaverMap(props: NaverMapProps) {
  const mapRef = useRef<HTMLDivElement>(null);
  const [enableTouch, setEnableTouch] = useState<boolean>(false);
  const handleToggleTouch = () => {
    setEnableTouch((prev) => !prev);
  }
  useEffect(() => {
    const initializeMap = () => {
      if (!mapRef.current) return;
      const location = new window.naver.maps.LatLng(props.center.lat, props.center.lng);
      const mapOptions = {
        center: location,
        draggable: enableTouch,
        pinchZoom: enableTouch,
        scrollWheel: enableTouch,
        keyboardShortcuts: enableTouch,
        disableDoubleTapZoom: enableTouch,
        disableDoubleClickZoom: enableTouch,
        disableTwoFingerTapZoom: enableTouch,
        zoom: props.zoom ?? 15,
        logoControlOptions: {
          position: window.naver.maps.Position.TOP_LEFT,
        },
        mapDataControl: false,
        scaleControlOptions: {
          position: window.naver.maps.Position.TOP_RIGHT,
        }
      };
      const map = new window.naver.maps.Map(mapRef.current, mapOptions);
      const marker = new window.naver.maps.Marker({
        position: location,
        map: map
      });

      const infoWindow = new window.naver.maps.InfoWindow({
        content: `<div style="color: white; font-weight: 600; padding: 5px 10px;"><p>${props.placeName}</p></div>`,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        borderColor: 'rgba(0, 0, 0, 0.5)',
        anchorColor: 'rgba(0, 0, 0, 0.1)',
        anchorSize: new window.naver.maps.Size(15, 15),
      });
      infoWindow.open(map, marker);

      window.naver.maps.Event.addListener(marker, 'click', () => {
        if (infoWindow.getMap()) {
          infoWindow.close();
        } else {
          infoWindow.open(map, marker);
        }
      });
    };

    const script = document.createElement('script');
    script.src = `https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=nkwyd1vrg3&language=${props.locale.split('-')[0]}`;
    script.async = true;
    script.onload = initializeMap;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [enableTouch]);

  return (
      <Box sx={{
        position: 'relative'
      }}>
        <div ref={mapRef} style={props.containerStyles}/>
        {
            (!props.disableTouch) && (
                <ButtonBase
                        sx={{
                          position: 'absolute',
                          width:'100%',
                          left: '50%',
                          bottom: 0,
                          p: '8px',
                          transform: 'translateX(-50%)',
                          textShadow: '-1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white',
                          backgroundColor: 'rgba(33,33,33,0.1)'
                        }}
                        onClick={handleToggleTouch}
                >
                  {
                    enableTouch ?
                        'Disable Interaction'
                        : 'Enable Interaction'
                  }
                </ButtonBase>
            )
        }
      </Box>
  )
}