export const MESSENGERS = [
  {label: 'WhatsApp', icon: 'WhatsApp', value: 'WhatsApp'},
  {label: 'Line', icon: 'Line', value: 'Line'},
  {label: 'WeChat', icon: 'WeChat', value: 'WeChat'},
  {label: 'Viber', icon: 'Viber', value: 'Viber'},
  {label: 'KakaoTalk', icon: 'KakaoTalk', value: 'KakaoTalk'},
  {label: 'Facebook Messenger', icon: 'facebook_messenger', value: 'FacebookMessenger'},
  {label: 'Tel', icon: 'call', value: 'Tel'},
  {label: 'SMS', icon: 'message', value: 'SMS'},
  {label: 'Others', icon: 'etc', value: 'Others'},
] as const;


export const PAYMENT_METHOD = [
  // {
  //   headline: 'Pay on Guide (On-Site, Cash)',
  //   description: 'It is only available while you are on tour.\nThe guide will request payment.',
  //   value: 'PAY_ON_GUIDE',
  // },
  {
    headline: 'Pay on Delivery (On-Site, Cash)',
    description: 'Delivery will be between 8 PM and 11 AM,\nand payment will be made in cash upon delivery',
    value: 'PAY_ON_DELIVERY',
  },
  {
    headline: 'Pay with Card (Preparing)',
    value: 'PAY_WITH_CREDIT_CARD',
    disabled: true,
  },
];

export const PRODUCT_SORT_TYPE = [
  {label: 'Recommend', value: 'recommend'},
  // {label: 'Best Selling', value: 'best_selling'},
  {label: 'Popular', value: 'popular'},
  {label: 'New', value: 'new'},
  {label: 'Price High', value: 'price_high'},
  {label: 'Price Low', value: 'price_low'},
];

type orderStatusGuidance = {
  title: string;
  tag: string;
};

export const ORDER_STATUS_GUIDANCE_MAP: Record<string, orderStatusGuidance> = {
  CONFIRMING_PAYMENT: {title: 'Waiting for payment!', tag: 'AWAITING PAYMENT'},
  CONFIRMING_ORDER: {title: "Checking your order!", tag: 'CHECKING ORDER'},
  PREPARING_ITEMS: {title: 'Packing your order!', tag: 'PREPARING ITEMS'},
  ASSIGN_DELIVERY: {title: 'Preparing transit!', tag: 'PREPARING DELIVERY'},
  OUT_FOR_DELIVERY: {title: 'Order is on its way!', tag: 'ON GOING DELIVERY'},
  DELIVERED: {title: 'Delivery Completed!', tag: "DELIVERY COMPLETED"},
  COMPLETED: {title: 'Delivery Completed!', tag: "DELIVERY COMPLETED"},
  REQUESTED_CANCELLATION: {title: 'Cancellation requested', tag: "CANCELLATION REQUESTED"},
  CANCELLATION: {title: 'Order canceled', tag: "ORDER CANCELED"},
};

