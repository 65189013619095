import { Notification } from '~kup/models/Notification.ts';
import { getNotification, patchRead } from '~kup/controllers/notification.ts';
import { useEffect, useState } from 'react';

type UseKupNotification = {
  notification: Notification | null,
  markAsRead: (id: Notification['id']) => void,
};

export default function useKupNotification(id?: string): UseKupNotification {
  const [notification, setNotification] = useState<Notification | null>(null);
  useEffect(() => {
    if (id)
      getNotification(id).then(setNotification).catch(console.error);
  }, [id]);

  const markAsRead = (id: Notification['id']) => patchRead(id);

  return {
    notification,
    markAsRead,
  };
}