import { ReactNode } from "react";
import { default as MuiTable } from '@mui/material/Table';
import { default as MuiTableRow } from '@mui/material/TableRow';
import { default as MuiTableCell, TableCellProps } from '@mui/material/TableCell';
import { default as MuiTableBody } from '@mui/material/TableBody';

type Pair = { key: string | number, pair: [ReactNode, ReactNode] }
export type SimpleTable = {
  pairs: Pair[];
  leftCellAlign?: TableCellProps['align'];
  rightCellAlign?: TableCellProps['align'];
}

export default function ValueList(props: SimpleTable) {
  const { pairs, leftCellAlign, rightCellAlign } = props;
  return (
    <MuiTable
      sx={(theme) => ({
        '& .MuiTableCell-root': {
          minWidth: '80px',
          paddingTop: '8px',
          paddingBottom: '8px',
          paddingLeft: '4px',
          paddingRight: '4px',
          overflow: 'hidden',
          whiteSpace: 'pre-wrap',
          textOverflow: 'ellipsis',
          color: theme.palette.typography.primary,
          ...theme.typography.BaseS,
          '&:first-of-type': {
            color: theme.palette.typography.tertiary
          }
        },
        '& .MuiTableCell-value': {
          maxWidth:'70vw',
          overflow: 'hidden',
          whiteSpace: 'pre-wrap',
          textOverflow: 'ellipsis',
        }
      })}
    >
      <MuiTableBody>
        {
          pairs.map(({ key, pair: [label, value] }) => (
            <MuiTableRow key={key}>
              <MuiTableCell align={leftCellAlign}>
                {label}
              </MuiTableCell>
              <MuiTableCell align={rightCellAlign} className="MuiTableCell-value">
                {value}
              </MuiTableCell>
            </MuiTableRow>
          ))
        }
      </MuiTableBody>
    </MuiTable>
  )
}