import { useState } from "react"
import {default as MuiBox} from '@mui/material/Box';
import {default as MuiImageList} from '@mui/material/ImageList';
import {default as MuiImageListItem} from '@mui/material/ImageListItem';

import ImagePreviewer from "~/components/ImagePreviewer";

interface Props {
  imgUrls: string[]
}

/**
 * image.length <= 3 -> cols = image.length
 * image.length === 4 -> cols = 2
 * image.length > 3 -> cols =3 (max)
 * image.length > 3 && iamge.length % 3 === 1 -> 마지막 image cols = 3
 * image.length > 3 && iamge.length % 3 === 2 -> 마지막 2개 image cols = 3
 *
 */

function ImageList({ imgUrls }: Props) {
  const [openPreviewImg, setPreviewImg] = useState(false)
  const [fileUrl, setFileUrl] = useState("")
  const openImagePreviewer = (fileUrl: string = "") => {
    setPreviewImg(true)
    setFileUrl(fileUrl)
  }
  const closeImagePreviewer = () => {
    setPreviewImg(false)
    setFileUrl("")
  }
  return (
      <MuiBox>
        <ImagePreviewer key={fileUrl} open={openPreviewImg} fileUrl={fileUrl} onClose={closeImagePreviewer} />
        <MuiImageList
            variant="quilted"
            cols={imgUrls.length <= 3 ? imgUrls.length : imgUrls.length === 4 ? 2 : 3}
            rowHeight={imgUrls.length >= 3 ? 121 : undefined}
            sx={{
              maxWidth: imgUrls.length >= 3 ? "calc(121px * 3)" : "50vw",
              borderRadius: "4px",
              marginBottom: "auto",
            }}
        >
          {imgUrls.map((fileUrl: string, index: number) => (
              <MuiImageListItem
                  key={fileUrl}
                  onClick={(e) => {
                    e.preventDefault();
                    openImagePreviewer(fileUrl)
                  }}
                  sx={{
                    cursor: "pointer",
                  }}
                  cols={
                    imgUrls.length > 3 &&
                    imgUrls.length !== 4 &&
                    imgUrls.length % 3 !== 0 &&
                    index >= imgUrls.length - 2
                        ? 3
                        : 1
                  }
              >
                <img src={fileUrl} loading="lazy" alt={`Image\r\nDownloadable`}/>
              </MuiImageListItem>
          ))}
        </MuiImageList>
      </MuiBox>
  )
}

export default ImageList
