import api from '~kup/api';
import { BasketResponse } from '~kup/models/Basket.ts';
import { Order, OrderHistory, OrderHistoryResponse, OrderPostData } from '~kup/models/Order.ts';

const ORDER_PATH = {
  ORDERS: () => ['', 'orders'].join('/'),
  ORDER: (pathParams: string[] = []) => ['', 'orders', ...pathParams].join('/'),
  ORDER_IN_CART: (pathParams: string[] = []) => ['', 'orders/in-cart', ...pathParams].join('/'),
  PLACE_ORDER: () => ['', 'orders/place-order'].join('/'),
  REQUEST_CANCELLATION: (orderId:string)=>['', 'admin/orders', orderId,'request-cancellation'].join('/')
};

type GetOrderHistoryParams = {
  pageSize: number,
  page: number,
}

type GetOrderHistoryResponse = {
  orderHistories: OrderHistory[],
  hasNextPage: boolean
};

export async function putBasketItem(basket: BasketResponse): Promise<BasketResponse> {
  const resp = await api.put(ORDER_PATH.ORDER_IN_CART(), basket);
  return resp.data.data;
}

export async function getBasket(): Promise<BasketResponse | null> {
  const resp = await api.get(ORDER_PATH.ORDER_IN_CART());
  return resp.data.data;
}

export async function postOrder(order: OrderPostData): Promise<Order | null> {
  const resp = await api.post(ORDER_PATH.PLACE_ORDER(), order);
  return resp.data.data;
}

export async function getOrderHistories(params: GetOrderHistoryParams): Promise<GetOrderHistoryResponse> {
  const config = { params };
  const resp = await api.get(ORDER_PATH.ORDERS(), config);
  const orderHistoryResponses: OrderHistoryResponse[] = resp.data.data;
  const hasNextPage: boolean = resp.data.meta.hasNext ?? false;
  return {
    orderHistories: orderHistoryResponses.map((r) => new OrderHistory(r)),
    hasNextPage,
  };
}

export async function getOrder(orderId: string): Promise<Order | null> {
  const resp = await api.get(ORDER_PATH.ORDER([orderId]));
  return resp.data.data ? new Order(resp.data.data) : null;
}


export async function cancelOrder(orderId:string):Promise<void>{
  const resp = await api.post(ORDER_PATH.REQUEST_CANCELLATION(orderId));
  return resp.data.data;
}