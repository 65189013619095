import {default as MuiCard, CardProps as MuiCardProps} from '@mui/material/Card';
import {default as MuiCardContent, CardContentProps as MuiCardContentProps} from "@mui/material/CardContent";
import {
  default as MuiCardActionArea,
  CardActionAreaProps as MuiCardActionAreaProps
} from "@mui/material/CardActionArea";

export type CardContentProps = MuiCardContentProps;

export type CardProps = MuiCardProps & {
  contentProps?: CardContentProps,
  onClick?: MuiCardActionAreaProps['onClick']
};

export default function Card(props: CardProps) {
  const {children, contentProps, onClick, ...restProps} = props;
  return (
      <MuiCard
          {...restProps}
      >
        {
          onClick
              ? (
                  <MuiCardActionArea onClick={onClick}>
                    <MuiCardContent {...contentProps}>
                      {children}
                    </MuiCardContent>
                  </MuiCardActionArea>
              )
              : (
                  <MuiCardContent {...contentProps}>
                    {children}
                  </MuiCardContent>
              )
        }
      </MuiCard>
  )
}