export type IconMessageProps = {
  width?: string,
  height?: string,
}
export default function IconMessage(props: IconMessageProps) {
  const {width = '32px', height = '32px'} = props;
  return (
      <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 19L3.41675 21.5833C3.15558 21.8444 2.85417 21.9033 2.5125 21.76C2.17083 21.6165 2 21.3604 2 20.9918V4.66675C2 4.21675 2.16525 3.82642 2.49575 3.49575C2.82642 3.16525 3.21675 3 3.66675 3H20.3333C20.7833 3 21.1736 3.16525 21.5043 3.49575C21.8348 3.82642 22 4.21675 22 4.66675V17.3332C22 17.7832 21.8348 18.1736 21.5043 18.5043C21.1736 18.8348 20.7833 19 20.3333 19H6ZM7.76675 11.95C8.05008 11.95 8.28758 11.8542 8.47925 11.6625C8.67092 11.4708 8.76675 11.2333 8.76675 10.95C8.76675 10.6667 8.67092 10.4292 8.47925 10.2375C8.28758 10.0458 8.05008 9.95 7.76675 9.95C7.48342 9.95 7.24592 10.0458 7.05425 10.2375C6.86258 10.4292 6.76675 10.6667 6.76675 10.95C6.76675 11.2333 6.86258 11.4708 7.05425 11.6625C7.24592 11.8542 7.48342 11.95 7.76675 11.95ZM12.05 11.95C12.3333 11.95 12.5708 11.8542 12.7625 11.6625C12.9542 11.4708 13.05 11.2333 13.05 10.95C13.05 10.6667 12.9542 10.4292 12.7625 10.2375C12.5708 10.0458 12.3333 9.95 12.05 9.95C11.7667 9.95 11.5292 10.0458 11.3375 10.2375C11.1458 10.4292 11.05 10.6667 11.05 10.95C11.05 11.2333 11.1458 11.4708 11.3375 11.6625C11.5292 11.8542 11.7667 11.95 12.05 11.95ZM16.2168 11.95C16.5001 11.95 16.7376 11.8542 16.9293 11.6625C17.1209 11.4708 17.2168 11.2333 17.2168 10.95C17.2168 10.6667 17.1209 10.4292 16.9293 10.2375C16.7376 10.0458 16.5001 9.95 16.2168 9.95C15.9334 9.95 15.6959 10.0458 15.5043 10.2375C15.3126 10.4292 15.2168 10.6667 15.2168 10.95C15.2168 11.2333 15.3126 11.4708 15.5043 11.6625C15.6959 11.8542 15.9334 11.95 16.2168 11.95Z"
              fill="currentColor"/>
      </svg>

  )
}