import {useState, ElementType, ReactNode} from "react";
import Container, {ContainerProps} from "~/components/Container";
import Typography from "~/components/Typography";
import Badge from "~/components/Badge";
import Stack from "~/components/Stack";
import IconButton from '~/components/IconButton';

import {default as MuiCollapse} from '@mui/material/Collapse';


export type BaseAreaProps<E extends ElementType> = ContainerProps<E> & {
  title?: ReactNode,
  trail?: ReactNode,
  description?: ReactNode,
  headerContainerProps?: Partial<ContainerProps<'div'>>,
  containerProps?: Partial<ContainerProps<'div'>>,
  component?: E,
}

export function BaseArea<E extends ElementType>(props: BaseAreaProps<E>) {
  const {
    title,
    trail,
    description,
    headerContainerProps = {
      py: '12px',
      px: '20px',
    },
    containerProps={},
    component = 'div',
    children,
    ...layoutProps
  } = props
  const titleComponent = typeof title === 'string' ? <Typography variant={'BaseM_B'}>{title}</Typography> : title;
  const trailComponent = typeof trail === 'string' ?
      <Badge border color={'default'} size={'small'}>{trail}</Badge> : trail;
  const descriptionComponent = typeof description === 'string' ?
      <Typography variant={'FootNote'} color={'typography.secondary'}>{description}</Typography> : description
  return (
      <Container
          component={component}
          sx={{
            position: 'relative',
          }}
          {...layoutProps}
      >
        {
            title &&
            <Container
                {...headerContainerProps}
            >
              <AreaHeader title={titleComponent} trail={trailComponent} description={descriptionComponent}/>
            </Container>
        }
        <Container
            {...containerProps}
        >
        {children}
        </Container>
      </Container>
  )
}

export type AreaHeaderProps = {
  title: ReactNode,
  description?: ReactNode,
  trail?: ReactNode
}

export function AreaHeader(props: AreaHeaderProps) {
  const {title, description, trail} = props;
  return (
      <Stack
          row
          width={'100%'}
          justifyContent={"stretch"}
      >
        <Stack
            alignSelf={'center'}
            flex={1}
            column
        >
          {title}
          {description}
        </Stack>
        {trail && trail}
      </Stack>
  )
}

export type CollapsableAreaProps<E extends ElementType> = Omit<BaseAreaProps<E>, 'trail'> & {
  open?: boolean,
  onToggle?: (open?: boolean) => void,
}


export function CollapsableArea<E extends ElementType>(props: CollapsableAreaProps<E>) {
  const {open, onToggle, children, collapsable, ...areaProps} = props; void collapsable // 명시적 collapsable 제외
  const isControlled = open !== undefined && typeof onToggle === 'function';
  const [_open, setOpen] = useState<boolean>(true);
  const collapseIn = isControlled ? open : _open;
  const handleToggle = () => {
    if (isControlled) {
      onToggle(open);
    } else {
      setOpen((open) => !open);
    }
  }

  return (
      <BaseArea
          {...areaProps as BaseAreaProps<E>}
          trail={
            <IconButton onClick={handleToggle}>
              {
                collapseIn
                    ? 'expand_less'
                    : 'expand_more'
              }
            </IconButton>
          }
      >
        <MuiCollapse
            in={collapseIn}
        >
          {children}
        </MuiCollapse>
      </BaseArea>
  )

}


export type AreaProps<E extends ElementType> = CollapsableAreaProps<E> | BaseAreaProps<E>

function isCollapsableAreaProps<E extends ElementType>(props: AreaProps<E>): props is CollapsableAreaProps<E> {
  return props.collapsable || ('open' in props && 'onToggle' in props && props.open !== undefined && props.onToggle !== undefined);
}

export default function Area<E extends ElementType>(props: AreaProps<E>) {
  const isCollapsable = isCollapsableAreaProps(props);
  return (isCollapsable
          ? <CollapsableArea {...props}/>
          : <BaseArea {...props}/>
  )
}
