import {ReactNode} from "react";
import {default as MuiStack} from "@mui/material/Stack";
import {default as MuiBox} from "@mui/material/Box";
import Headline, {HeadlineProps} from "~/components/Headline";


export type SpotlightProps = {
    lead?: ReactNode,
    trail?: ReactNode,
} & HeadlineProps

export default function Spotlight(props: SpotlightProps) {
    const {lead, trail, ...headlineProps} = props;
    return (
        <MuiStack
            flexDirection={'column'}
            alignItems={'center'}
            gap={'12px'}
            sx={{
                py:'25vh'
            }}
        >
            {
                lead &&
                <MuiBox>
                    {lead}
                </MuiBox>
            }
            <Headline
                size={'large'}
                gap={'6px'}
                {...headlineProps}
            />
            {
                trail &&
                <MuiBox>
                    {trail}
                </MuiBox>
            }
        </MuiStack>
    )
}


