import Cookies from 'js-cookie';


export type CookieName = 'Authorization'|'Kup-Locale'

export function getCookie(name:CookieName):string|undefined{
  return Cookies.get(name);
}

export function setCookie(name:CookieName, value:string, expires:number = 30){
  Cookies.set(name,value,{expires});
}