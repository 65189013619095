import {Notification} from '~kup/models/Notification.ts';
import {getNotifications} from "~kup/controllers/notification.ts";
import {useEffect, useState} from "react";

export default function useKupNotifications(onlyUnread?: boolean) {
  const [notifications, setNotifications] = useState<Notification[]>([])
  useEffect(() => {
    getNotifications(onlyUnread).then(setNotifications).catch(console.error);
  }, [onlyUnread]);
  return notifications
}