export type IconBasketProps = {
  width?: string,
  height?: string,
}
export default function IconBasket(props: IconBasketProps) {
  const {width = '32px', height = '32px'} = props;
  return (
      <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.29636 20C5.93988 20 5.63467 19.8679 5.38072 19.6037C5.12691 19.3394 5 19.0218 5 18.6507V8.26191C5 7.89087 5.12691 7.57327 5.38072 7.3091C5.63467 7.04479 5.93988 6.91263 6.29636 6.91263H8.24081C8.24081 5.83785 8.59832 4.91702 9.31336 4.15013C10.0285 3.38338 10.9025 3 11.9353 3C12.9932 3 13.895 3.37892 14.6408 4.13677C15.3864 4.89449 15.7592 5.81977 15.7592 6.91263H17.7036C18.0601 6.91263 18.3653 7.04479 18.6193 7.3091C18.8731 7.57327 19 7.89087 19 8.26191V18.6507C19 19.0218 18.8731 19.3394 18.6193 19.6037C18.3653 19.8679 18.0601 20 17.7036 20H6.29636ZM6.29636 18.6507H17.7036V8.26191H6.29636V18.6507ZM9.53697 6.91263H14.463C14.463 6.1931 14.2254 5.58596 13.75 5.0912C13.2746 4.59658 12.6913 4.34927 12 4.34927C11.3087 4.34927 10.7254 4.59658 10.25 5.0912C9.77465 5.58596 9.53697 6.1931 9.53697 6.91263ZM11.9899 13.119C12.9468 13.119 13.8132 12.7772 14.589 12.0936C15.3649 11.4101 15.7506 10.6433 15.7464 9.79332C15.7464 9.61792 15.6837 9.46499 15.5583 9.33452C15.433 9.20406 15.2768 9.13882 15.0897 9.13882C14.9356 9.13882 14.798 9.19279 14.6769 9.30073C14.5558 9.40866 14.4759 9.55708 14.437 9.74597C14.3031 10.3396 14.0049 10.8253 13.5425 11.2031C13.0803 11.5809 12.564 11.7698 11.9936 11.7698C11.4232 11.7698 10.9057 11.5809 10.4411 11.2031C9.97668 10.8253 9.67963 10.3396 9.55 9.74597C9.51111 9.55262 9.4312 9.40313 9.31025 9.29749C9.18918 9.19171 9.05086 9.13882 8.89531 9.13882C8.71823 9.13882 8.56592 9.20406 8.43836 9.33452C8.31093 9.46499 8.24722 9.61792 8.24722 9.79332C8.24722 10.6439 8.63177 11.4108 9.40086 12.0942C10.1701 12.7774 11.0331 13.119 11.9899 13.119Z"
            fill="currentColor"/>
      </svg>

  )
}