import Page from '~/components/Page';
import Container from '~/components/Container';
import Typography from '~/components/Typography';

export default function OrderAgreements() {
  return (
      <Page
          type={'modal'}
          name={'Order Agreements'}
      >
        <Container
            pb={'20px'}
            px={'20px'}
        >
          <Container py={'8px'}>
            <Typography variant={'TitleS_B'} component={'p'}>
              1. Introduction
            </Typography>
            By placing an order with Ktourstory Ltd., you agree to the following terms and conditions:
          </Container>

          <Container py={'8px'}>
            <Typography variant={'TitleS_B'} component={'p'}>
              2. Personal Information Collection and Use
            </Typography>
            You consent to the collection and use of your personal information, including your name, contact details,
            shipping address, and payment information, for the purposes of processing your order, delivering products,
            and providing customer service. Your personal information will be stored on Google Cloud servers located in
            Korea and Singapore and may be shared with third parties such as courier companies and payment processors
            solely for these purposes.
          </Container>

          <Container py={'8px'}>
            <Typography variant={'TitleS_B'} component={'p'}>
              3. Refund and Cancellation Policy
            </Typography>
            <ul>
              <li>
                Orders can be canceled before the product enters the preparation phase for delivery. Once the product is being prepared, cancellations are no longer possible, and the refund process must be followed.
              </li>
              <li>
                Refunds are available within 14 days of receiving the product, provided the product is unused and in a resalable condition. Refunds due to product defects or damage during shipping are also available. However, refunds for change of mind are not permitted.
              </li>
              <li>
                Customers are responsible for the cost of return shipping, which is calculated at 10,000 KRW (5,000 KRW each way) for domestic returns. For international returns, customers must cover the full cost of international shipping.
              </li>
            </ul>
          </Container>

          <Container py={'8px'}>
            <Typography variant={'TitleS_B'} component={'p'}>
              4. Seller and Ktourstory Ltd. Responsibilities
            </Typography>
            Ktourstory Ltd. is responsible for selling and delivering products but does not manufacture the products. We
            provide product information as supplied by the manufacturer and include AI-translated content for your
            convenience. Please note that AI translations may contain errors, and it is your responsibility to verify
            critical information using the original language provided. Ktourstory Ltd. is not liable for any issues
            arising from the products themselves.
          </Container>

          <Container py={'8px'}>
            <Typography variant={'TitleS_B'} component={'p'}>
              5. Delivery Obligations
            </Typography>
            Ktourstory Ltd. directly handles the delivery of products. To ensure successful delivery, you must take
            necessary actions, such as being present at the hotel lobby if you are staying at a hotel or arranging for
            the product to be held at the hotel lobby. Once delivery is completed, it is your responsibility to promptly
            pick up and secure the delivered items.
          </Container>

          <Container py={'8px'}>
            <Typography variant={'TitleS_B'} component={'p'}>
              6. Minors' Purchases
            </Typography>
            Purchases made by minors require the consent of a parent or legal guardian. If a purchase is made without
            such consent, the contract may be canceled at the request of the parent or legal guardian.
          </Container>

          <Container py={'8px'}>
            <Typography variant={'TitleS_B'} component={'p'}>
              7. Electronic Document Consent
            </Typography>
            Order-related information such as order confirmations and receipts will be provided in printed form with the
            delivery. Terms and conditions, as well as policy updates, will be posted on the kup.travel or kup.tours
            websites, and it is your responsibility to check these sites regularly for updates.
          </Container>

          <Container py={'8px'}>
            <Typography variant={'TitleS_B'} component={'p'}>
              8. Changes to Terms and Conditions
            </Typography>
            Ktourstory Ltd. reserves the right to modify these terms and conditions at any time. Changes will be posted
            on the kup.travel or kup.tours websites. Continued use of our services after changes are posted constitutes
            your acceptance of the updated terms.
          </Container>

          <Container py={'8px'}>
            <Typography variant={'TitleS_B'} component={'p'}>
              9. Governing Law and Jurisdiction
            </Typography>
            These terms and conditions are governed by the laws of the Republic of Korea. Any disputes arising from your
            use of our services will be subject to the exclusive jurisdiction of the Seoul Central District Court.
          </Container>
          <Container py={'8px'}>
            <Typography variant={'TitleS_B'} component={'p'}>
              10. Responsibility for Providing Accurate Information
            </Typography>
            It is the customer's responsibility to provide accurate and complete information when placing an order.
            Ktourstory Ltd. will not be held liable for any issues arising from incorrect or incomplete order information
            provided by the customer, including but not limited to wrong delivery addresses, incorrect contact details,
            or other errors that may affect the processing and delivery of the order. Any additional costs incurred due
            to such errors will be the customer's responsibility.
          </Container>

          <Container py={'8px'}>
            <Typography variant={'TitleS_B'} component={'p'}>
              11. Customer Inquiries
            </Typography>
            For inquiries regarding refunds or service usage, please contact us at support@kup.travel. Our customer
            support team is available to assist you with any issues related to your order or service experience.
          </Container>
        </Container>
      </Page>
  )
}