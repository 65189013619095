import Dialog from "~/components/Dialog.tsx";
import Container from "~/components/Container.tsx";
import Typography from "~/components/Typography.tsx";
import useKintStore from "~kint/store";
import useChatNotificationToken from "~/hooks/useChatNotificationToken.ts";
import useKupNotificationToken from "~/hooks/useKupNotificationToken.ts";


export default function TurnOffNotificationDialog(props: {
  open: boolean,
  onClose: () => void
}) {
  const {open, onClose} = props;
  const {participant} = useKintStore((state)=>({
    participant:state.participant,
  }));

  const {disableNotification} = useKupNotificationToken();
  const {disableChatNotification} = useChatNotificationToken();


  const handleTurnOff = ()=>{
    disableNotification()
    if(participant)
      disableChatNotification();
    onClose();
  }

  return (
      <Dialog
          title={"Stay Updated with Important Information"}
          open={open}
          onClose={onClose}
          onConfirm={onClose}
          confirm={'Keep Turn On'}
          onCancel={handleTurnOff}
          cancel={'Turn Off'}
      >
        <Container
            px={'20px'}
            stackProps={{
              gap: '8px'
            }}
        >
          <Typography color={'typography.secondary'} whiteSpace={'pre-wrap'} mt={2}>
            <strong>Before You Turn Off Notifications</strong><br/>
            Please consider staying updated with the latest updates. Missing out on important updates might cause
            you to miss crucial information.<br/><br/>
            <strong>Want to block for this device?</strong><br/>
            If you decide to block notifications, you can always disable it on your browser settings.<br/><br/>
            <strong>Installed?</strong><br/>
            {'On iOS, go to Settings > Notifications > Kup and enable notifications.'}<br/>
            {'On Android, go to Settings > Apps & notifications > Kup > Notifications and enable them.'}<br/>
          </Typography>
        </Container>
      </Dialog>
  )
}