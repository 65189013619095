import { Slice } from "~common/store";
import Auth from "~kup/models/Auth.ts";

export interface AuthSlice {
  auth: Auth | null;
  setAuth: (auth: Auth) => void;
}

export interface PersistedAuthSlice {
  auth: Auth | null;
}

export const persistAuthSlice: (state: AuthSlice) => PersistedAuthSlice = (state) => ({
  auth: state.auth,
});

export const createAuthSlice: Slice<AuthSlice> = (set) => ({
  auth: null,
  setAuth: (auth) => set(() => ({ auth })),
});
