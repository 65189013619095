import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Box from "~/components/Box.tsx";
import usePromotions from "~/hooks/usePromotions.ts";
import Slide from '~/components/Slide';
import useKintStore from "~kint/store";
import dayjs from "dayjs";

export default function PromotionBanner({timeout, beginAt}: { timeout: number, beginAt: Date, }) {
  const timeoutMillsecs = timeout * 1000;
  const endAt = new Date(beginAt.getTime() + timeoutMillsecs);
  const today = dayjs().format('YYYY-MM-DD');
  const {reservation} = useKintStore((state) => ({reservation: state.reservation}));
  const {promotions} = usePromotions({isHighlight: true});
  const [hidden, setHidden] = useState<boolean>(false);
  const [remainTimeout, setRemainTimeout] = useState<number>(timeoutMillsecs);
  const [closeTransitionEnd, setCloseTransitionEnd] = useState<boolean>(false);

  useEffect(() => {
    if (hidden) return;
    if (endAt.getTime() - Date.now() < 0) setHidden(true);

    const intervalId = setInterval(() => {
      const remainTime = endAt.getTime() - Date.now();
      setRemainTimeout(remainTime);
      if (remainTime <= 0) {
        setHidden(true)
      }
    }, 1000)

    return () => {
      if (intervalId) clearInterval(intervalId)
    }
  }, [hidden, endAt.getTime()]);


  if (!reservation?.date || reservation.date !== today) return null;
  if (promotions && promotions?.length <= 0) return null;
  if (closeTransitionEnd) return null;
  return (
    <Slide in={!hidden}
           appear={false}
           timeout={1000}
           onTransitionEnd={() => {
             if (hidden) {
               setCloseTransitionEnd(true)
             }
           }}>
      <Box
        sx={(theme) => ({
          position: 'sticky',
          top: '52px',
          zIndex: 500,
          width: '100%',
          display: closeTransitionEnd ? 'none' : 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          background: theme.palette.background.paper,
          padding: '4px',
        })}
      >
        <Link to={'/shop/product/4b27f2cf-77a5-49d3-9458-8727934eadd6'} style={{position: 'relative'}}>
          <img src={'/maskpack3x.png'} width={"100%"} style={{maxHeight: '80px'}} onClick={console.log}/>
          <span
            style={{
              position: 'absolute',
              zIndex: 501,
              right: '8px',
              top: '8px',
              width: '18px',
              height: '18px',
              borderRadius: '18px',
              fontSize: '10px',
              color: 'white',
              border: 'solid 2px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              opacity: 0.8,
            }}
          >
        {Math.ceil(Math.abs(remainTimeout / 1000))}
      </span>
        </Link>
      </Box>
    </Slide>
  )
}