import {useLocation} from "react-router-dom";
import Page from '~/components/Page';
import Container from "~/components/Container.tsx";
import ProductList from "~/components/ProductList.tsx";
import Typography from "~/components/Typography.tsx";
import useProductsKeepState from "~/hooks/useProductsKeepState.ts";
import Box from "@mui/material/Box";
import useTag from "~/hooks/useTag.ts";


export default function ShopTagPage() {
  const location = useLocation();
  const hash = location.hash;
  const tagName = hash.split('/').at(-1) ?? '';
  const tag = useTag(tagName);
  const {products, total, hasNextPage, next} = useProductsKeepState(`tag:${tagName}`, {tags: [tagName]}, {pageSize: 6});
  const imgUrl = tag?.headImageUrls?.[0]?.url ?? '';
  if (!tag) return null;
  return (
    <Page
      type={'page'}
      name={
        <Typography color={'typography.secondary'}>
          {tag.name.toUpperCase()}
        </Typography>
      }
      sx={{}}
      navigationBarProps={{
        position: {position: 'relative', top: 0, left: 0, right: 0},
        background: "transparent"
      }}
    >
      <Container
        pb={'20px'}
      >
        <TagPageHeader headerImage={imgUrl} headline={tag.headline}/>
        <ProductList title={<Typography variant={'FootNote'} color={'white'}>{total} products</Typography>}
                     products={products}
                     type={'pair'}
                     background={'transparent'}
                     onInfiniteScroll={hasNextPage ? () => false && next() : undefined}
        />
      </Container>
    </Page>
  )
}


function TagPageHeader(props: { headerImage: string, headline: string }) {
  const {headerImage, headline} = props;
  return (
    <Container
      sx={{
        overflowX: 'clip',
        position: 'relative',
      }}
    >
      <img
        src={headerImage}
        style={{
          width: '100%',
          maxHeight: '50vh',
          filter: 'blur(100px)',
          transform: "scale(1.2)",
          borderRadius: '100px',
          overflow: 'clip'
        }}
      />
      <img
        src={headerImage}
        style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          maxHeight: 'calc(100% - 40px)',
          maxWidth: 'calc(100% - 80px)',
          overflow: "hidden",
          borderRadius: '8px',
        }}
      />
      <Box
        sx={{
          display: 'inline-flex',
          flexDirection: 'column',
          position: 'absolute',
          bottom: '32px',
          left: '20px',
          zIndex: 1,
        }}
      >
        <Typography
          variant={'TitleS_B'}
          fontWeight={'bold'}
          component={'p'}
          color={'white'}
          whiteSpace={'pre-wrap'}
          sx={{
            textShadow: '1px 2px 4px rgba(0,0,0,0.22)'
          }}
        >
          {headline}
        </Typography>
      </Box>
    </Container>

  )
}
