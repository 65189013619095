import {kintFirestore} from "~kint/firebase/firestore";
import {getChatting} from "~kint/controllers/getChatting.ts";
import {IChattingMessage, IChattingParticipant} from "~kint/models/Chatting.ts";

export default async function sendMessage(
    chattingId: string,
    message: { type: string, text?: string, files?: string[] },
    sender: IChattingParticipant,
    replyMessage?: IChattingMessage
) {

  const chatting = await getChatting(chattingId);
  if (!chatting) return;
  const {participants} = chatting;
  const readStatus = Object.keys(participants ?? {}).reduce((r, pId: string) => {
    return {
      ...r,
      [pId]: pId === sender.id,
    }
  }, {} as { [pId: string]: boolean })

  let data = {}
  switch (message.type) {
    case "text": {
      data = {
        text: message.text,
        readStatus,
      }
      break
    }
    case "image": {
      data = {
        files: message.files,
        readStatus,
      }
      break
    }
    case "reply": {
      data = {
        text: message.text,
        reply: replyMessage,
        readStatus,
      }
    }
  }

  await kintFirestore.pushDoc(["chats", chattingId, "messages"], {
    type: message.type,
    date: kintFirestore.serverTimestamp(),
    sender,
    ...data,
  })

  await kintFirestore.putDoc(["chats", chattingId], {
    updatedAt: kintFirestore.serverTimestamp(),
  })
}