import {useEffect, useRef, useState} from "react";

import Box from "~/components/Box.tsx";
import Typography from "~/components/Typography.tsx";
import Badge from "~/components/Badge.tsx";
import useDeliveryCutOff from "~/pages/shop/hooks/useDeliveryCutOff.ts";


export default function OrderDeliveryTimeLimit() {
  const [showBanner, setShowBanner] = useState(false);
  const componentRef = useRef<HTMLDivElement | null>(null);
  const {deliveryCutOff} = useDeliveryCutOff();

  useEffect(() => {
    const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setShowBanner(false);
          } else {
            setShowBanner(true);
          }
        },
        {threshold: 0}
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, []);


  return (
      <>
        <Box ref={componentRef} sx={{display: 'flex', justifyContent: 'space-between', alignItems:'center'}}>
          <Typography variant={'FootNote_B'} color={'typography.secondary'}>
            Order Now, Get it {deliveryCutOff.deliveryDay.toUpperCase()}
          </Typography>

          <Badge
              color={"primary"}
          >
            {deliveryCutOff.timeUntilCutOff.text}
          </Badge>
        </Box>
        {
            showBanner && <OrderDeliveryTimeLimitBanner/>
        }
      </>
  )
}


function OrderDeliveryTimeLimitBanner() {
  const {deliveryCutOff} = useDeliveryCutOff();
  return (
      <Box
          sx={(theme) => ({
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 900,
            backgroundColor:theme.palette.background.paper
          })}
      >
        <Box
            sx={(theme) => ({
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: theme.palette.primary.light,
              py: '8px',
              gap: '8px',
            })}
        >
          <Typography
              variant={"BaseS_B"}
          >
            Order Now, Get it {deliveryCutOff.deliveryDay.toUpperCase()}
          </Typography>

          <Badge
              color={"primary"}
          >
            {deliveryCutOff.timeUntilCutOff.text}
          </Badge>
        </Box>
      </Box>
  )
}