import {useEffect, useState} from "react";
import useKintStore from "~kint/store";
import listenChattings from "~kint/controllers/listenChattings.ts";
import {IChatting} from "~kint/models/Chatting.ts";


export default function useListenChattings() {
  const [chattings, setChattings] = useState<IChatting[]>([]);
  const { participant } = useKintStore((state) => ({ participant: state.participant }));

  useEffect(() => {
    if (participant?.id) {
      const unsubscribe = listenChattings(participant.id, (list) => {
        setChattings(list);
      });

      return () => unsubscribe();
    }
  }, [participant?.id]);

  return chattings;
}