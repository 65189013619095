import {ReactNode} from "react";
import {Alert} from "@mui/material";
import {default as MuiSnackBar} from '@mui/material/Snackbar';
import useSafeArea from "~/hooks/useSafeArea.ts";


export type Message = {
  message: string,
  lift?: string | number | true
  action?: ReactNode,
  onClose?: (message: SnackBarMessage) => void
}
export type AlertMessage = Message & { severity: 'info' | 'success' | 'warning' | 'error' };
export type SnackBarMessage = string | Message | AlertMessage

function isMessage(message: SnackBarMessage): message is Message {
  return typeof message !== 'string';
}

function isAlertMessage(message: SnackBarMessage): message is AlertMessage {
  return typeof message !== 'string' && 'severity' in message;
}

export type Position = {
  top?: string | number,
  bottom?: string | number,
  left?: string | number,
  right?: string | number,
}

export type SnackBarProps = {
  message: SnackBarMessage,
  open?: boolean,
  duration?: number,
  position?: Position,
  onClose?: () => void;
}

export default function SnackBar(props: SnackBarProps) {
  const {open = true, duration = 3000} = props;
  const {message} = props;
  const {onClose} = props
  const {bottomSafeArea} = useSafeArea();
  const messageObj: Message = !isMessage(message) ? {message: message, lift: bottomSafeArea + 12, action: null} : message;
  const {position = {bottom: (messageObj.lift === true) ? bottomSafeArea + 84 : (messageObj.lift ?? bottomSafeArea + 12)}} = props;
  const assignableProps = {
    open,
    autoHideDuration: duration,
    onClose: () => {
      onClose?.();
      messageObj.onClose?.(message);
    },
    anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
    sx: {...position}
  } as const

  return isAlertMessage(messageObj) ?
      (
          <MuiSnackBar
              {...assignableProps}
          >
            <Alert
                severity={messageObj.severity}
                action={messageObj.action}
            >
              {messageObj.message}
            </Alert>

          </MuiSnackBar>
      )
      : (
          <MuiSnackBar
              {...assignableProps}
              message={messageObj.message}
              action={messageObj.action}
          />
      )
}