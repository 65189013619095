import {default as MuiBox, BoxProps as MuiBoxProps} from "@mui/material/Box";

import {ColorNames, Inherit, Fill, isColorName, isFill, isInherit} from "~/components/commons/ColorNames";
import {Palette} from "@mui/material";


export type ImageViewProps = MuiBoxProps & {
    src: string,
    width?: string,
    height?: string,
    maxWidth?: string,
    maxHeight?: string,
    minWidth?: string,
    minHeight?: string,
    borderRadius?: string,
    backgroundColor?: ColorNames | Inherit | Fill | string,
    objectFit?: 'fill' | 'cover' | 'contain',
    objectPosition?: 'top' | 'left' | 'bottom' | 'right' | 'center' | 'inherit',

}

export default function ImageView(props: ImageViewProps) {
    const {
        src,
        width,
        height,
        maxWidth,
        maxHeight,
        minWidth,
        minHeight,
        borderRadius ='6px',
        backgroundColor='grey',
        objectFit = 'cover',
        objectPosition = 'center',
        ...restProps
    } = props;
    return (
        <MuiBox
            {...restProps}
            component={'img'}
            loading={'lazy'}
            src={src}
            sx={(theme) => ({
                height,
                width,
                maxWidth,
                maxHeight,
                minWidth,
                minHeight,
                borderRadius,
                objectFit,
                objectPosition,
                backgroundColor:
                    (backgroundColor)
                        ? isColorName(backgroundColor)
                            ? (theme.palette[backgroundColor] as Palette['primary']).light
                            : isFill(backgroundColor)
                                ? theme.palette.fill.primary
                                : isInherit(backgroundColor)
                                    ? 'inherit'
                                    : theme.palette.fill.primary
                        : undefined
            })}
        />
    )
}