import {ReactNode} from "react";
import Box from "@mui/material/Box";
import {
  Inherit,
  isInherit,
  isColorName,
  AllPredefinedColors,
  isTypographyColors,
  TypographyColorTrails,
  isFill,
  isSystemColor,
  isDefault,
} from "~/components/commons/ColorNames";
import {Default} from "~/components/commons/ValueTypes";


type Color = AllPredefinedColors | Default | Inherit;
export type BadgeProps = {
  children: ReactNode,
  border?: boolean,
  size?: 'small' | 'large',
  color?: Color,
  strike?: boolean,
}


export default function Badge(props: BadgeProps) {
  const {children, border = false, size = 'large', color = 'typography.tertiary', strike = false} = props;
  return (
      <Box
          sx={(theme) => {
            const checkOtherBackgroundColors = (color: Color) => {
              return isFill(color) ? theme.palette.fill.primary : 'none';
            };
            const checkColorValue = (color: Color) => {
              const tailingColor = color.split('.').at(-1) as TypographyColorTrails;
              return isTypographyColors(color) && tailingColor ? theme.palette.typography[tailingColor] : isFill(color) ? theme.palette.fill.primary : isDefault(color) ? theme.palette.typography.tertiary : color;
            };

            const checkColorPalette = (color: Color) => isColorName(color) ? theme.palette[color] : isSystemColor(color) ? theme.palette.system[color] : null;

            const backgroundColorVal = checkColorPalette(color)?.light ?? checkOtherBackgroundColors(color) ?? undefined;
            const colorVal = color ? checkColorPalette(color)?.main ?? checkColorValue(color) : 'inherit';

            return ({
              flexGrow:0,
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: size === 'large' ? '30px' : '22px',
              py: size === 'large' ? '4px' : '2px',
              px: size === 'large' ? '8px' : '6px',
              backgroundColor: backgroundColorVal,
              color: colorVal,
              borderRadius: '6px',
              border: border ? 'solid 1px' : undefined,
              borderColor: isInherit(color) || isColorName(color)
                  ? 'currentColor'
                  : theme.palette.divider,
              textDecoration: strike ? 'line-through' : 'none',
              whiteSpace:'nowrap',
              ...theme.typography.FootNote_B
            })
          }}
      >
        {children}
      </Box>
  )
}
