import Box from '@mui/material/Box';
import { Container, Stack, Typography } from '@mui/material';
import Badge from '~/components/Badge.tsx';
import useTimeoutCutOff from '~/hooks/useTimeoutCutOff.ts';
import ProductList from '~/components/ProductList.tsx';
import { Promotion } from '~kup/models/Promotion.ts';
import useProductsKeepState from '~/hooks/useProductsKeepState.ts';

type PromotionSectionProps = {
  promotion: Promotion,
};

export default function PromotionSection(props: PromotionSectionProps) {
  const { promotion } = props;
  const { products } = useProductsKeepState(
    `tag:${promotion.id}`,
    { promotionId: promotion.id }, { pageSize: 4 },
  );

  return (
    <Box
      sx={{
        overflow: 'clip',
      }}
    >
      <PromotionHeader />
      <ProductList
        products={products}
        type={'vertical'}
        background={'transparent'}
      />
    </Box>
  );
}

function PromotionHeader() {
  const { timeoutCutOff } = useTimeoutCutOff({ cutOffTime: 24, cutOffDate: new Date() });

  return (
    <Container>
      <Stack sx={{ mb: 2, px: '20px' }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="column">
            <Typography variant="BaseM_B">🔥LIMITED TIME OFFER🔥</Typography>
            <Typography variant="BaseM_B">Only 1,000 KRW!</Typography>
          </Stack>
          <Badge color={'error'} size="large">
            {timeoutCutOff.timeUntilCutOff.text} left
          </Badge>
        </Stack>
      </Stack>
    </Container>
  );
}