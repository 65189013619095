import Dialog from "~/components/Dialog.tsx";
import useChatNotificationToken from "~/hooks/useChatNotificationToken.ts";
import Container from "~/components/Container.tsx";
import Typography from "~/components/Typography.tsx";
import {useEffect, useState} from "react";

export default function NotificationPermissionDialog(props: {
  timeout?: number, open?: boolean, onClose?: () => void
}) {
  const {timeout = 3000, open, onClose} = props;
  const [show, setShow] = useState<boolean>(open ?? false);
  const {
    requestToken,
    disableRequest,
    enableChatNotification,
    isNeededToShowDialog,
  } = useChatNotificationToken();

  const handleClose = ()=>{
    setShow(false);
    onClose?.();
  }

  const handleSkip = () => {
    disableRequest();
    handleClose();
  }

  const handleConfirm = ()=>{
    requestToken();
    enableChatNotification();
    handleClose();
  }

  useEffect(() => {
    if (!isNeededToShowDialog) {
      onClose?.();
    }
  }, [isNeededToShowDialog]);

  useEffect(() => {
    if (timeout) {
      setTimeout(() => {
        setShow(true);
      }, timeout)
    } else {
      setShow(true);
    }
  }, [timeout]);


  if (!isNeededToShowDialog) {
    return null;
  }


  if (open === false) {
    return null;
  }

  if (!open && !isNeededToShowDialog) {
    return null;
  }

  if (!show) {
    return null;
  }

  return (
      <Dialog
          title={"Stay Updated with Tour Chat"}
          open={show}
          onClose={handleClose}
          onConfirm={handleConfirm}
          confirm={'Turn On'}
          onCancel={handleSkip}
          cancel={'Skip'}
      >
        <Container
            px={'20px'}
            maxWidth={false}
            stackProps={{
              gap: '8px'
            }}
        >
          <Typography color={'typography.secondary'} whiteSpace={'pre-wrap'}>
            Get real-time updates on tour chat. You can adjust your notification settings at any time.<br/>
            If you skip, chat notification requests will be hidden for 6 hours.
            <br/><br/>
            <strong>Previously blocked notifications for this device?</strong><br/>
            If you previously blocked notifications, you need to change the browser or app settings<br/>
            {'On iOS, go to Settings > Notifications > Kup and enable notifications.'}<br/>
            {'On Android, go to Settings > Apps & notifications > Kup > Notifications and enable them.'}<br/>
          </Typography>

        </Container>
      </Dialog>
  )
}