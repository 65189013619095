import {useState} from "react";

export type UseLoading = {
  startLoading:()=>void,
  stopLoading:()=>void,
  loading:boolean
}
export default function useLoading():UseLoading{
  const [loading, setLoading] = useState<boolean>(false);
  return ({
    startLoading:()=>setLoading(true),
    stopLoading: ()=>setLoading(false),
    loading
  })
}