import {useEffect} from "react";
import useKintStore from "~kint/store";
import callTeam from "~kint/controllers/callTeam.ts";

export default function useTeam() {
  const {reservation, team, setTeam} = useKintStore((state) => ({
    reservation: state.reservation,
    team:state.team,
    setTeam:state.setTeam,
  }));

  useEffect(() => {
    if (!reservation) return;
    callTeam(reservation.id)
        .then((team) => {
          setTeam(team);
        });
  }, [reservation]);
  return team;
}