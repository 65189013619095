import {Slice} from "~common/store";

export interface CommonSlice {
  language: 'en' | 'cn' | 'tw' | null,
  setLanguage: (contact: 'en' | 'cn' | 'tw' | null) => void,
}

export interface PersistedCommonSlice {
  language: CommonSlice['language'],
}

export const persistCommonSlice: (state: CommonSlice) => PersistedCommonSlice = (state) => ({
  language: state.language,
})


export const createCommonSlice: Slice<CommonSlice, CommonSlice> = (set) => ({
  language: null,
  setLanguage: (language: CommonSlice['language']) => set(() => ({language: language})),
})

