import {useLocation, useNavigate} from "react-router-dom";
import Page from '~/components/Page';
import Container from "~/components/Container.tsx";
import useProductCategories from "~/pages/shop/hooks/useProductCategories.ts";
import Tabs from "~/components/Tabs.tsx";
import ProductList from "~/components/ProductList.tsx";
import Typography from "~/components/Typography.tsx";
import useProductsKeepState from "~/hooks/useProductsKeepState.ts";


export default function ShopCategoryPage() {
  const {categories} = useProductCategories();
  const navigate = useNavigate();
  const location = useLocation();
  const hash = location.hash;
  const categorySlug = hash.split('/').at(-1) ?? '';
  // const {products, total, hasNextPage, next} = useProducts(categorySlug || 0, {pageSize: 2});
  const {
    products,
    total,
    hasNextPage,
    next
  } = useProductsKeepState(`category:${categorySlug}`, {category: categorySlug || 0,}, {pageSize: 6});
  const category = categories.find(c => c.slug === categorySlug);


  return (
      <Page
          type={'page'}
          name={category?.name}
      >
        <Container
        >
          <Container
              pb={'4px'}
          >
            <Tabs
                scrollable
                value={categorySlug}
                onChange={(_, tab) => {
                  navigate(`/shop#category/${tab}`, {replace: true});
                }}
                sx={{
                  px: "20px",
                }}
                tabs={
                  [
                    ...categories.map((category) => ({label: category.name, value: category.slug})),
                  ]
                }
            >
            </Tabs>
          </Container>
          <Container>
            {/*<ProductList title={'How about this product?'} products={products} type={'simple'}/>*/}
            {/*<Divider type={'box'} color={'fill'}/>*/}
            <ProductList title={<Typography variant={'FootNote'}>{total} products</Typography>}
                         products={products}
                         type={'pair'}
                         onInfiniteScroll={hasNextPage ? () => {
                           next()
                         } : undefined}/>
          </Container>
        </Container>
      </Page>
  )
}