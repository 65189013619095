import {Notification} from '~kup/models/Notification';
import Page from '~/components/Page';
import Container from '~/components/Container';
import Headline from '~/components/Headline';
import useKupNotifications from '~/hooks/useKupNotifications.ts';
import Card from '~/components/Card.tsx';
import {useNavigate} from 'react-router-dom';
import useKupNotification from '~/hooks/useKupNotification.ts';
import Badge from '~/components/Badge.tsx';
import {Stack} from '@mui/material';
import Spotlight from "~/components/Spotlight.tsx";

export default function MessageNotificationsPage() {
  const notifications = useKupNotifications();
  const {markAsRead} = useKupNotification();
  const navigate = useNavigate();
  const handleClickNotification = (notification: Notification) => () => {
    if (notification.payload?.path) {
      navigate(notification.payload?.path);
    } else {
      navigate(`/message/notification/${notification.id}`);
    }
    markAsRead(notification.id);
  };
  return (
      <Page
          type={'modal'}
      >
        <Container
            py={'16px'}
            px={'20px'}
        >
          <Headline
              headline={'Notification'}
          />
        </Container>
        <Container
            px={'20px'}
            stackProps={{
              column: true,
              gap: '16px',
            }}
        >
          {(notifications.length == 0) && (
              <Spotlight
                  size={'large'}
                  textAlign={'center'}
                  headline={'No notifications for now'}
                  headlineProps={{color: 'typography.tertiary'}}
              />
          )}
          {notifications.map(notification => (
              <Card
                  key={notification.id}
                  sx={{flexShrink: 0, width: '100%'}}
                  onClick={handleClickNotification(notification)}
              >
                <Stack direction="row" justifyContent="space-between">
                  <Headline
                      headline={notification.title.slice(0, 32) + (notification.title.length > 32 ? '...' : '')}
                      description={notification.description}
                  />
                  {notification.unread && <Badge color={'error'} size={'small'}>Unread</Badge>}
                </Stack>
              </Card>
          ))}
        </Container>
      </Page>
  );
}