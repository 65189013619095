import {FirebaseApp} from 'firebase/app';
import {
  getMessaging,
  getToken,
  isSupported,
  MessagePayload,
  Messaging,
  onMessage,
  Unsubscribe
} from "firebase/messaging";


export class FMessaging {
  app: FirebaseApp
  messaging: Messaging | null;

  constructor(app: FirebaseApp) {
    this.app = app;
    this.messaging = null;
    this.initMessaging().catch(console.error);
  }

  private async initMessaging(): Promise<void> {
    const supported = await isSupported();
    if (supported) {
      this.messaging = getMessaging(this.app);
    }
  }

  private async ensureMessagingInitialized(): Promise<boolean> {
    if (!this.messaging) {
      await this.initMessaging();
    }
    return this.messaging !== null;
  }

  async isSupported(): Promise<boolean> {
    return await isSupported();
  }

  isPermissionGranted(): boolean {
    return window?.Notification?.permission === 'granted'
  }

  async requestPermission(): Promise<boolean> {
    if (!await this.isSupported()) {
      return false;
    }
    if (this.isPermissionGranted()) return true;
    try {
      return 'granted' === await Notification.requestPermission();
    } catch (e) {
      console.error(e);
      return false;
    }
  }


  async getToken(): Promise<string | null> {
    const messagingInitialized = await this.ensureMessagingInitialized();
    if (!messagingInitialized || !this.isPermissionGranted()) {
      return null;
    }
    try {
      if(!this.messaging) return null;
      return await getToken(this.messaging, {vapidKey: import.meta.env.VITE_VITE_FIREBASE_VAPID_KEY});
    } catch (e) {
      return await new Promise((resolve) => {
        setTimeout(() => {
          if (this.messaging)
            getToken(this.messaging, {vapidKey: import.meta.env.VITE_VITE_FIREBASE_VAPID_KEY}).then(resolve)
        }, 500)
      });
    }
  }

  async requestToken(): Promise<string | null> {
    if (!await this.ensureMessagingInitialized()) {
      return null;
    }
    if (!this.isPermissionGranted()) {
      const isGranted = await this.requestPermission();
      if (!isGranted) return null;
    }
    return await this.getToken();
  }

  onMessage(listener: (payload: MessagePayload) => void): Unsubscribe {
    if (!this.messaging) {
      return ()=>{};
    }
    return onMessage(this.messaging, listener);
  }

}
