import {useCallback, useEffect} from "react";
import {RouterProvider} from "react-router-dom"
import {ThemeProvider, CssBaseline} from "@mui/material";

import router from "~/router"
import {theme, GlobalCSS} from "~/theme";

import PWABadge from '~/components/PWABadge'
import { ErrorBoundary } from "react-error-boundary";
import {KeepStateProvider} from "~/contexts/KeepStateContext.tsx";
import ErrorBoundaryFallBack from "~/components/ErrorFallBack/ErrorBoundaryFallBack.tsx";

import './i18n';
import '~kup/firebase/analytics';
import './sentry.ts';


function App() {
  const chatNotificationClickHandler = useCallback(
      (event: ServiceWorkerContainerEventMap['message']) => {
        switch(event.data.action){
          case 'notification-click':
            window.focus()
            if ('navigate' in window && typeof window.navigate ==='function') {
              window.navigate(event.data.url);
            } else {
              window.location.href = event.data.url;
            }
            return
        }
      },
      [window]
  )

  useEffect(() => {
    if (window && window.navigator && navigator.serviceWorker) {
      window.navigator.serviceWorker.addEventListener("message", chatNotificationClickHandler)
    }
    //todo foreground message 처리
    // const unsub = listenForeMessage((payloadData)=>console.log('foreground message', payloadData));


    return () => {
      // if (unsub) {
      //   unsub();
      // }
      if (window && window.navigator && navigator.serviceWorker) {
        window.navigator.serviceWorker.removeEventListener("message", chatNotificationClickHandler)
      }
    }
  }, [])

  return (
      <ErrorBoundary FallbackComponent={ErrorBoundaryFallBack} onError={(e)=>{console.error(e)}}>
        <ThemeProvider theme={theme}>
          <GlobalCSS/>
          <CssBaseline/>
          <KeepStateProvider>
            <RouterProvider router={router}/>
          </KeepStateProvider>
          <PWABadge/>
        </ThemeProvider>
      </ErrorBoundary>
  )
}

export default App
