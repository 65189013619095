export type LogoCosmeticProps = {
  width?:string,
  height?:string,
}
export default function IconCosmetic(props:LogoCosmeticProps) {
  const {width ='32px', height='32px'} = props;
  return (
      <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M28 17.1622L26.5 14L23.6763 9.67336C21.7547 6.34499 24.1567 4 28 4C31.8433 4 34.2453 6.34499 32.3237 9.67335L29.5 14L28 17.1622Z"
            fill="#FFD8E4"/>
        <path
            d="M7 21C7 20.4477 7.44772 20 8 20H20C20.5523 20 21 20.4477 21 21V33C21 34.6569 19.6569 36 18 36H10C8.34315 36 7 34.6569 7 33V21Z"
            fill="#3C475C"/>
        <path d="M8 16C8 15.4477 8.44772 15 9 15H19C19.5523 15 20 15.4477 20 16V20H8V16Z" fill="#6D788D"/>
        <path
            d="M10 7.08332C10 5.63457 11.4924 4.66646 12.8153 5.25704L16.8153 7.04276C17.5359 7.36447 18 8.07986 18 8.86903V15.0002H10V7.08332Z"
            fill="#EF4444"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M26.3787 10.015C26.6466 9.94807 26.9181 10.111 26.9851 10.3789L27.9851 14.3789C28.052 14.6467 27.8892 14.9182 27.6213 14.9852C27.3534 15.0522 27.0819 14.8893 27.0149 14.6214L26.0149 10.6214C25.948 10.3535 26.1108 10.082 26.3787 10.015Z"
              fill="#FFB9CE"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M29.6213 10.015C29.3534 9.94807 29.0819 10.111 29.0149 10.3789L28.0149 14.3789C27.948 14.6467 28.1108 14.9182 28.3787 14.9852C28.6466 15.0522 28.9181 14.8893 28.9851 14.6214L29.9851 10.6214C30.052 10.3535 29.8892 10.082 29.6213 10.015Z"
              fill="#FFB9CE"/>
        <path d="M26.5 15H29.5L29 23H27L26.5 15Z" fill="#E6E8E8"/>
        <path d="M27 23H29V35C29 35.5523 28.5523 36 28 36C27.4477 36 27 35.5523 27 35V23Z" fill="#3C475C"/>
        <path d="M26.5 14H29.5V15H26.5V14Z" fill="#D4D6D6"/>
      </svg>

  )
}