import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import useKupStore from "~kup/store";

import Card from "~/components/Card.tsx";
import Box from "~/components/Box.tsx";
import Typography from "~/components/Typography.tsx";
import Progress from "~/components/Progress.tsx";
import Icon from "~/components/Icon";
import ButtonBase from "~/components/ButtonBase.tsx";
import useDeliveryCutOff from "~/pages/shop/hooks/useDeliveryCutOff.ts";
import useDelivery from "~/pages/shop/hooks/useDelivery.ts";
import Logo from '~/components/Logo';

import {ButtonBaseProps} from "@mui/material";


export default function OrderDelivery() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {currency, basket, deliveryAddress} = useKupStore((state) => ({
    currency: state.currency,
    basket: state.basket,
    deliveryAddress: state.deliveryAddress,
  }));

  const {deliveryCutOff} = useDeliveryCutOff();

  const amount = basket.availableAmount

  const {left, fulfilledPercentage, isFree} = useDelivery(amount);

  const handleAddressClick = () => {
    navigate('/shop/address')
  }

  const handleOrderNowClick = () => {
    navigate('/shop/basket')
  }


  return (
      <Card>
        <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-end",
              mb: '8px',
            }}
        >
          {
            isFree
                ? (
                    <Typography
                        variant={"BaseS_B"}
                    >
                      Get it by 11 PM {deliveryCutOff.deliveryDay.toUpperCase()} with Free Delivery
                    </Typography>
                )
                : (
                    <Typography
                        variant={"BaseS_B"}
                    >
                      <Typography
                          component={"span"}
                          variant={"inherit"}
                          sx={{display: "flex", alignItems: "center"}}
                      >
                        {t("shop-main-free")}
                          <Logo color={"primary"} height={"1em"} sx={{mx:'4px'}}>
                            delivery
                          </Logo>
                        {t(deliveryCutOff.isPassedTodayCutOff ? "shop-main-byTomorrow" : "shop-main-byToday", {hours: deliveryCutOff.cutOffTime})}<br/>
                      </Typography>
                      <Typography component={"span"} color={"primary"}>
                        {t("shop-main-addAmountOrMore", {amount: `${(left).toLocaleString()}${currency}`})}
                      </Typography>
                    </Typography>
                )
          }
        </Box>
        <Box>
          <Progress
              type={"linear"}
              variant={"determinate"}
              value={fulfilledPercentage * 100}/>
          <Box
              sx={(theme) => ({
                display: "flex",
                alignItems: "center",
                gap: "8px",
                color: theme.palette.typography.secondary,
                pt: "4px",
              })}
          >
            <Typography
                variant={"Caption"}
            >
              {t("shop-main-inYourCart", {amount: `${basket.amount.toLocaleString()}${currency}`})}
            </Typography>
          </Box>
        </Box>
        <Box
            pt={'8px'}
            pb={'4px'}
        >
          {
            !deliveryAddress ? (
                    <OrderDeliveryButton
                        onClick={handleAddressClick}
                    >
                      <Icon
                          size={"1.5em"}
                          width={"24px"}
                          color={"primary"}
                      >
                        place
                      </Icon>
                      <Typography
                          flex={1}
                          variant={'FootNote_B'}
                      >
                        {t("shop-main-whichHotelAreYou")}
                      </Typography>
                      <Icon
                          size={"1.5em"}
                          width={"24px"}
                          color={"typography.tertiary"}
                      >
                        navigate_next
                      </Icon>
                    </OrderDeliveryButton>
                )
                : isFree
                    ? (
                        <OrderDeliveryButton onClick={handleOrderNowClick}>
                          <Typography
                              flex={1}
                              textAlign={"center"}
                              variant={'FootNote_B'}
                          >
                            Order Now!
                          </Typography>
                        </OrderDeliveryButton>
                    )
                    : null
          }
        </Box>
      </Card>
  );
}

function OrderDeliveryButton(props: { onClick: ButtonBaseProps['onClick'], children: ButtonBaseProps['children'] }) {
  const {onClick, children} = props;
  return (
      <ButtonBase
          sx={(theme) => ({
            display: "flex",
            width: "100%",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "10px",
            backgroundColor: theme.palette.fill.primary,
            paddingX: "16px",
            paddingY: "12px",
            borderRadius: "8px",
            borderTopLeftRadius: "0px",
          })}
          onClick={onClick}
      >
        {children}
      </ButtonBase>
  )
}