export type LogoGimbabProps = {
  width?:string,
  height?:string,
}
export default function IconGimbab(props:LogoGimbabProps) {
  const {width ='32px', height='32px'} = props;
  return (
      <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.2317 7H17.8633V27H12.2317V7Z" fill="#333333"/>
        <path
            d="M28 17C28 22.5228 23.5228 27 18 27C12.4772 27 8 22.5228 8 17C8 11.4772 12.4772 7 18 7C23.5228 7 28 11.4772 28 17Z"
            fill="#333333"/>
        <path
            d="M22 17C22 22.5228 17.5228 27 12 27C6.47715 27 2 22.5228 2 17C2 11.4772 6.47715 7 12 7C17.5228 7 22 11.4772 22 17Z"
            fill="#333333"/>
        <path
            d="M21 17C21 21.9706 16.9706 26 12 26C7.02944 26 3 21.9706 3 17C3 12.0294 7.02944 8 12 8C16.9706 8 21 12.0294 21 17Z"
            fill="white"/>
        <path
            d="M5.69995 16.2593C5.69995 14.5865 7.05602 13.2305 8.72883 13.2305C10.4016 13.2305 11.7577 14.5865 11.7577 16.2593V17.1247C11.7577 18.7975 10.4016 20.1536 8.72883 20.1536C7.05602 20.1536 5.69995 18.7975 5.69995 17.1247V16.2593Z"
            fill="#FFCD00"/>
        <path
            d="M11.325 14.3125C11.325 12.7592 12.5842 11.5 14.1375 11.5C15.6908 11.5 16.95 12.7592 16.95 14.3125V15.6106C16.95 17.1639 15.6908 18.4231 14.1375 18.4231C12.5842 18.4231 11.325 17.1639 11.325 15.6106V14.3125Z"
            fill="#EF4444"/>
        <path
            d="M9.59424 19.2017C9.59424 17.3377 11.1053 15.8267 12.9692 15.8267H13.1424C15.0063 15.8267 16.5174 17.3377 16.5174 19.2017V19.3748C16.5174 21.2388 15.0063 22.7498 13.1424 22.7498H12.9692C11.1053 22.7498 9.59424 21.2388 9.59424 19.3748V19.2017Z"
            fill="#00BD79"/>
        <path d="M22.2317 13H27.8633V33H22.2317V13Z" fill="#333333"/>
        <path
            d="M38 23C38 28.5228 33.5228 33 28 33C22.4772 33 18 28.5228 18 23C18 17.4772 22.4772 13 28 13C33.5228 13 38 17.4772 38 23Z"
            fill="#333333"/>
        <path
            d="M32 23C32 28.5228 27.5228 33 22 33C16.4772 33 12 28.5228 12 23C12 17.4772 16.4772 13 22 13C27.5228 13 32 17.4772 32 23Z"
            fill="#333333"/>
        <path
            d="M31 23C31 27.9706 26.9706 32 22 32C17.0294 32 13 27.9706 13 23C13 18.0294 17.0294 14 22 14C26.9706 14 31 18.0294 31 23Z"
            fill="white"/>
        <path
            d="M15.7 22.2593C15.7 20.5865 17.056 19.2305 18.7288 19.2305C20.4016 19.2305 21.7577 20.5865 21.7577 22.2593V23.1247C21.7577 24.7975 20.4016 26.1536 18.7288 26.1536C17.056 26.1536 15.7 24.7975 15.7 23.1247V22.2593Z"
            fill="#FFCD00"/>
        <path
            d="M21.325 20.3125C21.325 18.7592 22.5842 17.5 24.1375 17.5C25.6908 17.5 26.95 18.7592 26.95 20.3125V21.6106C26.95 23.1639 25.6908 24.4231 24.1375 24.4231C22.5842 24.4231 21.325 23.1639 21.325 21.6106V20.3125Z"
            fill="#EF4444"/>
        <path
            d="M19.5942 25.2017C19.5942 23.3377 21.1053 21.8267 22.9692 21.8267H23.1424C25.0063 21.8267 26.5174 23.3377 26.5174 25.2017V25.3748C26.5174 27.2388 25.0063 28.7498 23.1424 28.7498H22.9692C21.1053 28.7498 19.5942 27.2388 19.5942 25.3748V25.2017Z"
            fill="#00BD79"/>
      </svg>

  )
}