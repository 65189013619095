export type WhatsAppLogoProps = {
  width?:string,
  height?:string,
}
export default function MessengerLogo(props:WhatsAppLogoProps) {
  const {width ='32px', height='32px'} = props;
  return (
      <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_477_17089)">
          <path
              d="M0 10C0 4.47715 4.47715 0 10 0H30C35.5228 0 40 4.47715 40 10V30C40 35.5228 35.5228 40 30 40H10C4.47715 40 0 35.5228 0 30V10Z"
              fill="url(#paint0_radial_477_17089)"/>
          <path fillRule="evenodd" clipRule="evenodd"
                d="M20 32.2645C27.1927 32.2645 33.0185 26.6091 33.0185 19.6322C33.0185 12.6554 27.1927 7 20 7C12.8072 7 6.98145 12.6554 6.98145 19.6322C6.98145 23.2664 8.55994 26.5411 11.0947 28.8473C11.3706 29.0986 11.5379 29.4459 11.5379 29.8183V32.4258C11.5379 33.2446 12.4037 33.7771 13.1392 33.4111L15.9883 31.993C16.2669 31.8544 16.5833 31.8265 16.8859 31.8991C17.8845 32.1388 18.9253 32.2645 20 32.2645ZM23.0556 19.1298L26.7659 16.3961C27.3452 15.962 28.0938 16.6552 27.7032 17.2641L25.0279 21.5915L24.2403 22.8158C23.5048 23.956 21.9556 24.2345 20.862 23.4248L18.2752 21.4872C17.8847 21.1957 17.3444 21.1957 16.9473 21.4936L13.237 24.2274C12.6577 24.6614 11.9092 23.9683 12.2997 23.3593L14.975 19.032L15.7626 17.8076C16.4982 16.6675 18.0474 16.3889 19.141 17.1987L21.7277 19.1363C22.1183 19.4278 22.6586 19.4278 23.0556 19.1298Z"
                fill="white"/>
        </g>
        <defs>
          <radialGradient id="paint0_radial_477_17089" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                          gradientTransform="translate(7.087 38.0723) scale(47.612 46.5057)">
            <stop stopColor="#1292FF"/>
            <stop offset="0.079" stopColor="#2982FF"/>
            <stop offset="0.23"  stopColor="#4E69FF"/>
            <stop offset="0.351" stopColor="#6559FF"/>
            <stop offset="0.428" stopColor="#6D53FF"/>
            <stop offset="0.754" stopColor="#DF47AA"/>
            <stop offset="0.946" stopColor="#FF6257"/>
          </radialGradient>
          <clipPath id="clip0_477_17089">
            <rect width="40" height="40" fill="white"/>
          </clipPath>
        </defs>
      </svg>

  )
}