import {ChangeEvent, useState} from "react";
import FileUploadButton, {FileUploadButtonProps} from "~/components/FileUploadButton.tsx";
import Icon from "~/components/Icon";
import Progress from '~/components/Progress';
import heic2any from "heic2any";

export type ImageUploadButtonProps = {
  id: string,
  disabled?:boolean,
  onChange: (files: Blob[] | null, e: ChangeEvent<HTMLInputElement>) => void,
  onError?: (e: Error) => void,
  children?: FileUploadButtonProps['children']
}

const GalleryIcon =
    <Icon
        color={'typography.tertiary'}
        width={'24px'}
    >
      image_attachment
    </Icon>

export default function ImageUploadButton(props: ImageUploadButtonProps) {
  const {id, disabled, onChange, onError, children = GalleryIcon} = props;
  const [converting, setConverting] = useState<boolean>(false);
  const handleChange = (files: Blob[] | null, e: ChangeEvent<HTMLInputElement>) => {
    if (!files) {
      onChange(files, e);
      return;
    }
    setConverting(true);
    Promise.all(files.map(async (file) => {
      if (file.type === 'image/heic' || file.type === 'image/heif') {
        const uploadable = await heic2any({
          blob: file,
          toType: 'image/jpeg',
          quality: 1,
        });
        return Array.isArray(uploadable) ? uploadable[0] : uploadable
      }
      return file;
    }))
        .then((files) => onChange(files, e))
        .catch((e) => {
          onError?.(e)
        })
        .finally(() => {
          setConverting(false)
        })
  }

  return (
      <FileUploadButton
          id={id}
          onChange={handleChange}
          disabled={converting || disabled}
          accept={"image/*"}
          sx={{
            py:'12px',
            px:'12px'
          }}
      >
        {
          converting
              ? <Progress type={'circular'} size={'24px'}/>
              : children
        }
      </FileUploadButton>
  )
}