import {default as MuiButtonBase, ButtonBaseProps as MuiButtonBaseProps} from '@mui/material/ButtonBase';
import {default as MuiBox} from '@mui/material/Box';
import Icon from "~/components/Icon";
import Typography from "~/components/Typography.tsx";
import {useNavigate} from "react-router-dom";

export type IconMenuProps = MuiButtonBaseProps&{
  icon:string,
  label:string,
  to:string,
}

export default function IconMenu(props:IconMenuProps){
  const {icon, label, to, ...restProps} = props;
  const navigate = useNavigate();
  return(
      <MuiButtonBase
          sx={{
            display:'inline-flex',
            flexDirection:'column'
          }}
          onClick={()=>navigate(to)}
          {...restProps}
      >
        <MuiBox
            sx={(theme)=>({
              p:'8px',
              mb:'4px',
              height:'48px',
              backgroundColor:theme.palette.fill.primary,
              borderRadius:'12px'
            })}
        >
          <Icon
              width={'32px'}
          >
            {icon}
          </Icon>
        </MuiBox>
        <Typography variant={'FootNote_B'} color={'typography.secondary'}>
          {label}
        </Typography>
      </MuiButtonBase>
  )
}