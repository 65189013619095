import Stack from "~/components/Stack.tsx";
import Typography from "~/components/Typography.tsx";
import StockBadge from "~/pages/shop/components/StockBadge.tsx";
import Select from "~/components/Select.tsx";

import {ProductOption, ProductOptionItem, ProductStock} from "~kup/models/Product.ts";
import {ProductOptionItemWithStockType} from "~/pages/shop/types";

export type ProductOptionSelectsProps = {
  productOptions: ProductOption[],
  productStock: ProductStock,
  optionSelection: ProductOptionItem[],
  onOptionSelection: (productOptionItems: ProductOptionItem[]) => void
}
export default function ProductOptionSelects(props: ProductOptionSelectsProps) {
  const {productOptions, productStock, optionSelection, onOptionSelection} = props;

  return (

      <Stack
          column
          gap={'8px'}
      >
        {
          productOptions.map((option, optionIndex) => {
            const productOptionItemsWithStock = option.optionItems.map<ProductOptionItemWithStockType>((optionItem) => {
              const selectedSlug = optionSelection.map(s => s.slug).splice(0, optionIndex);
              if (!productStock.optionStock) {
                return {...optionItem, stock: 0, optionStock: null, optionStocks: []};
              } else {
                const searchSlug = [...selectedSlug, optionItem.slug];
                const optionStocks = productStock.getMatchingOptionStocks(searchSlug);
                const optionStock = productStock.getExactOptionStock(searchSlug);
                const optionStockQuantity = productStock.getMatchingOptionStockQuantity(searchSlug);
                return {...optionItem, stock: optionStockQuantity, optionStock, optionStocks};
              }
            });

            const menus = productOptionItemsWithStock
                .filter((pis) => pis.stock > 0)
                .map((item) => ({
                  label: (
                      <Stack key={item.name + item.stock} row width={'100%'} justifyContent={'stretch'} alignItems={'center'}>
                        <Typography flexGrow={1}
                                    whiteSpace={'pre-wrap'}>{item.name}</Typography>
                        <StockBadge stock={item.stock} size={'small'}/>
                      </Stack>),
                  value: item.slug
                }))

            return (
                <Select
                    fullWidth
                    key={option.name}
                    sx={(theme) => ({
                      color: theme.palette.typography.secondary,
                      display: optionIndex > optionSelection.length ? 'none' : 'flex',
                    })}
                    placeholder={`Select ${option.name} to add another item`}
                    menus={menus}
                    value={optionSelection.at(optionIndex)?.slug ?? ''}
                    onChange={(e) => {
                      const selectedSlug = e.target.value;
                      const selectedItem = productOptionItemsWithStock.find((item) => item.slug === selectedSlug);
                      if (!selectedItem) return;
                      const beforeOptionIndex: ProductOptionItem[] = optionSelection.slice(0, optionIndex);
                      onOptionSelection([...beforeOptionIndex, selectedItem]);

                    }}
                />
            )
          })
        }
      </Stack>
  )
}