export type IconShopProps = {
  width?: string,
  height?: string,
}
export default function IconShop(props: IconShopProps) {
  const {width = '32px', height = '32px'} = props;
  return (
      <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.65825 21C4.20825 21 3.818 20.8348 3.4875 20.5043C3.157 20.1736 2.99175 19.7833 2.99175 19.3333V11.0333C2.55275 10.6499 2.24858 10.1722 2.07925 9.6C1.90975 9.02783 1.91942 8.43892 2.10825 7.83325L3.175 4.45C3.30833 4.0055 3.54308 3.65275 3.87925 3.39175C4.21525 3.13058 4.60275 3 5.04175 3H18.8917C19.3527 3 19.7555 3.13192 20.1 3.39575C20.4445 3.65975 20.6862 4.01117 20.825 4.45L21.9083 7.83325C22.0972 8.43892 22.1042 9.02508 21.9292 9.59175C21.7542 10.1584 21.4473 10.6389 21.0082 11.0333V19.3333C21.0082 19.7833 20.843 20.1736 20.5125 20.5043C20.182 20.8348 19.7917 21 19.3417 21H4.65825ZM14.2417 10.1667C14.7139 10.1667 15.1 10.0098 15.4 9.69575C15.7 9.38192 15.8194 9.01108 15.7583 8.58325L15.1582 4.66675H12.8418V8.65C12.8418 9.0555 12.9751 9.40967 13.2418 9.7125C13.5084 10.0153 13.8418 10.1667 14.2417 10.1667ZM9.625 10.1667C10.0638 10.1667 10.4319 10.0181 10.7293 9.72075C11.0264 9.42358 11.175 9.06667 11.175 8.65V4.66675H8.85825L8.25825 8.58325C8.19158 9.00558 8.29858 9.37508 8.57925 9.69175C8.85975 10.0084 9.20833 10.1667 9.625 10.1667ZM5.10825 10.1667C5.47492 10.1667 5.793 10.0389 6.0625 9.78325C6.332 9.52775 6.49175 9.21108 6.54175 8.83325L7.15825 4.66675H4.84175L3.74175 8.21675C3.59725 8.67225 3.65975 9.11108 3.92925 9.53325C4.19858 9.95558 4.59158 10.1667 5.10825 10.1667ZM18.8917 10.1667C19.4084 10.1667 19.8056 9.95842 20.0833 9.54175C20.3611 9.12508 20.425 8.68342 20.275 8.21675L19.1582 4.66675H16.8583L17.475 8.83325C17.525 9.21108 17.6847 9.52775 17.9542 9.78325C18.2236 10.0389 18.5361 10.1667 18.8917 10.1667Z"
              fill="currentColor"/>
      </svg>

  )
}