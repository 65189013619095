import ModalPopup from "~/components/ModalPopup.tsx";
import Typography from "~/components/Typography.tsx";
import useKintStore from "~kint/store";
import Container from "~/components/Container.tsx";
import ValueList from "~/components/ValueList.tsx";
import Button from "~/components/Button.tsx";

export type ReservationPopupProps = {
  onClose: () => void
  open: boolean
}

export default function ReservationPopup(props: ReservationPopupProps) {
  const {open, onClose} = props;
  const {reservation} = useKintStore((state) => ({
    reservation: state.reservation
  }))

  const handleCheckInOther = ()=>{
    localStorage.clear();
    window.location.reload();
  }

  return (
    <ModalPopup
      open={open}
      onClose={onClose}
      bottomPosition={'sticky'}
      fixedBottom={
        <Container
          bottom
          background={'paper'}
          py={'12px'}
          px={'20px'}
          stackProps={{
            gap: '8px'
          }}
        >
          <Button fullWidth size={'small'} color={'fill'} onClick={onClose}> Confirm </Button>
          <Button variant={'text'} size={'small'}  onClick={handleCheckInOther} color={'secondary'}>Check in a different reservation</Button>
        </Container>
      }
    >
      <Container
        py={'16px'}
        px={'20px'}
      >
        <Typography variant={'TitleM_B'}>
          My Booking
        </Typography>
        <Typography variant={'BaseS'} color={'typography.secondary'} component={'p'}>
          A Reservation made by {reservation?.clientName}
        </Typography>
      </Container>
      <Container
        py={'16px'}
        px={'20px'}
      >
        <ValueList pairs={
          [
            {key: 'date', pair: ['Date', reservation?.date]},
            {key: 'tour', pair: ['Tour', reservation?.product]},
            {key: 'name of lead', pair: ['Name', reservation?.clientName]},
            {
              key: 'travelers',
              pair: ['Travelers', [['Adult', reservation?.adult], ['Kid', reservation?.kid], ['Infant', reservation?.infant]]
                .filter(([, count]) => !!count)
                .map(([label, count]) => `${label} ${count}`)
                .join(' / ')]
            },
            {
              key: 'Pickup',
              pair: ['Pickup', reservation?.pickupInfo?.en?.toUpperCase() ?? reservation?.pickupPlace?.toUpperCase() ?? '']
            },
            {key: 'email', pair: ['Email', reservation?.email?.toUpperCase()]},
            {key: 'Platform', pair: ['Platform', reservation?.agency?.toUpperCase()]},
            {key: 'ID', pair: ['ID', reservation?.agencyCode?.toUpperCase()]},
          ]
        }/>

      </Container>
    </ModalPopup>
  )
}