import api from "~kup/api";
import Auth from '~kup/models/Auth';
import {setCookie} from "~common/api/cookies.ts";

/**
 * todo 같은 예약 서로 다른 고객 대응하는 것 추가해야함
 * @param reservationId
 * @param reservationDate
 * @param name
 * @param locale
 */
export async function signIn(reservationId: string, reservationDate: string, name: string, locale: 'en-US' | 'zh-TW' = 'en-US',): Promise<Auth> {
  const authRes = await api.post(
    "/auth/signIn/by-reservation",
    {reservationId, reservationDate, name, locale}
  );

  const data = authRes.data.data;
  setCookie('Authorization', data.accessToken);
  setCookie('Kup-Locale', locale);

  return ({accessToken: data.accessToken, role: data.role, name: data.name, locale});
}

export async function getNames(reservationId: string): Promise<string[]> {
  const nameRes = await api.get('/customers/name/by-reservation', {params: {reservationId}})
  return nameRes.data;
}