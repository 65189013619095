import useKintStore from "~kint/store";
import useKupStore from "~kup/store";
import {signIn} from "~kup/controllers/auth.ts";

export default function useKupAuthWithKint() {
  const {name, reservation} = useKintStore((state) => ({
    name: state.name,
    reservation: state.reservation
  }));
  const {setAuth, setCustomerName, setCustomerEmail} = useKupStore((state) => ({
    setAuth: state.setAuth,
    setCustomerName: state.setCustomerName,
    setCustomerEmail: state.setCustomerEmail
  }));

  return async (_name?: string): Promise<void> => {
    const authName = _name ?? name;
    if (reservation?.id && authName) {
      const locale = reservation.language.toLowerCase().includes('cn') ? 'zh-TW' : 'en-US';
      const auth = await signIn(reservation.id, reservation.date, authName, locale)
      setAuth(auth);
      setCustomerName(authName ?? '');
      setCustomerEmail(reservation.email ?? '');
    }
  }
}