import {BasketProductItem} from "~kup/models/Basket.ts";
import Container from "~/components/Container.tsx";
import Stack from '~/components/Stack';
import ImageView from "~/components/ImageView.tsx";
import Headline from "~/components/Headline.tsx";
import FillBox from "~/components/FillBox.tsx";
import Typography from "~/components/Typography.tsx";
import useKupStore from "~kup/store";


export type OrderItemProps = {
  item: BasketProductItem;
}

export default function OrderItem(props: OrderItemProps) {
  const {currency} = useKupStore();
  const {
    item,
  } = props;

  const isOutOfStock = item.stock.quantity < item.quantity;

  return (
      <Container
          pb={'16px'}
          px={'20px'}
      >
        <Container
          py={'12px'}
        >
          <Stack
              row
              gap={'14px'}
              alignItems={'center'}
          >
            <ImageView borderRadius={'6px'} width={'40px'} height={'40px'}
                       src={item.thumbnail}/>
            <Headline
                sx={{
                  flex: 1,
                }}
                headline={item.productName}
                headlineProps={{sx: {maxLines: 2, textOverflow: 'ellipsis'}}}
                subHeadline={item.productBrand}
                description={`${item.stock.quantity} left`}
                descriptionProps={{color: item.stock.quantity < 3 || isOutOfStock ? 'error' : 'typography.secondary'}}
            />
          </Stack>
        </Container>
        <Container
        >
          <FillBox
              type={'border'}
              borderRadius={'6px'}
              trail={<Typography variant={'BaseS_B'} strike={item.availableAmount === 0}
                                 whiteSpace={'nowrap'}>{item.availableAmount.toLocaleString()} {currency}</Typography>}
          >
            <Typography
                variant={'FootNote_B'}
                strike={isOutOfStock}
                color={isOutOfStock ? 'error' : 'typography.secondary'}
                whiteSpace={'pre-wrap'}>
              {`${item.optionSelectionName} X ${item.availableQuantity}`}
            </Typography>
          </FillBox>
        </Container>
      </Container>
  )
}