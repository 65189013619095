import {ElementType, ReactNode} from "react";
import {SystemStyleObject} from "@mui/system";
import {Theme, TypeBackground} from "@mui/material/styles";
import {
  default as MuiContainer,
  ContainerProps as MuiContainerProps
} from "@mui/material/Container";
import Stack, {StackProps} from "~/components/Stack.tsx";
import useSafeArea from "~/hooks/useSafeArea.ts";

type Padding = string | number;
type Background = keyof TypeBackground | 'white' | 'transparent';

export type ContainerProps<E extends ElementType> = MuiContainerProps<E> & {
  p?: Padding,
  px?: Padding,
  py?: Padding,
  pt?: Padding,
  pb?: Padding,
  pl?: Padding,
  pr?: Padding,
  bottom?: boolean,
  border?: string,
  borderTop?: string,
  borderBottom?: string,
  borderLeft?: string,
  borderRight?: string
  background?: Background
  children: ReactNode,
  stackProps?: StackProps,
  component?: E,
};
export default function Container<E extends ElementType>(props: ContainerProps<E>) {
  const {bottomSafeArea} = useSafeArea();
  const {
    sx = {},
    p,
    px = p,
    py = p,
    pt = py,
    pb = py,
    pl = px,
    pr = px,
    bottom,
    background = 'transparent',
    children,
    stackProps,
    ...restProps
  } = props;

  return (
      <MuiContainer
          sx={(theme) => {
            const predefinedStyles = {
              px: px ? (px + ' !important') : '0px !important',
              py: py ? (py + ' !important') : '0px !important',
              pt,
              pb: pb ?? (bottom ? `${Math.max(bottomSafeArea, 8)}px` : undefined),
              pl: pl ? (pl + ' !important') : '0px !important',
              pr: pr ? (pr + ' !important') : '0px !important',
              background: background in theme.palette.background ? theme.palette.background[background as keyof TypeBackground] : background,
            }
            const style = (
                typeof sx === 'function'
                    ? ({
                      ...predefinedStyles,
                      ...sx(theme),
                    })
                    : ({
                      ...predefinedStyles,
                      ...(sx ?? {}),
                    } as SystemStyleObject<Theme>)
            )
            return style;
          }}
          {...restProps}
      >
        {
          stackProps
              ? <Stack {...stackProps} >{children}</Stack>
              : children
        }
      </MuiContainer>
  )
}