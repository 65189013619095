import {Slice} from "~common/store";
import Reservation from "~kint/models/Reservation.ts";
import callReservation from "~kint/controllers/callReservation.ts";


export interface ReservationSlice {
  reservationKey: string | null,
  reservation: Reservation | null,
  setReservation: (reservation: Reservation | null) => void,
  setReservationKey: (reservationKey: string) => void,
  name: string | null,
  setName: (name: string | null) => void,
  syncReservation: () => Promise<void>,
}

export interface PersistedReservationSlice {
  reservationKey: string | null
  reservation: Reservation | null
  name: string | null,
}

export const persistReservationSlice: (state: ReservationSlice) => PersistedReservationSlice = (state) => ({
  reservationKey: state.reservationKey,
  reservation: state.reservation,
  name: state.name
})

export const createReservationSlice: Slice<ReservationSlice> = (set, get) => ({
  reservationKey: null,
  reservation: null,
  setReservation: (reservation: Reservation | null) => set(() => ({reservation})),
  setReservationKey: (reservationKey: string) => set(() => ({reservationKey})),
  name: null,
  setName: (name: string | null) => set(() => ({name})),
  syncReservation: async () => {
    const existReservation = get().reservation;
    const existKey = get().reservationKey;
    const mismatched = !existReservation || (existReservation.id !== existKey && existReservation.agencyCode !== existKey);
    if (mismatched) {
      return;
    }
    try{
      const syncedReservation = await callReservation(existKey);
      if (syncedReservation)
        set(() => ({reservation: syncedReservation}))
    }catch(e){
      console.error(e);
    }
  },
})

export const mergeReservationPersistedSlice = (persistedState: unknown, currentState: ReservationSlice): ReservationSlice => {
  const syncState = {...currentState, ...persistedState ?? {}};
  return ({
    ...syncState,
    reservation: syncState.reservation ? new Reservation(syncState.reservation) : null
  })
}


