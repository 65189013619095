import api from "~kup/api";
import {
  Product,
  ProductCategory,
  ProductCategoryResponse,
  ProductResponse,
  ProductTag,
  ProductTagResponse
} from "~kup/models/Product.ts";

const PRODUCT_PATH = {
  PRODUCTS: (pathParams: string[] = []) => ['', 'products', ...pathParams].join('/'),
  PRODUCT: (productId: Product['id']) => ['', 'products', productId].join('/'),
  CATEGORIES: () => '/product-categories',
  TAGS: () => '/products/tags'
}

export async function getProductCategories(): Promise<ProductCategory[]> {
  const resp = await api.get(PRODUCT_PATH.CATEGORIES());
  const productCategoryResponses: ProductCategoryResponse[] = resp.data.data;
  return productCategoryResponses.map((r) => new ProductCategory(r))
}

type GetProductsParams = {
  pageSize: number,
  page: number,
  category?: string,
  excludeSoldOut?:boolean,
  tags?: string[],
  promotionId?: string,
}

type GetProductResponseMeta = {
  count: number
  hasNext: boolean
  hasPrev: boolean
  page: number
  totalCount: number
  totalPage: number
}

type GetProductsResponse = {
  products: Product[],
  hasNextPage: boolean,
  meta: GetProductResponseMeta
};

export async function getProducts(params: GetProductsParams): Promise<GetProductsResponse> {
  const config = {params};
  const resp = await api.get(PRODUCT_PATH.PRODUCTS(), config);
  const productResponses: ProductResponse[] = resp.data.data;
  const hasNextPage: boolean = resp.data.meta.hasNext ?? false;
  return {
    products: productResponses.map((r) => new Product(r)),
    hasNextPage,
    meta: resp.data.meta
  }
}

export async function getProduct(productId: Product['id']): Promise<Product> {
  const resp = await api.get(PRODUCT_PATH.PRODUCT(productId));
  return new Product(resp.data.data);
}

export async function getProductTags(keyword?: string, isHighlight: boolean = false): Promise<ProductTag[]> {
  const resp = await api.get(PRODUCT_PATH.TAGS(), {params: {keyword, isHighlight}});
  return (Array.isArray(resp.data.data) ? resp.data.data : resp.data ?? []).map((r: ProductTagResponse) => new ProductTag(r));
}

export async function getProductTag(keyword?: string, isHighlight: boolean = false): Promise<ProductTag | null> {
  const resp = await api.get(PRODUCT_PATH.TAGS(), {params: {keyword, isHighlight}});
  const tag = (Array.isArray(resp.data.data) ? resp.data.data : (resp.data ?? [])).at(0);
  return tag ? new ProductTag(tag) : null;
}