export type LogoRibbonProps = {
  width?:string,
  height?:string,
}
export default function IconRibbon(props:LogoRibbonProps) {
  const {width ='32px', height='32px'} = props;
  return (
      <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M27.0591 32.3156C21.6555 27.3229 20.1876 20.1289 20.0999 16.3335C20.0882 15.8277 20.4849 15.4195 20.9892 15.3789L24.799 15.0721C25.3077 15.0311 25.7637 15.3826 25.8787 15.8798C27.1267 21.2731 29.9056 24.3026 31.8522 25.6192C32.3996 25.9894 32.6035 26.769 32.2023 27.2941L28.473 32.1745C28.1286 32.6253 27.4758 32.7005 27.0591 32.3156Z"
            fill="#EF4444"/>
        <path
            d="M12.9409 32.3156C18.3445 27.3229 19.8125 20.1289 19.9002 16.3335C19.9119 15.8277 19.5151 15.4195 19.0108 15.3789L15.2011 15.0721C14.6923 15.0311 14.2364 15.3826 14.1213 15.8798C12.8733 21.2731 10.0945 24.3026 8.14779 25.6192C7.60045 25.9894 7.39657 26.769 7.79777 27.2941L11.527 32.1745C11.8714 32.6253 12.5242 32.7005 12.9409 32.3156Z"
            fill="#EF4444"/>
        <path
            d="M33.4478 23.858C28.5402 24.6615 23.3945 22.9666 23 21L24.8958 19.1079C25.0658 18.9383 25.2962 18.843 25.5363 18.843H33.3672C33.6072 18.843 33.8374 18.9382 34.0073 19.1076L35.2819 20.3783C35.4525 20.5484 35.5484 20.7794 35.5484 21.0204V21.1811C35.5484 22.4734 34.7231 23.6492 33.4478 23.858Z"
            fill="#CE2424"/>
        <path
            d="M32.8062 7.9771C27.4625 7.62787 24.1422 10.1021 23 12V21C31.5806 18.2985 35.5484 20.1939 35.5484 21.5446L35.8881 11.724C35.9533 9.83688 34.6904 8.10024 32.8062 7.9771Z"
            fill="#EF4444"/>
        <path
            d="M6.55224 23.858C11.4598 24.6615 16.6055 22.9666 17 21L15.1042 19.1079C14.9342 18.9383 14.7038 18.843 14.4637 18.843H6.63281C6.39283 18.843 6.16263 18.9382 5.99267 19.1076L4.71814 20.3783C4.54751 20.5484 4.45161 20.7794 4.45161 21.0204V21.1811C4.45161 22.4734 5.27692 23.6492 6.55224 23.858Z"
            fill="#CE2424"/>
        <path
            d="M7.19378 7.9771C12.5375 7.62787 15.8578 10.1021 17 12V21C8.41933 18.2985 4.45159 20.1939 4.45159 21.5446L4.11191 11.724C4.04664 9.83688 5.30954 8.10024 7.19378 7.9771Z"
            fill="#EF4444"/>
        <path
            d="M17 11.9999C18.9722 11.5287 21.0278 11.5287 23 11.9999V20.9999C21.084 21.8195 18.916 21.8195 17 20.9999V11.9999Z"
            fill="#CE2424"/>
      </svg>

  )
}