import {ElementType, ReactNode} from "react";
import {default as MuiModal, ModalProps as MuiModalProps} from '@mui/material/Modal';
import {default as MuiFade} from '@mui/material/Fade';
import {default as MuiPaper, PaperProps as MuiPaperProps} from '@mui/material/Paper';
import {BaseArea, BaseAreaProps} from './Area';
import Typography from './Typography';
import {BoxProps as MuiBoxProps, default as MuiBox} from "@mui/material/Box/Box";

export type ModalPopoverProps<E extends ElementType> =
    {
      title?: ReactNode,
      description?: ReactNode,
      fixedBottom?: ReactNode,
      fixedBottomPosition?: 'absolute' | 'sticky' | 'relative',
      bottomProps?: MuiBoxProps,
      modalProps?: Omit<MuiModalProps, 'children'>,
      popoverProps?: Omit<MuiPaperProps, 'children'>,
      onClose: () => void,

    }
    & Omit<MuiModalProps<E> & BaseAreaProps<E>, 'onClose'>
    & Pick<MuiBoxProps, 'width' | 'maxWidth' | 'minWidth' | 'height' | 'maxHeight' | 'minHeight'>

function isStringTitle(title: ReactNode): title is string {
  return typeof title === 'string';
}

export default function ModalPopover<E extends ElementType>(props: ModalPopoverProps<E>) {
  const {
    open,
    onClose,
    title,
    description,
    fixedBottom,
    fixedBottomPosition = 'relative',
    bottomProps = {},
    children,
    modalProps = {},
    popoverProps = {},
  } = props;

  const dimensions = {
    width: props.width,
    maxWidth: props.maxWidth ?? '80vw',
    minWidth: props.minWidth ?? '300px',
    height: props.height,
    minHeight: props.minHeight,
    maxHeight: props.maxHeight ?? '70vh'
  };

  const titleNode = isStringTitle(title) ?
      <Typography variant={'TitleM_B'} sx={{display: 'inline-flex', alignItems: 'center'}}>{title}</Typography> : title;
  const descriptionNode = isStringTitle(title) ?
      <Typography variant={'FootNote'} color={'typography.secondary'}>{description}</Typography> : description;

  const handleClose = () => {
    onClose();
  }
  return open ? (
      <MuiModal
          disableAutoFocus
          closeAfterTransition
          open={open}
          onClose={handleClose}
          {...modalProps}
      >
        <MuiFade
            in={open}
            timeout={500}
        >
          <MuiPaper
              onClick={(e) => e.stopPropagation()}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                overflow: 'scroll',
                ...dimensions
              }}
              {...popoverProps}
          >
            <BaseArea title={titleNode} description={descriptionNode}>
              {children}
            </BaseArea>
            {
              fixedBottom
                  ? (
                      <MuiBox
                          style={{
                            bottom: 0,
                            left: 0,
                            right: 0,
                            position: fixedBottomPosition,
                          }}
                          {...bottomProps}
                      >
                        {
                          fixedBottom
                        }
                      </MuiBox>
                  )
                  : null
            }
          </MuiPaper>
        </MuiFade>
      </MuiModal>
  ) : null
}