import Page from '~/components/Page';
import Container from '~/components/Container';
import Typography from '~/components/Typography';


export default function TermsPage() {
  return (
      <Page
          type={'modal'}
          name={'Terms & Condition'}
      >
        <Container
            pb={'20px'}
            px={'20px'}
        >
          <Container py={'8px'}>
            <Typography variant={'TitleS_B'} component={'p'}>
              1. Introduction
            </Typography>

            Welcome to Ktourstory Ltd. By using our one-day tour services, commerce services, and accessing related travel information, you agree to these Terms & Conditions. Please read them carefully.
          </Container>

          <Container py={'8px'}>
            <Typography variant={'TitleS_B'} component={'p'}>
              2. Services Provided
            </Typography>
            Ktourstory Ltd. offers the following services:
            <ul>
              <li>
                <b>Tour Services:</b> <br/>We provide necessary services to facilitate your tour, including chat
                services, notifications, and essential tour and travel information. These services are designed to
                ensure a smooth and informed experience during your tour. To provide these services, we collect and use
                booking information that is submitted through travel agencies or other platforms. This information is
                processed and stored by Ktourstory Ltd. for up to one year solely for the purpose of providing our
                services. Please refer to the information provided by your booking platform for specific details
                regarding your Tour.
              </li>
              <li>
                <b>Commerce Services:</b> <br/>Our online commerce service is available to foreigners during their stay
                in Korea. Customers can order and pay for various products online, with AI-translated information
                provided in multiple languages. Orders placed before a certain time may be delivered the same day.
                Customers must provide the necessary information for this service, which will be stored for up to one
                year or as required by law.
              </li>
              <li>
                <b>Travel Information Service:</b> <br/>We provide various travel-related information and guides, which
                may be personalized based on your tour usage and service interaction.
              </li>
            </ul>
          </Container>

          <Container py={'8px'}>
            <Typography variant={'TitleS_B'} component={'p'}>
              3. User Responsibilities
            </Typography>
            By using our services, you agree to:
            <ul>
              <li>Provide accurate and up-to-date personal information.</li>
              <li>Use the services for lawful purposes only.</li>
              <li>Follow the instructions provided by tour guides and service providers.</li>
              <li>Review the booking information provided by the platform where the tour was reserved.</li>
            </ul>
          </Container>

          <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
              4. Commerce Order and Payment
            </Typography>
            All orders made through our commerce service are subject to acceptance and availability. Payment must be made at the time of purchase through the methods provided on our platform.
          </Container>

          <Container py={'8px'}>
            <Typography variant={'TitleS_B'} component={'p'}>
              5. Commerce Order Cancellation and Refunds
            </Typography>
            <ul>
              <li>
                <b>Tour Cancellation:</b> <br/>Tour cancellations must be processed through the platform or agency where the tour was originally booked. Cancellations will be subject to the terms and conditions provided by that booking platform or agency.
              </li>
              <li>
                <b>Commerce Order Cancellation:</b> <br/>Customers can cancel their orders through the service until the product is in the process of being prepared for delivery. After this point, a refund procedure is necessary.
              </li>
              <li>
                <b>Commerce Refund Policy:</b> <br/>Refunds are available within 14 days of purchase. Refund requests must be submitted via the email provided by Ktourstory Ltd. The product must be unused and in a condition that allows for resale, retaining its commercial value. Refunds due to product defects or other issues are also possible. However, refunds due to a simple change of mind may be restricted.
              </li>
              <li>
                <b>Return Shipping Costs:</b> <br/>The customer is responsible for return shipping costs, which will be calculated based on the required amount. If Ktourstory Ltd. arranges the return pickup, a shipping fee of 5,000 KRW will be charged.
              </li>
            </ul>
          </Container>

          <Container py={'8px'}>
            <Typography variant={'TitleS_B'} component={'p'}>
              6. Data Collection and Privacy
            </Typography>
            Your use of our services involves the collection of personal information as outlined in our Privacy Policy. By agreeing to these Terms & Conditions, you also agree to the terms set forth in our Privacy Policy.
            <br/>
            <b>Data Storage:</b> <br/>Data is stored using Google Cloud services and may be stored in servers located in Korea and Singapore to facilitate service provision.
            <br/>
            <b>Order and Payment Information:</b> <br/>Your order and payment information will be stored for one year or for the duration required by law, whichever is longer. After this period, the information will be promptly deleted unless further retention is required by legal obligations.
          </Container>

          <Container py={'8px'}>
            <Typography variant={'TitleS_B'} component={'p'}>
              7. Service Notifications and Marketing Content
            </Typography>
            <ul>
              <li>
                <b>Service Notifications:</b> <br/>You will receive push notifications related to your use of the chat service and commerce features. These notifications are strictly related to service operations and do not include marketing pushes.
              </li>
              <li>
                <b>Informational Content:</b> <br/>You may receive travel-related content, such as store introductions and destination guides, which are provided in a magazine-like format for informational purposes only.
              </li>
            </ul>
          </Container>

          <Container py={'8px'}>
            <Typography variant={'TitleS_B'} component={'p'}>
              8. Limitation of Liability
            </Typography>
            Ktourstory Ltd. is not liable for any indirect, incidental, or consequential damages arising from the use of our services. Our liability is limited to the amount paid for the services. We strongly recommend that customers purchase travel insurance to cover any potential risks during the tour. Tour participants must follow the guidance provided by their tour guides and review the information provided by the platform where the tour was booked.
          </Container>

          <Container py={'8px'}>
            <Typography variant={'TitleS_B'} component={'p'}>
              9. Intellectual Property
            </Typography>
            All content provided by Ktourstory Ltd., including travel guides and informational material, is owned by Ktourstory Ltd. Some products offered through the commerce service may be owned by the manufacturer. User-generated content, such as reviews or photos, may be used by Ktourstory Ltd. for promotional purposes and in service-related materials.
          </Container>

          <Container py={'8px'}>
            <Typography variant={'TitleS_B'} component={'p'}>
              10. Changes to Terms
            </Typography>
            We reserve the right to modify or discontinue these Terms & Conditions at any time. Any significant changes will be communicated through a notice on our website. Continued use of our services following such changes will be deemed acceptance of the updated Terms & Conditions.
          </Container>

          <Container py={'8px'}>
            <Typography variant={'TitleS_B'} component={'p'}>
              11. Governing Law and Jurisdiction
            </Typography>
            These Terms & Conditions are governed by and construed in accordance with the laws of the Republic of Korea. Any disputes arising from these terms shall be resolved in the jurisdiction of the Seoul Central District Court.
          </Container>

          <Container py={'8px'}>
            <Typography variant={'TitleS_B'} component={'p'}>
              12. Use by Minors
            </Typography>
            Minors may use our services with parental consent and under parental supervision. However, there may be restrictions on purchases made by minors.
          </Container>

          <Container py={'8px'}>
            <Typography variant={'TitleS_B'} component={'p'}>
              13. Contact Us
            </Typography>
            If you have any questions regarding these Terms & Conditions, please contact us at info@ktourstory.com or visit our office at 12, Supyo-ro, Seoul, South Korea.
          </Container>
        </Container>
      </Page>
  )
}