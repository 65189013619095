import kintFirestore from "~kint/firebase/firestore.ts";
import {IChatting} from "~kint/models/Chatting.ts";

export default function listenChattings(
    participantId: string,
    callback: (list: IChatting[], map: { [id: string]: IChatting }) => void
): () => void {

  // listenCollection 메서드를 사용해 실시간 리스너를 설정합니다.
  return kintFirestore.listenCollection<IChatting>(
      callback,
      ['chats'],
      {
        where: ['participantIds', 'array-contains', participantId]
      }
  );
}
