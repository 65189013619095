import {useState} from "react";
import Container from "~/components/Container.tsx";
import Stack from "~/components/Stack.tsx";
import Button from "~/components/Button.tsx";
import Popover from "~/components/ModalPopover.tsx";
import {BasketProductItem} from "~kup/models/Basket.ts";
import QuantityController from "~/pages/shop/components/QuantityController.tsx";
import ProductOptionSelects from "~/pages/shop/components/ProductOptionSelects.tsx";
import {OptionStock, ProductOptionItem, ProductOptionSelection} from "~kup/models/Product.ts";
import isEquivalentSetArray from "~/utils/isEquivalentSetArray.ts";
import useKupStore from "~kup/store";
import useProduct from "~/pages/shop/hooks/useProduct.ts";
import {useSnackBar} from "~/contexts/SnackBarContext.tsx";
import Typography from "~/components/Typography.tsx";
import useLoading from "~/hooks/useLoading.tsx";


export type ModifyItemPopoverProps = {
  open?: boolean,
  onClose: () => void,
  item: BasketProductItem
}

export default function ModifyItemPopover(props: ModifyItemPopoverProps) {
  const {basket, setBasket} = useKupStore();
  const {showSnackBar} = useSnackBar();
  const {open, onClose, item} = props;
  const {product} = useProduct(item.productId);
  const productOptions = product?.productOptions ?? [];
  const productStock = product?.stocks ?? null;
  const [optionItemSelections, setOptionItemSelections] = useState<ProductOptionItem[]>(item.optionItemSelections);
  const [quantity, setQuantity] = useState(item.quantity);
  const {startLoading, stopLoading, loading} = useLoading();

  const isOptionsModified = optionItemSelections.length === item.optionItemSelections.length && !isEquivalentSetArray(optionItemSelections.map(o => o.slug), item.slugs);
  const isQuantityChanged = quantity !== item.quantity;


  const handleCancel = () => {
    onClose();
  }

  const handleSelect = (optionItemSelections: ProductOptionItem[]) => {
    setOptionItemSelections(optionItemSelections);

    if (optionItemSelections.length === item.optionItemSelections.length) {
      if (!product) return;
      if (!productStock) return;
      const slugList: string[] = optionItemSelections.map((item) => item.slug)
      const stock: OptionStock | null = productStock.getExactOptionStock(slugList);

      if (!stock || stock.quantity === 0) {
        showSnackBar({message: 'The option is out of stock.', lift: true});
        return;
      }

      if (stock.quantity < quantity) {
        showSnackBar({message: `The maximum order limit is ${stock.quantity}`})
      }

      const properQuantity = Math.min(quantity, stock.quantity);
      if (quantity !== properQuantity) setQuantity(properQuantity);
    }
  }

  const handleModify = () => {
    if (!product) return;
    if (!productStock) return;
    if (!productOptions.length || !productStock.optionStock) return;

    const slugList: string[] = optionItemSelections.map((item) => item.slug)
    const stock: OptionStock | null = productStock.getExactOptionStock(slugList);

    if (!stock || stock.quantity === 0) {
      showSnackBar({message: 'The option is out of stock.', lift: true});
      return;
    }

    if (stock.quantity < quantity) {
      showSnackBar({message: `The maximum order limit is ${stock.quantity}`})
    }

    const properQuantity = Math.min(quantity, stock.quantity);

    if (isOptionsModified) {
      const newProductSelection: ProductOptionSelection = new ProductOptionSelection(stock, optionItemSelections, quantity);
      const addBasket = ({
        productId: product?.id,
        productName: product?.name,
        productBrand: product?.brand,
        productImageUrls: product?.imageUrls,
        stock: newProductSelection.stock,
        quantity: properQuantity,
        optionItemSelections: newProductSelection.optionItemSelections
      });

      if (basket.find(addBasket.optionItemSelections.map((s) => s.slug))) {
        showSnackBar({message: 'The option has already been added, and the selection is combined.'})
      }

      startLoading();
      basket.replaceItem(item, addBasket)
          .then(setBasket)
          .then(onClose)
          .finally(stopLoading);
      return;
    }

    if (isQuantityChanged) {
      startLoading();
      const newItem = item.setQuantity(properQuantity);
      basket.replaceMatchingItem(newItem)
          .then(setBasket)
          .then(onClose)
          .finally(stopLoading);
      return;
    }
  }


  const handleQuantity = (quantity: number) => {
    if (!productStock) return;
    const slugList: string[] = optionItemSelections.map((item) => item.slug)
    const stock: OptionStock | null = productStock.getExactOptionStock(slugList);

    if (!stock || stock.quantity === 0) {
      showSnackBar({message: 'The option is out of stock.'});
      return;
    }

    if (stock.quantity < quantity) {
      showSnackBar({message: `The maximum order limit is ${stock.quantity}`})
    }

    if (quantity < 1) {
      showSnackBar({message: `The minimum order quantity is 1`})
    }

    const properQuantity = Math.min(quantity, stock.quantity);

    setQuantity(properQuantity);
  }


  return <Popover open={open}
                  title={item.productName}
                  description={`${item.optionSelectionName} X ${item.quantity}`}
                  onClose={onClose}
                  fixedBottomPosition={'sticky'}
                  fixedBottom={
                    <Container
                        pb={'28px'}
                        pt={'12px'}
                        px={'20px'}
                        background={'paper'}
                        sx={() => ({ zIndex: 1,})}
                    >
                      <Stack
                          column
                          gap={'8px'}
                      >
                        <Button
                            loading={loading}
                            disabled={!isQuantityChanged && !isOptionsModified}
                            variant={'contained'}
                            color={'primary'}
                            onClick={handleModify}
                            size={'medium'}
                        >
                          Modify Order
                        </Button>
                        <Button
                            disabled={loading}
                            variant={'contained'}
                            color={'fill'}
                            onClick={handleCancel}
                            size={'medium'}
                        >
                          Cancel
                        </Button>
                        {
                            isOptionsModified &&
                            <Typography color={'warning.main'} variant={'caption'} textAlign={'center'}>
                              The option selection has been changed.
                            </Typography>
                        }
                      </Stack>
                    </Container>
                  }
  >
    <Container
        px={'20px'}
        pb={'16px'}
        stackProps={{
          gap: '14px',
        }}
    >
      <Stack
          column
          gap={'8px'}
      >
        {
            productStock &&
            <ProductOptionSelects productOptions={productOptions} productStock={productStock}
                                  optionSelection={optionItemSelections}
                                  onOptionSelection={handleSelect}/>
        }
      </Stack>
      <QuantityController quantity={quantity} onChange={handleQuantity}/>
    </Container>
  </Popover>
}