import {ReactNode, useRef} from "react";
import {
  default as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  TextFieldVariants as MuiTextFieldVariants,
} from '@mui/material/TextField';
import {default as MuiInputAdornment} from '@mui/material/InputAdornment';
import IconButton from '~/components/IconButton';
import Icon from '~/components/Icon';


export type TextFieldProps<Variant extends MuiTextFieldVariants = MuiTextFieldVariants> =
    Omit<MuiTextFieldProps<Variant>, 'variant'> & {
  onClear?: () => void,
  onEnter?: () => void,
  endAdornment?: ReactNode,
  variant?: Variant,
}

export default function TextField<Variant extends MuiTextFieldVariants = MuiTextFieldVariants>(props: TextFieldProps<Variant>) {
  const {onClear, onEnter, endAdornment, placeholder, InputProps, InputLabelProps, multiline, ...restProps} = props
  const inputRef = useRef<HTMLInputElement>(null);
  const handleKeyPress: TextFieldProps['onKeyDown'] = (e) => {
    if (e.key === 'Enter') {
      if (!multiline) {
        e.preventDefault();
      }
      onEnter?.();
      inputRef.current?.blur();
    }
  };
  return (
      <div className={'kup-textfield'} style={{
        paddingTop: props.label ? '20px' : '' //padding for label position
      }}>
        <MuiTextField
            {...restProps}
            multiline={multiline}
            inputRef={inputRef}
            placeholder={placeholder}
            onKeyDown={handleKeyPress}
            InputProps={{
              ...(InputProps && InputProps),
              endAdornment: (onClear || endAdornment)
                  && (
                      <MuiInputAdornment position="end">
                        {
                            onClear && (
                                <IconButton
                                    onClick={onClear}
                                >
                                  <Icon
                                      type={'circle'}
                                      size={'0.875em'}
                                      backgroundColor={'typography.quaternary'}
                                  >
                                    clear
                                  </Icon>
                                </IconButton>
                            )
                        }

                        {
                            endAdornment && endAdornment
                        }
                      </MuiInputAdornment>
                  ),
            }}
            InputLabelProps={{
              ...(InputLabelProps && InputLabelProps),
              shrink: placeholder !== undefined || InputLabelProps?.shrink
            }}
        />
      </div>
  )
}