import Page from '~/components/Page';
import Container from '~/components/Container';
import Typography from '~/components/Typography';

export default function PrivacyPage() {
  return (
      <Page
          type={'modal'}
          name={'Privacy Policy'}
      >
        <Container
            pb={'20px'}
            px={'20px'}
        >
          <Container py={'8px'}>
            <Typography variant={'TitleS_B'} component={'p'}>
              1. Introduction
            </Typography>
            At Ktourstory Ltd., your privacy is our top priority. This Privacy Policy outlines how we collect, use, and protect your personal information when you use our one-day tour services and receive related travel information for Seoul and Korea.
          </Container>

          <Container py={'8px'}>
            <Typography variant={'TitleS_B'} component={'p'}>
              2. Information We Collect
            </Typography>
            To provide you with our services, we collect the following personal information:
            <ul>
              <li><b>Name:</b><br /> To personalize your tour experience and communicate with you effectively.</li>
              <li><b>Contact Information:</b><br /> Including your phone number and email address, to send you updates about your tour, order confirmations, and essential service-related information.</li>
              <li><b>Delivery Address:</b><br /> If you utilize our commerce services for same-day delivery, we require your delivery address.</li>
              <li><b>Booking Information:</b><br /> Collected from travel agencies or other platforms where the tour was reserved, used to process and store your booking details.</li>
            </ul>
          </Container>

          <Container py={'8px'}>
            <Typography variant={'TitleS_B'} component={'p'}>
              3. How We Use Your Information
            </Typography>
            Your personal information is used for the following purposes:
            <ul>
              <li><b>Tour Services:</b><br /> To facilitate and enhance your one-day tour experience.</li>
              <li><b>Travel Information:</b><br /> To provide you with relevant information about Seoul and Korea, including travel tips, destination guides, and other useful content.</li>
              <li><b>Commerce Services:</b><br /> To process orders and arrange same-day deliveries related to your tour experience.</li>
              <li><b>Service Notifications:</b><br /> To send you push notifications related to your use of the chat service and commerce features. Note that we do not send marketing push notifications; all notifications are strictly service-related.</li>
              <li><b>Non-Intrusive Marketing Content:</b><br /> We may offer travel-related content similar to a magazine format, which includes store introductions and other informational content. This content is provided for informational purposes only and does not involve direct marketing pushes.</li>
            </ul>
          </Container>

          <Container py={'8px'}>
            <Typography variant={'TitleS_B'} component={'p'}>
              4. Data Collection and Consent
            </Typography>
            By using our services and voluntarily providing your information, you expressly consent to the collection, use, and processing of your personal information as outlined in this Privacy Policy. This includes information provided directly by you as well as information indirectly provided through third-party booking platforms or agencies.
          </Container>

          <Container py={'8px'}>
            <Typography variant={'TitleS_B'} component={'p'}>
              5. Data Retention
            </Typography>
            We retain your personal information for one year after the completion of your last tour or service with us. After this period, your data will be securely deleted unless required by law to retain it longer.
          </Container>

          <Container py={'8px'}>
            <Typography variant={'TitleS_B'} component={'p'}>
              6. Data Security
            </Typography>
            We use industry-standard security measures to protect your personal information from unauthorized access, disclosure, or misuse.
          </Container>

          <Container py={'8px'}>
            <Typography variant={'TitleS_B'} component={'p'}>
              7. Third-Party Data Sharing
            </Typography>
            We may share your personal information with third parties in the following circumstances:
            <ul>
              <li><b>Payment Processing:</b><br /> To facilitate payment transactions, your payment information may be shared with our payment processing partners.</li>
              <li><b>Shipping and Delivery:</b><br /> If you use our commerce services, your delivery information will be shared with our logistics partners to ensure the proper delivery of your order.</li>
            </ul>
            All third parties with whom we share your information are contractually obligated to protect your data in accordance with applicable privacy laws.
          </Container>

          <Container py={'8px'}>
            <Typography variant={'TitleS_B'} component={'p'}>
              8. Data Processing and Service Analysis
            </Typography>
            We use Google Analytics and Hotjar to analyze service usage and improve your experience. These tools collect data in a manner that does not personally identify you, ensuring that your privacy is maintained. The data is used solely for service enhancement and is protected in accordance with industry standards.
            <br /><br />
            Google Analytics and Hotjar may use cookies to collect this information. If you do not wish to have data collected by Google Analytics, you can use the <a href="https://tools.google.com/dlpage/gaoptout">Google Analytics Opt-out Browser Add-on</a> or block Hotjar data collection through their <a href="https://www.hotjar.com/policies/do-not-track/">Opt-out page</a>.
            <br /><br />
            The data collected is used solely for service enhancement and is protected according to industry standards. We ensure that any third parties processing this data on our behalf comply with relevant privacy laws and protect your data accordingly. Additionally, this data is only retained for as long as necessary for analysis purposes, after which it is securely deleted.
          </Container>

          <Container py={'8px'}>
            <Typography variant={'TitleS_B'} component={'p'}>
              9. Your Rights
            </Typography>
            You have the right to access, correct, or delete your personal information at any time. If you wish to exercise any of these rights, or if you wish to opt-out of receiving any informational content, please contact us at support@kup.travel.
          </Container>

          <Container py={'8px'}>
            <Typography variant={'TitleS_B'} component={'p'}>
              10. Minor’s Privacy
            </Typography>
            We understand the importance of protecting minors' privacy. If a minor (under the age of 14) uses our services, we may collect their personal information only with the explicit consent of their legal guardian. Any information collected without such consent will be promptly deleted upon discovery. Additionally, such information can be deleted upon the explicit request of the minor’s legal guardian. To request deletion, please contact us at support@kup.travel.
          </Container>

          <Container py={'8px'}>
            <Typography variant={'TitleS_B'} component={'p'}>
              11. Changes to This Policy
            </Typography>
            We may update this Privacy Policy from time to time. Any changes will be posted on our website. We encourage you to review this policy periodically to stay informed about how we are protecting your information. Continued use of our services after any changes constitutes your acceptance of the updated Privacy Policy.
          </Container>

          <Container py={'8px'}>
            <Typography variant={'TitleS_B'} component={'p'}>
              12. Contact Us
            </Typography>
            If you have any questions or concerns about this Privacy Policy, please contact us at support@kup.travel or visit our office at 12, Supyo-ro, Seoul, South Korea.
          </Container>
        </Container>
      </Page>
  )
}
