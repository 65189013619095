import {useEffect, useState} from "react";
import {getProductTag} from "~kup/controllers/product.ts";
import {ProductTag} from "~kup/models/Product.ts";

export default function useTag(keyword:string) {
  const [tag, setTag] = useState<ProductTag|null>(null)
  useEffect(() => {
    getProductTag(keyword)
      .then((tag) => {
        return setTag(tag);
      }).catch(console.error);
  }, [keyword]);

  return tag
}