import Page from "~/components/Page.tsx";
import useKintStore from "~kint/store";
import Container from "~/components/Container.tsx";
import Divider from "~/components/Divider.tsx";
import Headline from "~/components/Headline.tsx";
import Area from "~/components/Area.tsx";
import ValueList from "~/components/ValueList.tsx";
import Button from "~/components/Button.tsx";
import {Navigate, useLocation, useNavigate} from "react-router-dom";

export default function ReservationConfirmPage() {
  const {setReservation} = useKintStore((state) => ({
    setReservation:state.setReservation,
  }));
  const location = useLocation();
  const {reservation} = (location.state ?? {});
  const navigate = useNavigate();

  const handleClickYes = ()=>{
    setReservation(reservation);
    navigate('/reservation/name', {replace:true})
  }

  const handleClickNo = ()=>{
    setReservation(null);
    navigate('/reservation', {replace:true})
  }

  if(!reservation) return <Navigate to={'/reservation'} replace/>

  return (
      <Page
          type={'page'}
          fixedBottomPosition={'fixed'}
          fixedBottom={
            <Container
                bottom
                background={'paper'}
                pt={'8px'}
                px={'20px'}
                stackProps={{
                  gap:'10px'
                }}
            >
              <Button variant={'contained'} color={'primary'} onClick={handleClickYes}>
                Yes, That's correct!
              </Button>
              <Button variant={'contained'} color={'fill'} onClick={handleClickNo}>
                No, It is not
              </Button>
            </Container>
          }
      >
        <Container
            py={'8px'}
            pb={'148px'}
        >
          <Container
              py={'16px'}
              px={'20px'}
          >
            <Headline
                size={'large'}
                headline={`Is this a reservation made by\n${reservation?.clientName}?`}
                description={'Please check the details below'}
                headlineProps={{
                  whiteSpace: 'pre-wrap'
                }}
            />
          </Container>
          <Divider type={'box'} color={'fill'}/>
          <Area
              py={'8px'}
              title={'Reservation Info'}
              containerProps={{
                px: '20px'
              }}
          >
            <ValueList pairs={
              [
                {key: 'date', pair: ['Date', reservation?.date]},
                {key: 'tour', pair: ['Tour', reservation?.product]},
                {
                  key: 'travelers',
                  pair: ['Travelers', [['Adult', reservation?.adult], ['Kid', reservation?.kid], ['Infant', reservation?.infant]]
                      .filter(([, count]) => !!count)
                      .map(([label, count]) => `${label} ${count}`)
                      .join(' / ')]
                },
                {key: 'Platform', pair: ['Platform', reservation?.agency?.toUpperCase()]},
                {key: 'ID', pair: ['ID', reservation?.agencyCode?.toUpperCase()]},
              ]
            }/>
          </Area>
        </Container>

      </Page>
  )
}