import {useState} from 'react';
import useKupStore from '~kup/store';
import {Address} from '~kup/models/types.ts';
import Page from '~/components/Page';
import {useLocation, useNavigate} from 'react-router-dom';
import Container from '~/components/Container.tsx';
import Headline from '~/components/Headline.tsx';
import TextField, {TextFieldProps} from '~/components/TextField.tsx';
import Typography from '~/components/Typography.tsx';
import Button from '~/components/Button.tsx';
import Slide from '~/components/Slide';
import NaverMap from '~/components/NaverMap.tsx';
import Box from '~/components/Box';

export default function ShopAddressCheckPage() {
  const {
    setDeliveryAddress,
    setInputAddress,
    locale,
  } = useKupStore((state) => ({
    setDeliveryAddress: state.setDeliveryAddress,
    setInputAddress: state.setInputAddress,
    locale: state.auth?.locale ?? 'en-US',
  }));
  const navigate = useNavigate();
  const location = useLocation();
  const input = location?.state?.inputAddress as string;
  const address = location?.state?.address as Address;
  const [detail, setDetail] = useState<string>('');
  const [request, setRequest] = useState<string>('');
  const [step, setStep] = useState<0 | 1>(0);
  const center = {
    lat: address.location.latitude,
    lng: address.location.longitude,
  };

  const handleChangeDetail: TextFieldProps['onChange'] = (e) => {
    setDetail(e.target.value);
  };

  const handleChangeRequest: TextFieldProps['onChange'] = (e) => {
    setRequest(e.target.value);
  };

  const handleNext = () => {
    setStep(1);
  };

  const handleConfirm = () => {
    setDeliveryAddress({
      address,
      detail,
      request,
    });
    setInputAddress(input);
    navigate(-2);
  };

  return (
      <Page
          type={'modal'}
          fixedBottom={
            <Container
                bottom
                background={'paper'}
                pt={'8px'}
                sx={{
                  borderTopLeftRadius: '12px',
                  borderTopRightRadius: '12px',
                }}
            >{
                step === 0 && (
                    <Slide in={step === 0} direction={'left'} unmountOnExit mountOnEnter>
                      <div style={{zIndex: 200}}>
                        <Container
                            py={'12px'}
                            px={'20px'}
                        >
                          <Headline headline={address.displayName.text} description={address.formattedAddress}/>
                        </Container>
                        <Container
                            stackProps={{
                              column: true,
                              gap: '8px',
                            }}
                            py={'8px'}
                            px={'20px'}
                        >
                          <TextField
                              fullWidth
                              placeholder={'Detail Address Info (ex Room Number, floor...) '}
                              onChange={handleChangeDetail}
                          />
                          <Box>
                            <Typography variant={'FootNote'} color={'typography.secondary'} component={'p'}>
                              It may require in-person pickup at the lobby or entrance due to hotel policy or delivery circumstances.
                            </Typography>
                          </Box>
                        </Container>
                        <Container
                            py={'8px'}
                            px={'20px'}
                        >

                          <Button
                              fullWidth
                              onClick={handleNext}
                          >
                            Next
                          </Button>
                        </Container>
                      </div>
                    </Slide>)
            }
              <Slide in={step > 0} direction={'left'} unmountOnExit mountOnEnter>
                <div style={{zIndex: 200}}>
                  <Container
                      py={'12px'}
                      px={'20px'}
                  >
                    <Headline headline={'Any instructions or request for your delivery?'}/>
                  </Container>
                  <Container
                      stackProps={{
                        column: true,
                        gap: '8px',
                      }}
                      py={'8px'}
                      px={'20px'}
                  >
                    <TextField
                        fullWidth
                        placeholder={'Instruction for your delivery'}
                        onChange={handleChangeRequest}
                    />
                  </Container>
                  <Container
                      py={'8px'}
                      px={'20px'}
                  >
                    <Button
                        fullWidth
                        onClick={handleConfirm}
                    >
                      Confirm
                    </Button>
                  </Container>
                </div>
              </Slide>
            </Container>
          }
      >
        <Container>
          <NaverMap
              locale={locale}
              center={center}
              zoom={13}
              placeName={address.displayName.text}
              containerStyles={{width: '100%', height: 'calc(100vh - 250px)'}}/>
        </Container>
      </Page>
  );
}