import {useEffect, useState} from "react";
import useLoading from "~/hooks/useLoading.tsx";
import {IChatting, IChattingMessage} from "~kint/models/Chatting.ts";
import listenChatting from "~kint/controllers/listenChatting.ts";
import {getChatting} from "~kint/controllers/getChatting.ts";
import useKintStore from "~kint/store";

export type UseListenChattingMessage = {
  initialized: boolean,
  loading: boolean,
  chatting: IChatting | null,
  messages: IChattingMessage[],
};

export type ERROR = 'ACCESS EXPIRED' | 'INVALID RESERVATION' | 'UNEXPECTED ERROR'

export default function useListenChattingMessage(chattingId: string, onError?: (error: ERROR) => void): UseListenChattingMessage {
  const {reservation, participant} = useKintStore((state) => ({reservation: state.reservation, participant:state.participant}))
  const {startLoading, stopLoading, loading} = useLoading();
  const [initialized, setInitialized] = useState<boolean>(false);
  const [chatting, setChatting] = useState<IChatting | null>(null);
  const [messages, setMessages] = useState<IChattingMessage[]>([])

  useEffect(() => {
    if (!chattingId) return;
    startLoading();
    (async () => {

      if (!reservation) {
        onError?.('ACCESS EXPIRED')
        return;
      }

      const chatting: IChatting | null = await getChatting(chattingId);
      const isValid = true;
          // new Date(reservation?.date ?? "2000-01-01") > new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
      if (!isValid || !chatting || !participant || !chatting.participantIds?.includes(participant.id)) {
        onError?.("INVALID RESERVATION")
        return;
      }
      setChatting(chatting);

      listenChatting(chattingId, (messageList) => {
        setMessages(messageList);
      });

    })()
        .catch(() => {
          onError?.('UNEXPECTED ERROR');
        })
        .finally(() => {
          stopLoading();
          setInitialized(true);
        })

  }, [chattingId]);

  return {
    initialized,
    loading,
    chatting,
    messages
  }
}