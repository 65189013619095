export default function Delivery(props:{width?:string, height?:string}){
  const {width, height} = props;
    return (
        <svg width={width} height={height} viewBox="0 0 77 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M72.5562 10.773L70.252 4.20451L72.0774 4.02496L73.4988 8.49875L74.7257 4.51872C74.8055 4.27932 74.9402 4.07982 75.1297 3.92022C75.3292 3.76062 75.5537 3.66586 75.803 3.63594L77 3.51624L73.409 13.7655H71.5238L72.5562 10.773Z"
                fill={"currentColor"}/>
            <path
                d="M66.2891 4.20456L68.0846 3.83049V4.69832C68.304 4.36914 68.6332 4.07987 69.0721 3.83049C69.511 3.58112 69.9549 3.41653 70.4037 3.33673V5.52125H70.3888C70.2491 5.5013 70.1494 5.49133 70.0895 5.49133C69.7005 5.49133 69.3015 5.64096 68.8925 5.94021C68.6631 6.10978 68.4736 6.29432 68.324 6.49382C68.1843 6.69332 68.1045 6.84294 68.0846 6.94269V9.96511C68.0846 10.2245 67.9898 10.4439 67.8003 10.6235C67.6207 10.813 67.4013 10.9078 67.1419 10.9078H66.2891V4.20456Z"
                fill={"currentColor"}/>
            <path
                d="M64.0935 6.4639C63.9638 6.13472 63.7593 5.8654 63.48 5.65592C63.2107 5.44645 62.8716 5.34171 62.4626 5.34171C62.3329 5.34171 62.2332 5.3467 62.1633 5.35667C61.6746 5.41652 61.2905 5.57114 61.0112 5.82051C60.7419 6.05991 60.5374 6.37412 60.3978 6.76315L64.0935 6.4639ZM58.6621 7.60105C58.6621 6.5437 58.9564 5.65094 59.5449 4.92276C60.1334 4.18461 60.9264 3.73574 61.9239 3.57614C62.1334 3.54621 62.3429 3.53125 62.5524 3.53125C63.1708 3.53125 63.7144 3.67589 64.1833 3.96516C64.6621 4.24446 65.0311 4.65344 65.2905 5.19209C65.5598 5.72076 65.6945 6.3442 65.6945 7.0624C65.6945 7.29182 65.6845 7.46639 65.6646 7.58609V7.70578L60.4127 8.06489C60.5025 8.40404 60.707 8.72323 61.0262 9.02248C61.3554 9.31176 61.6746 9.45141 61.9838 9.44144H62.0736C62.2232 9.43146 62.3828 9.38158 62.5524 9.29181C62.7319 9.20203 62.9464 9.07735 63.1957 8.91775C63.3454 8.82797 63.505 8.78308 63.6745 8.78308C63.8641 8.78308 64.0436 8.83795 64.2132 8.94767L65.1409 9.47136L65.0661 9.5761C64.697 10.0649 64.2381 10.4439 63.6895 10.7132C63.1409 10.9826 62.5524 11.1172 61.9239 11.1172C61.2656 11.1172 60.687 10.9726 60.1883 10.6833C59.6995 10.394 59.3205 9.98507 59.0511 9.4564C58.7918 8.91775 58.6621 8.2993 58.6621 7.60105Z"
                fill={"currentColor"}/>
            <path
                d="M51.9453 4.20443L53.7707 4.05481L55.2371 8.33408L56.5687 4.42887C56.6286 4.23934 56.7333 4.08473 56.8829 3.96503C57.0425 3.84533 57.2221 3.77551 57.4216 3.75556L58.8131 3.65082L55.9253 10.9076H54.5637L51.9453 4.20443Z"
                fill={"currentColor"}/>
            <path
                d="M51.4085 3.80047V9.87525C51.4085 10.1545 51.3088 10.3939 51.1093 10.5934C50.9098 10.8029 50.6654 10.9077 50.3761 10.9077H49.4783V4.08476L51.4085 3.80047ZM49.2539 1.49625C49.2539 1.1172 49.3985 0.773063 49.6878 0.463838C49.9771 0.154613 50.3063 0 50.6753 0C50.9546 0 51.1841 0.0947626 51.3636 0.284287C51.5531 0.473812 51.6479 0.7182 51.6479 1.01745C51.6479 1.40647 51.5033 1.75061 51.214 2.04986C50.9247 2.34911 50.5906 2.49874 50.2115 2.49874C49.9422 2.49874 49.7128 2.40397 49.5232 2.21445C49.3437 2.02492 49.2539 1.78552 49.2539 1.49625Z"
                fill={"currentColor"}/>
            <path
                d="M48.2153 1.6908V9.98002C48.2153 10.2294 48.1255 10.4439 47.946 10.6234C47.7664 10.8129 47.547 10.9077 47.2876 10.9077H46.2852V2.33418L48.2153 1.6908Z"
                fill={"currentColor"}/>
            <path
                d="M44.0896 6.4639C43.9599 6.13472 43.7554 5.8654 43.4761 5.65592C43.2068 5.44645 42.8676 5.34171 42.4587 5.34171C42.329 5.34171 42.2293 5.3467 42.1594 5.35667C41.6707 5.41652 41.2866 5.57114 41.0073 5.82051C40.738 6.05991 40.5335 6.37412 40.3939 6.76315L44.0896 6.4639ZM38.6582 7.60105C38.6582 6.5437 38.9525 5.65094 39.541 4.92276C40.1295 4.18461 40.9225 3.73574 41.92 3.57614C42.1295 3.54621 42.339 3.53125 42.5484 3.53125C43.1669 3.53125 43.7105 3.67589 44.1794 3.96516C44.6582 4.24446 45.0272 4.65344 45.2866 5.19209C45.5559 5.72076 45.6906 6.3442 45.6906 7.0624C45.6906 7.29182 45.6806 7.46639 45.6606 7.58609V7.70578L40.4088 8.06489C40.4986 8.40404 40.7031 8.72323 41.0223 9.02248C41.3515 9.31176 41.6706 9.45141 41.9799 9.44144H42.0696C42.2193 9.43146 42.3789 9.38158 42.5484 9.29181C42.728 9.20203 42.9425 9.07735 43.1918 8.91775C43.3415 8.82797 43.5011 8.78308 43.6706 8.78308C43.8602 8.78308 44.0397 8.83795 44.2093 8.94767L45.137 9.47136L45.0621 9.5761C44.6931 10.0649 44.2342 10.4439 43.6856 10.7132C43.137 10.9826 42.5484 11.1172 41.92 11.1172C41.2617 11.1172 40.6831 10.9726 40.1844 10.6833C39.6956 10.394 39.3166 9.98507 39.0472 9.4564C38.7879 8.91775 38.6582 8.2993 38.6582 7.60105Z"
                fill={"currentColor"}/>
            <path
                d="M33.4468 9.11217C33.9157 9.09222 34.3246 8.94758 34.6738 8.67825C35.0329 8.39895 35.3072 8.02988 35.4967 7.57103C35.6862 7.11218 35.781 6.61343 35.781 6.07478C35.781 5.3067 35.6014 4.68327 35.2423 4.20447C34.8832 3.71569 34.3995 3.47131 33.791 3.47131C33.7212 3.47131 33.6064 3.48128 33.4468 3.50123L32.2798 3.69574V9.15705L33.4468 9.11217ZM30.3496 2.33416L33.4468 1.70573C33.6763 1.65586 33.9406 1.63092 34.2399 1.63092C34.9281 1.63092 35.5615 1.81047 36.1401 2.16957C36.7286 2.52867 37.1925 3.02742 37.5316 3.66582C37.8807 4.30422 38.0553 5.0274 38.0553 5.83538C38.0553 6.75308 37.8408 7.60095 37.4119 8.379C36.983 9.15705 36.4144 9.7755 35.7062 10.2344C34.998 10.6832 34.2448 10.9077 33.4468 10.9077H31.397C31.1077 10.9077 30.8583 10.8029 30.6489 10.5935C30.4494 10.394 30.3496 10.1496 30.3496 9.86029V2.33416Z"
                fill={"currentColor"}/>
            <path
                d="M24.0562 2.49876C24.1659 2.24939 24.3305 2.0449 24.55 1.8853C24.7694 1.7257 25.0138 1.63593 25.2831 1.61598L26.7494 1.49628V10.9077H24.4003V6.1945L22.4103 10.9077H20.5699L18.6996 6.44886V10.9077H16.6797V2.33418L18.6996 2.16959L21.4826 8.54361L24.0562 2.49876Z"
                fill={"currentColor"}/>
            <path
                d="M12.9853 6.25444C13.3444 6.21454 13.6237 6.04996 13.8232 5.76068C14.0326 5.46143 14.1374 5.10233 14.1374 4.68338C14.1374 4.27441 14.0326 3.95022 13.8232 3.71082C13.6237 3.46145 13.3444 3.3617 12.9853 3.41157L10.9952 3.71082V6.43399L12.9853 6.25444ZM9.03516 2.33427L12.9853 1.58615C13.1648 1.55622 13.3493 1.54126 13.5389 1.54126C14.0276 1.54126 14.4765 1.66595 14.8855 1.91532C15.3044 2.15472 15.6336 2.49387 15.873 2.93277C16.1124 3.37167 16.2321 3.87042 16.2321 4.42902C16.2321 5.05745 16.0825 5.64597 15.7832 6.1946C15.484 6.73324 15.085 7.17713 14.5862 7.52626C14.0875 7.86541 13.5538 8.04994 12.9853 8.07987L10.9952 8.19957V9.96514C10.9952 10.2245 10.9005 10.4439 10.711 10.6235C10.5314 10.813 10.312 10.9078 10.0526 10.9078H9.03516V2.33427Z"
                fill={"currentColor"}/>
            <path
                d="M3.03739 1.54126V10.0549C3.03739 10.2843 2.9526 10.4838 2.78302 10.6534C2.62342 10.823 2.42891 10.9078 2.19949 10.9078H1.10722V4.23451L0 4.45895V2.57367L3.03739 1.54126Z"
                fill={"currentColor"}/>
            <path
                d="M7.03739 1.54126V10.0549C7.03739 10.2843 6.9526 10.4838 6.78302 10.6534C6.62342 10.823 6.42891 10.9078 6.19949 10.9078H5.10722V4.23451L4 4.45895V2.57367L7.03739 1.54126Z"
                fill={"currentColor"}/>
        </svg>

    )
}