import Translation from "~/locales/Translation.ts";

const en:Translation = {
    "shop-main-byTomorrow": "by Tomorrow!",
    "shop-main-byToday": "by Today!",
    "shop-main-buyAnother": "Buy Another {{amount}}!",
    "shop-main-buyAmount": "Buy {{amount}}!",
    "shop-main-addAmountOrMore": "On orders over {{amount}}",
    "shop-main-cart": "CART",
    "shop-main-freeDelivery": "Free Delivery",
    "shop-main-free": "Free",
    "shop-main-ifYou": "If you",
    "shop-main-inYourCart": "{{amount}} in your basket",
    "shop-main-myOrder": "MY ORDER",
    "shop-main-whichHotelAreYou": "Which hotel are you staying at?",
    "reservation-welcome":"Welcome",
    "reservation-key-label":"Reservation Code",
    "reservation-key-placeholder":"Please types in your reservation code."
}

export default en;
