import {styled} from "@mui/material";
import {default as MuiCheckbox, CheckboxProps as MuiCheckboxProps} from '@mui/material/Checkbox';
import {
  default as MuiFormControlLabel,
  FormControlLabelProps as MuiFormControlLabelProps
} from "@mui/material/FormControlLabel";
import {ReactNode} from "react";


export type BareCheckboxProps = {
  width?: string,
  height?: string
  type?: 'box' | 'line',
} & MuiCheckboxProps


function BareCheckbox(props: BareCheckboxProps) {
  const {type = 'box', ...restProps} = props;
  return (
      <MuiCheckbox
          icon={
            type === 'box'
                ? (
                    <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H16C17.933 0.5 19.5 2.067 19.5 4V16C19.5 17.933 17.933 19.5 16 19.5H4C2.067 19.5 0.5 17.933 0.5 16V4Z"
                          stroke={restProps.disabled ? "currentColor" : "#000000"} opacity={'0.06'}/>
                      <path
                          d="M8.32218 12.3444L14.2389 6.42778C14.3505 6.31666 14.483 6.26111 14.6364 6.26111C14.7899 6.26111 14.9222 6.31686 15.0333 6.42836C15.1444 6.53986 15.2 6.67226 15.2 6.82557C15.2 6.97889 15.1444 7.1111 15.0333 7.22221L8.71106 13.5444C8.59996 13.6556 8.47034 13.7111 8.32218 13.7111C8.17404 13.7111 8.04441 13.6556 7.9333 13.5444L4.95551 10.5667C4.8444 10.455 4.79162 10.3225 4.79718 10.1691C4.80274 10.0156 4.86126 9.88332 4.97276 9.77221C5.08426 9.6611 5.21667 9.60554 5.36998 9.60554C5.5233 9.60554 5.65552 9.6611 5.76663 9.77221L8.32218 12.3444Z"
                          fill={restProps.disabled ? "currentColor" : "#000000"} opacity={'0.16'}/>
                    </svg>
                )
                : (
                    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M9.48316 15.5166L18.3582 6.6416C18.5256 6.47493 18.7244 6.3916 18.9546 6.3916C19.1847 6.3916 19.3832 6.47523 19.5498 6.64248C19.7165 6.80973 19.7998 7.00833 19.7998 7.2383C19.7998 7.46827 19.7165 7.66659 19.5498 7.83325L10.0665 17.3166C9.89984 17.4833 9.7054 17.5666 9.48316 17.5666C9.26095 17.5666 9.0665 17.4833 8.89984 17.3166L4.43316 12.8499C4.2665 12.6825 4.18733 12.4837 4.19566 12.2535C4.204 12.0233 4.29179 11.8249 4.45904 11.6583C4.62629 11.4916 4.8249 11.4083 5.05486 11.4083C5.28485 11.4083 5.48317 11.4916 5.64984 11.6583L9.48316 15.5166Z"
                          fill="#101010" opacity="0.3"/>
                    </svg>
                )

          }
          checkedIcon={
            type === 'box'
                ? (
                    <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4Z"
                          fill="currentColor"/>
                      <path
                          d="M8.32218 12.3444L14.2389 6.42778C14.3505 6.31666 14.483 6.26111 14.6364 6.26111C14.7899 6.26111 14.9222 6.31686 15.0333 6.42836C15.1444 6.53986 15.2 6.67226 15.2 6.82557C15.2 6.97889 15.1444 7.1111 15.0333 7.22221L8.71106 13.5444C8.59996 13.6556 8.47034 13.7111 8.32218 13.7111C8.17404 13.7111 8.04441 13.6556 7.9333 13.5444L4.95551 10.5667C4.8444 10.455 4.79162 10.3225 4.79718 10.1691C4.80274 10.0156 4.86126 9.88332 4.97276 9.77221C5.08426 9.6611 5.21667 9.60554 5.36998 9.60554C5.5233 9.60554 5.65552 9.6611 5.76663 9.77221L8.32218 12.3444Z"
                          fill="white"/>
                    </svg>
                )
                : (
                    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M9.48316 15.5166L18.3582 6.6416C18.5256 6.47493 18.7244 6.3916 18.9546 6.3916C19.1847 6.3916 19.3832 6.47523 19.5498 6.64248C19.7165 6.80973 19.7998 7.00833 19.7998 7.2383C19.7998 7.46827 19.7165 7.66659 19.5498 7.83325L10.0665 17.3166C9.89984 17.4833 9.7054 17.5666 9.48316 17.5666C9.26095 17.5666 9.0665 17.4833 8.89984 17.3166L4.43316 12.8499C4.2665 12.6825 4.18733 12.4837 4.19566 12.2535C4.204 12.0233 4.29179 11.8249 4.45904 11.6583C4.62629 11.4916 4.8249 11.4083 5.05486 11.4083C5.28485 11.4083 5.48317 11.4916 5.64984 11.6583L9.48316 15.5166Z"
                          fill="currentColor"/>
                    </svg>
                )
          }
          {...restProps}
      />
  )
}


const StyledCheckbox = styled(BareCheckbox)(({width, height,}) => ({
  width: width ?? '20px',
  height: height ?? '20px',
  padding: 0,
  '& .MuiTouchRipple-root .MuiTouchRipple-child': {
    borderRadius: '20%'
  }
}));


export type CheckboxWithLabelProps = {
  label: ReactNode,
  labelProps: Omit<MuiFormControlLabelProps, 'control' | 'label'>,
} & BareCheckboxProps

const StyledFormControlLabel = styled(MuiFormControlLabel)({
  margin: 0,
  "& > .MuiFormControlLabel-label": {
    marginLeft: '8px',
  }
})

function CheckboxWithLabel(props: CheckboxWithLabelProps) {
  const {labelProps, label, ...checkboxProps} = props;
  return (
      <StyledFormControlLabel control={<StyledCheckbox {...checkboxProps}/>} label={label} {...labelProps} />
  )
}

type CheckboxProps = CheckboxWithLabelProps | BareCheckboxProps;

function isCheckboxWithLabelProps(props: CheckboxProps): props is CheckboxWithLabelProps {
  return 'label' in props;
}

export default function Checkbox(props: CheckboxProps) {
  return isCheckboxWithLabelProps(props) ? <CheckboxWithLabel {...props} /> : <StyledCheckbox {...props}/>
}