export type WhatsAppLogoProps = {
  width?: string,
  height?: string,
}
export default function WhatsAppLogo(props: WhatsAppLogoProps) {
  const {width = '32px', height = '32px'} = props;
  return (
      <svg
          width={width}
          height={height}
          viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
              d="M0 10C0 4.47715 4.47715 0 10 0H30C35.5228 0 40 4.47715 40 10V30C40 35.5228 35.5228 40 30 40H10C4.47715 40 0 35.5228 0 30V10Z"
              fill="#2AB540"/>
          <g>
            <path
                d="M8.55286 32.8282C8.32711 32.8282 8.10708 32.7389 7.94412 32.5739C7.72893 32.3565 7.64617 32.0408 7.72694 31.7457L9.32027 25.9283C8.32397 24.0813 7.79828 22.0031 7.79629 19.8935C7.79629 19.888 7.79629 19.8826 7.79629 19.8772C7.79885 12.8557 13.5138 7.14307 20.5356 7.14307C23.9414 7.14449 27.1415 8.47099 29.5468 10.8777C31.952 13.2852 33.2757 16.4848 33.2748 19.8875C33.2717 26.9095 27.5573 32.6225 20.5358 32.6225C18.5221 32.6219 16.5301 32.1421 14.7473 31.2329L8.76975 32.8003C8.69812 32.8191 8.62506 32.8282 8.55286 32.8282ZM14.852 29.4643C14.9941 29.4643 15.1351 29.4997 15.2618 29.5688C16.8703 30.4458 18.6922 30.9095 20.5307 30.9101C26.6132 30.9101 31.5599 25.9651 31.5622 19.8869C31.5633 16.9417 30.4172 14.172 28.3353 12.088C26.2536 10.0047 23.4836 8.85655 20.5353 8.85541C14.4627 8.85541 9.5189 13.7921 9.50863 19.8632C9.50863 19.8683 9.50863 19.8732 9.50863 19.8783C9.50806 21.8144 10.0169 23.72 10.9801 25.3889C11.0945 25.587 11.1248 25.8227 11.0643 26.0433L9.77033 30.7677L14.6351 29.492C14.7064 29.4735 14.7792 29.4643 14.852 29.4643Z"
                fill="white"/>
            <path
                d="M17.5655 14.9116C17.3432 14.417 17.1089 14.4073 16.8975 14.3985C16.7242 14.391 16.5262 14.3916 16.3284 14.3916C16.1303 14.3916 15.8087 14.4658 15.5367 14.7629C15.2644 15.06 14.4973 15.7783 14.4973 17.239C14.4973 18.6999 15.5615 20.1114 15.7097 20.3098C15.8581 20.5078 17.7639 23.6015 20.7813 24.7912C23.2896 25.7804 23.7999 25.5835 24.3444 25.5341C24.8887 25.4847 26.101 24.8161 26.3484 24.1229C26.5959 23.4296 26.5959 22.8355 26.5217 22.7113C26.4475 22.5875 26.2494 22.5133 25.9526 22.3649C25.6555 22.2165 24.196 21.4981 23.9238 21.3988C23.6515 21.2998 23.4534 21.2504 23.2557 21.5478C23.0576 21.8446 22.4891 22.5133 22.3159 22.7113C22.1426 22.9097 21.9694 22.9345 21.6726 22.7861C21.3755 22.6371 20.4192 22.3238 19.2847 21.3126C18.402 20.5255 17.8061 19.5538 17.6329 19.2564C17.4597 18.9596 17.6144 18.7986 17.7633 18.6505C17.8966 18.5175 18.0604 18.3037 18.2088 18.1305C18.3569 17.957 18.4063 17.8334 18.5053 17.6354C18.6044 17.4373 18.555 17.2638 18.4808 17.1154C18.4063 16.967 17.8293 15.4986 17.5655 14.9116Z"
                fill="white"/>
          </g>
        </g>
      </svg>
  )
}