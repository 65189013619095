import Dialog from "~/components/Dialog.tsx";
import Container from "~/components/Container.tsx";
import Typography from "~/components/Typography.tsx";
import useKintStore from "~kint/store";
import useChatNotificationToken from "~/hooks/useChatNotificationToken.ts";
import useKupNotificationToken from "~/hooks/useKupNotificationToken.ts";


export default function TurnOnNotificationDialog(props: {
  open: boolean,
  onClose: () => void
}) {
  const {open, onClose} = props;
  const {participant} = useKintStore((state) => ({
    participant: state.participant,
  }));

  const {requestToken, enableNotification} = useKupNotificationToken();
  const {requestToken: requestChatToken, enableChatNotification} = useChatNotificationToken();


  const handleTurnOn = () => {
    enableNotification();
    requestToken();

    if (participant) {
      enableChatNotification();
      requestChatToken();
    }
    onClose();
  }

  return (
      <Dialog
          title={"Stay Updated with Important Information"}
          open={open}
          onClose={onClose}
          onConfirm={handleTurnOn}
          confirm={'Turn On'}
          onCancel={onClose}
          cancel={'Close'}
      >
        <Container
            px={'20px'}
            stackProps={{
              gap: '8px'
            }}
        >
          <Typography color={'typography.secondary'} whiteSpace={'pre-wrap'}>
            Get real-time updates. You can adjust your notification settings at any time.<br/>
            If you skip, chat notification requests will be hidden for 12 hours.
            <br/><br/>
            <strong>Previously blocked notifications for this device?</strong><br/>
            If you previously blocked notifications, you need to change the browser or app settings<br/>
            {'On iOS, go to Settings > Notifications > Kup and enable notifications.'}<br/>
            {'On Android, go to Settings > Apps & notifications > Kup > Notifications and enable them.'}<br/>
          </Typography>
        </Container>
      </Dialog>
  )
}