import {useNavigate} from "react-router-dom";
import recallCSChatting from "~kint/controllers/recallCSChatting.ts";
import {useSnackBar} from "~/contexts/SnackBarContext.tsx";
import useKintStore from "~kint/store";
import useLoading from "~/hooks/useLoading.tsx";
import Button, {ButtonProps} from "~/components/Button.tsx";

export default function RecallCsChatting(props: {
  children?: string,
  color?: ButtonProps<'button'>['color'],
  service: 'KUP' | 'CS'
}) {
  const {service = 'CS', children = '1:1 Support', color = 'primary'} = props;
  const {participant, reservation} = useKintStore((state) => ({
    participant: state.participant,
    reservation: state.reservation,
  }))
  const navigate = useNavigate();
  const {startLoading, stopLoading, loading} = useLoading();
  const {showSnackBar} = useSnackBar();

  function handleError() {
    showSnackBar({message: 'Cannot find user information, Please contact via our Email', lift: true})
  }

  const handleCallCS = () => {
    if (!participant || !reservation) {
      handleError()
      return;
    }

    (async () => {
      startLoading();
      return await recallCSChatting(participant, reservation, service);
    })()
        .then((chatting) => {
          if (!chatting) {
            handleError();
            return;
          }
          navigate(`/message/chatting/${chatting!.id}`, {replace: false})
        })
        .catch(() => {
          handleError();
        })
        .finally(stopLoading)
  }


  return (
      <>
        <Button fullWidth onClick={handleCallCS} loading={loading} color={color} size={'medium'}>
          {children}
        </Button>
      </>
  )
}