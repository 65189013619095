import { Promotion } from '~kup/models/Promotion.ts';
import { useCallback, useLayoutEffect, useState } from 'react';
import { getPromotions } from '~kup/controllers/promotion.ts';


type UsePromotions = {
  promotions: Promotion[]|null,
};

type PromotionSearchOptions = {
  isHighlight?: boolean,
}

export default function usePromotions(searchOptions: PromotionSearchOptions = {}): UsePromotions {
  const [promotions, setPromotions] = useState<Promotion[]|null>(null);
  const callPromotions = useCallback(() => {
    getPromotions({ ...searchOptions })
      .then((promotions) => {
        setPromotions(promotions);
      });
  }, []);

  useLayoutEffect(() => {
    callPromotions();
  }, []);

  return { promotions };
}
