import Badge, {BadgeProps} from "~/components/Badge.tsx";

export type StockBadgeProps ={
  stock:number
} & Omit<BadgeProps, 'children'>
export default function StockBadge(props:StockBadgeProps){
  const {stock, ...restProps} = props;
  return(
      stock === 0
          ? <Badge border={false} {...restProps}> Out of Stock </Badge>
          : stock < 3 ? <Badge color={stock === 0 ? 'error' : 'warning'} {...restProps}>Only {stock} left</Badge>
              : <Badge border={false} {...restProps}>{stock} left </Badge>
  )
}