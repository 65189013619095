import {ReactNode} from 'react';
import {createBrowserRouter, Navigate} from "react-router-dom";

import MainLayout from "~/layouts/MainLayout.tsx";
import BaseLayout from "~/layouts/BaseLayout.tsx";
import ShopMainPage from "~/pages/shop/main";
import ShopProductPage from "~/pages/shop/product";
import ShopBasketPage from "~/pages/shop/basket";
import ShopOrderPage from "~/pages/shop/order";
import ShopOrderTransactionPage from "~/pages/shop/order/transaction";

import ShopContactPage from "~/pages/shop/contact";
import ShopAddressPage from "~/pages/shop/address";
import ShopCategoryPage from "~/pages/shop/category";
import ShopTagPage from "~/pages/shop/tag";
import ShopAddressCheckPage from "~/pages/shop/address/check";
import ShopMyOrderPage from "~/pages/shop/myOrder";
import ShopMyOrderCancel from "~/pages/shop/myOrder/cancel";
import ShopMyOrdersPage from "~/pages/shop/myOrder/orders";

import MessageMainPage from "~/pages/message/main";
import MessageChattingPage from "~/pages/message/chat";
import MessageNotificationPage from '~/pages/message/notification';
import MessageNotificationsPage from '~/pages/message/notifications';

import ReservationMainPage from "~/pages/reservation/main";
import ReservationConfirmPage from "~/pages/reservation/confirm";
import ReservationNamePage from "~/pages/reservation/name";
import HomeMain from "~/pages/home/main";

import Icon from "~/components/Icon";
import TermsPage from "~/pages/agreements/terms.tsx";
import PrivacyPage from "~/pages/agreements/privacy.tsx";
import OrderAgreementsPage from "~/pages/agreements/orderAgreements.tsx";
import RouterErrorFallBack from "~/components/ErrorFallBack/RouterErrorFallBack.tsx";


const routes = [
  {
    navigation: false,
    path: '/terms',
    element: <TermsPage/>,
  },
  {
    navigation: false,
    path: '/privacy',
    element: <PrivacyPage/>
  }, {
    navigation: false,
    path: '/orderagreements',
    element: <OrderAgreementsPage/>
  },
  {
    navigation: false,
    path: '/reservation',
    element: <BaseLayout/>,
    ErrorBoundary: RouterErrorFallBack,
    children: [
      {
        path: '',
        element: <ReservationMainPage/>
      },
      {
        path: 'confirm',
        element: <ReservationConfirmPage/>
      },
      {
        path: 'name',
        element: <ReservationNamePage/>
      }
    ]
  },

  {
    navigation: true,
    name: 'message',
    label: 'Message',
    path: '/message',
    icon: <Icon width={'24px'}>message_navigation</Icon>,
    element: <BaseLayout/>, // 일부 페이지는 하단 네비게이션 표지 하지 아니함
    ErrorBoundary: RouterErrorFallBack,
    children: [
      {
        path: '',
        element: <MainLayout/>,
        children: [
          {
            path: '',
            element: <MessageMainPage/>
          },]
      },
      {
        path: "notification",
        element: <MessageNotificationsPage/>
      },
      {
        path: "notification/:notificationId",
        element: <MessageNotificationPage/>
      },
      {
        path: 'chatting/:chattingId',
        element: <MessageChattingPage/>
      }
    ]
  },
  {
    navigation: true,
    name: "home",
    label: 'Home',
    path: '/',
    icon: <Icon width={'24px'}>home_navigation</Icon>,
    element: <MainLayout/>,
    ErrorBoundary: RouterErrorFallBack,
    children: [
      {
        path: '',
        element: <HomeMain/>
      }
    ]
  },
  {
    navigation: true,
    name: "shop",
    label: "Picks",
    path: '/shop',
    icon: <Icon size={'24px'}>thumb_up</Icon>,
    ErrorBoundary: RouterErrorFallBack,
    element: (
        <BaseLayout hashPages={(hash) => {
          const splits = hash.replace('#', '').split('/');
          const type = splits[0];
          switch (type) {
            case 'category':
              return <ShopCategoryPage/>
            case 'tag':
              return <ShopTagPage/>
            default:
              return null;
          }
        }}/>
    ), // 일부 페이지는 하단 네비게이션 표지 하지 아니함
    children: [
      {
        path: 'product/:productId',
        element: <ShopProductPage/>

      },
      {
        path: 'basket',
        element: <ShopBasketPage/>

      },
      {
        path: 'order',
        element: <ShopOrderPage/>

      },
      {
        path: 'order/transaction',
        element: <ShopOrderTransactionPage/>

      },
      {
        path: 'orders/:orderId',
        element: <ShopMyOrderPage/>

      },
      {
        path: 'orders',
        element: <ShopMyOrdersPage/>

      },
      {
        path: 'orders/:orderId/cancellation',
        element: <ShopMyOrderCancel/>

      },
      {
        path: 'contact',
        element: <ShopContactPage/>

      },
      {
        path: 'address',
        element: <ShopAddressPage/>

      },
      {
        path: 'address/:addressId',
        element: <ShopAddressCheckPage/>

      },
      {
        path: '',
        element: <ShopMainPage hashPages={() => undefined}/>

      },
    ]
  },
  // {
  //   navigation: true,
  //   name: "discovery",
  //   label: "Discovery",
  //   path: '/discovery',
  //   icon: <Icon width={'24px'}>discovery_navigation</Icon>,
  //   element: <MainLayout/>,
  //   children: [
  //     {
  //       path: '',
  //       element: <DiscoveryMain/>
  //     }
  //   ]
  // },
  // {
  //   navigation: true,
  //   name: 'tour',
  //   label: 'Tour',
  //   path: '/tour',
  //   icon: <Icon width={'24px'}>tour_navigation</Icon>,
  //   element: <MainLayout/>,
  //   children: [
  //     {
  //       path: '',
  //       element: <TourMain/>
  //     }
  //   ]
  // },
  {
    path: '*',
    element: <Navigate to={'/'}/>,
  },
]

const router = createBrowserRouter(routes);
export default router;
export const navigations = routes
    .filter(r => r.navigation && r.name && r.label && r.path && r.icon)
    .map<{ name: string, label: string, path: string, icon: ReactNode }>((r) => ({
      name: r.name!, label: r.label!, path: r.path!, icon: r.icon!
    }))