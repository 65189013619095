export type LogoPerfumeProps = {
  width?:string,
  height?:string,
}
export default function IconPerfume(props:LogoPerfumeProps) {
  const {width ='32px', height='32px'} = props;
  return (
      <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M26 7.5H20V6.5H26V7.5Z" fill="#D4D6D6"/>
        <path
            d="M36 24.7188C36 32.7015 28.8366 36 20 36C11.1634 36 4 32.7015 4 24.7188C4 16.736 11.1634 11 20 11C28.8366 11 36 16.736 36 24.7188Z"
            fill="#E6E8E8"/>
        <path
            d="M16 10C16 8.89543 16.8954 8 18 8H22C23.1046 8 24 8.89543 24 10V12C24 13.1046 23.1046 14 22 14H18C16.8954 14 16 13.1046 16 12V10Z"
            fill="#D4D6D6"/>
        <path
            d="M18 7C18 5.89543 18.8954 5 20 5C21.1046 5 22 5.89543 22 7C22 8.10457 21.1046 9 20 9C18.8954 9 18 8.10457 18 7Z"
            fill="#E6E8E8"/>
        <path
            d="M19.5 7C19.5 6.72386 19.7239 6.5 20 6.5C20.2761 6.5 20.5 6.72386 20.5 7C20.5 7.27614 20.2761 7.5 20 7.5C19.7239 7.5 19.5 7.27614 19.5 7Z"
            fill="#D4D6D6"/>
        <path
            d="M7.12827 20.148C9.02043 19.8109 11.4803 19.9309 13.8404 21.7813C16.8096 24.1092 24.1176 24.0387 26.859 22.4294C28.4775 21.4792 30.5959 20.6696 33.1261 20.6836C33.6912 21.9636 34 23.3487 34 24.7945C34 31.3085 27.732 34 20 34C12.268 34 6 31.3085 6 24.7945C6 23.1446 6.40211 21.5739 7.12827 20.148Z"
            fill="#FFDE2F"/>
        <path
            d="M31 7C31 8.65685 29.6569 10 28 10C26.3431 10 25 8.65685 25 7C25 5.34315 26.3431 4 28 4C29.6569 4 31 5.34315 31 7Z"
            fill="#FFB9CE"/>
        <path
            d="M13 21C13 19.8954 13.8954 19 15 19H25C26.1046 19 27 19.8954 27 21V27C27 28.1046 26.1046 29 25 29H15C13.8954 29 13 28.1046 13 27V21Z"
            fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M15.2273 22.5C15.2273 22.0733 15.5733 21.7273 16 21.7273H24C24.4268 21.7273 24.7727 22.0733 24.7727 22.5C24.7727 22.9268 24.4268 23.2727 24 23.2727H16C15.5733 23.2727 15.2273 22.9268 15.2273 22.5Z"
              fill="#E6E8E8"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M15.2273 26C15.2273 25.5733 15.5733 25.2273 16 25.2273H22C22.4268 25.2273 22.7727 25.5733 22.7727 26C22.7727 26.4268 22.4268 26.7727 22 26.7727H16C15.5733 26.7727 15.2273 26.4268 15.2273 26Z"
              fill="#E6E8E8"/>
      </svg>

  )
}