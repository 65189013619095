export type IconImageAttachmentProps = {
  width?: string,
  height?: string,
}
export default function IconImageAttachment(props: IconImageAttachmentProps) {
  const {width = '32px', height = '32px'} = props;
  return (
      <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.66675 21C4.20842 21 3.816 20.8368 3.4895 20.5105C3.16317 20.184 3 19.7916 3 19.3333V4.66675C3 4.20842 3.16317 3.816 3.4895 3.4895C3.816 3.16317 4.20842 3 4.66675 3H13.65C13.8862 3 14.0841 3.08017 14.2438 3.2405C14.4034 3.40067 14.4833 3.59925 14.4833 3.83625C14.4833 4.07325 14.4034 4.27092 14.2438 4.42925C14.0841 4.58758 13.8862 4.66675 13.65 4.66675H4.66675V19.3333H19.3333V10.3667C19.3333 10.1306 19.4134 9.93267 19.5738 9.773C19.7341 9.61317 19.9327 9.53325 20.1695 9.53325C20.4065 9.53325 20.6042 9.61317 20.7625 9.773C20.9208 9.93267 21 10.1306 21 10.3667V19.3333C21 19.7916 20.8368 20.184 20.5105 20.5105C20.184 20.8368 19.7916 21 19.3333 21H4.66675ZM17.3333 6.68325H16.15C15.9138 6.68325 15.7159 6.60317 15.5563 6.443C15.3966 6.28267 15.3168 6.084 15.3168 5.847C15.3168 5.61017 15.3966 5.41258 15.5563 5.25425C15.7159 5.09592 15.9138 5.01675 16.15 5.01675H17.3333V3.83325C17.3333 3.59725 17.4134 3.39933 17.5738 3.2395C17.7341 3.07983 17.9327 3 18.1695 3C18.4065 3 18.6042 3.07983 18.7625 3.2395C18.9208 3.39933 19 3.59725 19 3.83325V5.01675H20.1833C20.4194 5.01675 20.6173 5.09683 20.777 5.257C20.9368 5.41733 21.0168 5.616 21.0168 5.853C21.0168 6.08983 20.9368 6.28742 20.777 6.44575C20.6173 6.60408 20.4194 6.68325 20.1833 6.68325H19V7.86675C19 8.10275 18.9198 8.30067 18.7595 8.4605C18.5993 8.62017 18.4008 8.7 18.1638 8.7C17.9268 8.7 17.7291 8.62017 17.5708 8.4605C17.4124 8.30067 17.3333 8.10275 17.3333 7.86675V6.68325ZM11.2333 16.2168L9.25 13.5918C9.16117 13.4806 9.05008 13.425 8.91675 13.425C8.78342 13.425 8.67225 13.4806 8.58325 13.5918L6.51675 16.3C6.40558 16.4445 6.39167 16.5918 6.475 16.7418C6.55833 16.8918 6.68333 16.9668 6.85 16.9668H17.1668C17.3278 16.9668 17.4486 16.8918 17.5293 16.7418C17.6098 16.5918 17.6 16.4445 17.5 16.3L14.6833 12.5418C14.5944 12.4306 14.4833 12.375 14.35 12.375C14.2167 12.375 14.1056 12.4306 14.0168 12.5418L11.2333 16.2168Z"
            fill="currentColor"/>
      </svg>

  )
}