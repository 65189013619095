export type IconBackProps = {
  width?: string,
  height?: string,
}
export default function IconBack(props: IconBackProps) {
  const {width = '32px', height = '32px'} = props;
  return (
      <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.06075 11.9547L14.6655 17.5595C14.8151 17.7091 14.8889 17.8911 14.8869 18.1054C14.8849 18.3197 14.8091 18.5057 14.6594 18.6634C14.5017 18.8211 14.3157 18.9 14.1014 18.9C13.8871 18.9 13.7011 18.8211 13.5433 18.6634L7.68384 12.81C7.56252 12.6887 7.47356 12.5532 7.41694 12.4036C7.36033 12.254 7.33203 12.1043 7.33203 11.9547C7.33203 11.8051 7.36033 11.6555 7.41694 11.5058C7.47356 11.3562 7.56252 11.2208 7.68384 11.0994L13.5555 5.2278C13.7132 5.07009 13.8982 4.99427 14.1105 5.00034C14.3228 5.0064 14.5078 5.08829 14.6655 5.246C14.8151 5.40371 14.892 5.5877 14.896 5.79798C14.9001 6.00826 14.8232 6.19226 14.6655 6.34997L9.06075 11.9547Z"
            fill="currentColor"/>
      </svg>

  )
}