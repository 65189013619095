export type LogoViberProps = {
  width?: string,
  height?: string,
}
export default function IconViber(props: LogoViberProps) {
  const {width = '32px', height = '32px'} = props;
  return (
      <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="40" height="40" rx="8" fill="#7360F2"/>
        <path
            d="M30.5674 8.73835C29.8693 8.11376 27.077 6.05628 20.7944 6.01954C20.7944 6.01954 13.4095 5.57865 9.80892 8.88531C7.78819 10.906 7.09011 13.8086 7.01663 17.4459C6.94315 21.0832 6.83294 27.8802 13.4095 29.754V32.583C13.4095 32.583 13.3728 33.722 14.1076 33.9424C15.0261 34.2364 15.5405 33.3546 16.4222 32.4361C16.8999 31.9217 17.5612 31.1869 18.0388 30.599C22.5212 30.9664 25.9748 30.1214 26.379 29.9744C27.2975 29.6805 32.4044 29.0192 33.2495 22.2222C34.1312 15.168 32.8821 10.7223 30.5674 8.73835ZM31.3389 21.6711C30.6409 27.3659 26.4892 27.7333 25.7176 27.9537C25.387 28.0639 22.3742 28.7987 18.5532 28.5783C18.5532 28.5783 15.7242 31.9952 14.8057 32.9137C14.6587 33.0607 14.5117 33.0974 14.4015 33.0974C14.2545 33.0607 14.2178 32.877 14.2178 32.6198C14.2178 32.2524 14.2545 27.917 14.2545 27.917C8.70671 26.3739 9.03737 20.5688 9.07411 17.5194C9.14759 14.4699 9.69871 12.0083 11.3888 10.3182C14.4383 7.56264 20.7209 7.96679 20.7209 7.96679C26.0116 8.00353 28.5467 9.58338 29.1345 10.1345C31.045 11.8246 32.037 15.8293 31.3389 21.6711Z"
            fill="white"/>
        <path d="M23.3657 17.2988C23.2922 15.9026 22.5941 15.1677 21.2346 15.0942Z" fill="white"/>
        <path d="M23.3657 17.2988C23.2922 15.9026 22.5941 15.1677 21.2346 15.0942" stroke="#7360F2"
              strokeWidth="1.92693" strokeLinecap="round" strokeLinejoin="round"/>
        <path
            d="M25.2038 17.8871C25.2406 16.6011 24.8364 15.4988 24.0648 14.6538C23.2565 13.772 22.1543 13.2943 20.7581 13.1841"
            fill="white"/>
        <path
            d="M25.2038 17.8871C25.2406 16.6011 24.8364 15.4988 24.0648 14.6538C23.2565 13.772 22.1543 13.2943 20.7581 13.1841"
            stroke="#7360F2" strokeWidth="1.92693" strokeLinecap="round" strokeLinejoin="round"/>
        <path
            d="M27.0401 18.622C27.0401 16.3807 26.342 14.6171 25.0193 13.3311C23.6965 12.0451 22.0432 11.3838 20.0591 11.3838"
            fill="white"/>
        <path
            d="M27.0401 18.622C27.0401 16.3807 26.342 14.6171 25.0193 13.3311C23.6965 12.0451 22.0432 11.3838 20.0591 11.3838"
            stroke="#7360F2" strokeWidth="1.92693" strokeLinecap="round" strokeLinejoin="round"/>
        <path
            d="M21.3081 22.0387C21.3081 22.0387 21.8225 22.0755 22.0797 21.7448L22.5941 21.0835C22.8513 20.7528 23.4391 20.5324 24.0637 20.863C24.3944 21.0467 24.9822 21.4141 25.3496 21.7081C25.7538 22.002 26.5621 22.6633 26.5621 22.6633C26.9662 22.994 27.0397 23.4716 26.7825 23.986C26.5253 24.4636 26.1579 24.9045 25.6803 25.3087C25.3129 25.6393 24.9455 25.7863 24.5781 25.8598H24.4311C24.2841 25.8598 24.1004 25.823 23.9535 25.7863C23.4024 25.6393 22.4838 25.2352 20.904 24.3901C19.912 23.839 19.067 23.2512 18.3689 22.7001C18.0015 22.4061 17.5973 22.0755 17.2299 21.6713L17.083 21.5244C16.6788 21.1202 16.3482 20.7528 16.0542 20.3854C15.5031 19.6873 14.9153 18.8423 14.3642 17.8503C13.5191 16.3072 13.115 15.3887 12.968 14.8008C12.9313 14.6539 12.8945 14.5069 12.8945 14.3232V14.1762C12.9313 13.8088 13.115 13.4414 13.4456 13.074C13.8498 12.6331 14.2907 12.2657 14.7683 11.9718C15.2827 11.7146 15.7603 11.7881 16.091 12.1922C16.091 12.1922 16.7523 13.0005 17.0462 13.4047C17.3034 13.7721 17.6708 14.36 17.8913 14.6906C18.2219 15.2785 18.0015 15.9031 17.7076 16.1602L17.0462 16.6746C16.7156 16.9318 16.7523 17.4462 16.7523 17.4462C16.7523 17.4462 17.6708 21.0835 21.3081 22.0387Z"
            fill="white"/>
      </svg>


  )
}