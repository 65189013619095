import { OrderHistory } from '~kup/models/Order.ts';
import { getOrderHistories } from '~kup/controllers/order.ts';
import { useCallback, useLayoutEffect, useState } from 'react';

type PageNumber = number; // 1 보다 크거나 같은 정수;

type UseHistories = {
  orderHistories: OrderHistory[],
  hasNextPage: boolean,
  page: number
  next: (targetPage?: number) => void,
  prev: () => void,
};

type OrderHistoriesPagination = {
  startPage?: PageNumber,
  pageSize?: number,
  continuous?: boolean
}

export default function useOrderHistories(pagination: OrderHistoriesPagination = {}): UseHistories {
  const { startPage = 1, pageSize = 6, continuous = true } = pagination;
  const [currentPage, setCurrentPage] = useState<PageNumber>(startPage);
  const [orderHistories, setOrderHistories] = useState<OrderHistory[]>([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);

  const callOrderHistories = useCallback((page: PageNumber, pageSize: number, continuous: boolean) => {
    getOrderHistories({ pageSize, page })
      .then(({ orderHistories, hasNextPage }) => {
        setOrderHistories((prev) => {
          if (continuous && page !== startPage) {
            return [...prev, ...orderHistories];
          }
          return [...orderHistories];
        });
        setHasNextPage(hasNextPage);
        setCurrentPage(page);
      });

  }, [startPage]);

  const next = useCallback((targetPage?: number) => {
    if (targetPage) {
      callOrderHistories(targetPage, pageSize, continuous);
      return;
    }
    if (!hasNextPage) return;
    callOrderHistories(currentPage + 1, pageSize, continuous);
  }, [hasNextPage, callOrderHistories, currentPage, pageSize, continuous]);

  const prev = useCallback(() => {
    const hasPrev = currentPage - 1 > 1;
    if (!hasPrev) return;
    callOrderHistories(currentPage - 1, pageSize, continuous);
  }, [currentPage, callOrderHistories, pageSize, continuous]);

  //최초 초기화 콜
  useLayoutEffect(() => {
    callOrderHistories(startPage, pageSize, continuous);
  }, [startPage, pageSize, continuous, callOrderHistories]);

  return {
    orderHistories,
    hasNextPage,
    page: currentPage,
    next,
    prev,
  };
}
