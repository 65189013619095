import {useEffect, useRef} from "react";

export default function useScrollIn(onIn?: () => void, onOut?:()=>void, threshold?: number) {
  const observerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!onIn) return;
    const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            onIn();
          }else{
            onOut?.()
          }
        },
        {threshold: threshold ?? 0.75}
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [onIn]);

  return observerRef;
}
