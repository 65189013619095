import {Slice} from "~common/store";

export interface CommonSlice {
}

export interface PersistedCommonSlice {
}

export const persistCommonSlice: (state: CommonSlice) => PersistedCommonSlice = () => ({
})


export const createCommonSlice: Slice<CommonSlice, CommonSlice> = () => ({

})

