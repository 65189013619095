export type TimeRemains = {
  hours: number,
  minutes: number,
  seconds: number,
  isPassedToday: boolean,
}

export default function getTimeRemainUntilKST(until: number): TimeRemains {
  const now = new Date();
  const utcNow = new Date(now.getTime() + now.getTimezoneOffset() * 60000);

  // KST is UTC+9
  const kstOffset = 9 * 60 * 60000;
  const kstNow = new Date(utcNow.getTime() + kstOffset);

  const tenPMKST = new Date(kstNow);
  tenPMKST.setHours(until, 0, 0, 0);

  // If it's already past 10 PM KST today, set the target time to 10 PM tomorrow KST
  const isPassedToday = kstNow.getTime() > tenPMKST.getTime();
  if (isPassedToday) {
    tenPMKST.setDate(tenPMKST.getDate() + 1);
  }

  const millisecondsUntilTenPM = tenPMKST.getTime() - kstNow.getTime();
  const seconds = Math.floor((millisecondsUntilTenPM / 1000) % 60);
  const minutes = Math.floor((millisecondsUntilTenPM / 1000 / 60) % 60);
  const hours = Math.floor((millisecondsUntilTenPM / (1000 * 60 * 60)) % 24);

  return {
    hours,
    minutes,
    seconds,
    isPassedToday,
  };
}