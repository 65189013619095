import NaverMap from "~/components/NaverMap.tsx";
import Container from "~/components/Container.tsx";
import Typography from "~/components/Typography.tsx";
import Button from "~/components/Button.tsx";
import Paper from "~/components/Paper.tsx";
import {ReservationPickupInfo} from "~kint/models/Reservation.ts";
import Headline from "~/components/Headline.tsx";

export default function HomePickupCard(props: { pickupInfo: Partial<ReservationPickupInfo>, locale: string }) {
  const {pickupInfo, locale} = props;
  if (!(pickupInfo.lat && pickupInfo.lng)) return null;
  return (
      <Container
          stackProps={{
            column: true,
            gap: '16px'
          }}
      >
        <Headline
            size={'medium'}
            headline={'Check your Pickup Place!'}
            description={'Bus departs on time. We recommend arriving 5 minutes early.'}
        />
        <Paper
            elevation={1}
            sx={{
              overflow: 'clip'
            }}
        >

          <NaverMap
              placeName={`Departure at ${pickupInfo.time}`}
              locale={locale}
              center={{lat: pickupInfo.lat, lng: pickupInfo.lng}}
              containerStyles={{width: '100%', height: '240px'}}
          />
          <Container
              pt={'4px'}
              pb={'12px'}
          >
            <Container
                px={'20px'}
                py={'8px'}
            >
              <Typography variant={'BaseM_B'} component={'p'}>
                {pickupInfo.en ?? ''}
              </Typography>
              <Typography variant={'BaseS_B'} color={'typography.secondary'} component={'p'}>
                {pickupInfo.cn ?? ''}
              </Typography>
            </Container>
            <Container
                px={'20px'}
                py={'8px'}
            >
              <Button fullWidth color={'fill'} size={'small'} component={'a'}
                      href={`https://www.google.com/maps/search/?api=1&query=${pickupInfo?.lat},${pickupInfo?.lng}`}
                      target={"_blank"}>
                Find on Google Map
              </Button>
            </Container>
          </Container>
        </Paper>
      </Container>

  )
}