import React, { useState} from 'react';
import Page from '~/components/Page';
import Button from '~/components/Button.tsx';
import CSPopup from '~/components/CSPopup.tsx';
import Spotlight from '~/components/Spotlight.tsx';
import Container from '~/components/Container.tsx';
import Icon from '~/components/Icon';
import IconButton from '~/components/IconButton.tsx';

const ErrorFallBackView: React.FC = () => {
  const [openCS, setOpenCS] = useState(false);

  const handleToggleCS = () => {
    setOpenCS((prevOpenCS) => !prevOpenCS);
  };

  return (
      <Page
          navigationBarProps={{
            lead: (
                <IconButton
                    touchArea={'16px'}
                    onClick={() => {
                      window.location.href = '/reservation';
                    }}
                    iconProps={{
                      width: '20px',
                      color: 'typography.tertiary',
                    }}
                >
                  close
                </IconButton>
            ),
          }}
      >
        <Container px={'20px'}>
          <Spotlight
              textAlign={'center'}
              lead={<Icon size={'60px'}>error</Icon>}
              headline="Something went wrong :("
              description={'An unexpected error occurred.\nPlease contact us if you need help.'}
              descriptionProps={{
                whiteSpace: 'pre-wrap',
              }}
              trail={
                <Button
                    size="small"
                    variant="contained"
                    color="fill"
                    sx={{
                      minWidth: '126px',
                    }}
                    onClick={handleToggleCS}
                >
                  Contact
                </Button>
              }
          />
        </Container>
        <CSPopup
            open={openCS}
            onClose={handleToggleCS}
            email="support@ktourstory.com"
            enableChat={false}
        />
      </Page>
  );
};

export default ErrorFallBackView;
