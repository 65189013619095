import {default as MuiBox} from '@mui/material/Box';
import {default as MuiDivider, DividerProps as MuiDividerProps} from '@mui/material/Divider';

type LineDivider = 'line';
type BoxDivider = 'box';

type DividerColors = 'fill' | 'divider'

export type DividerProps = MuiDividerProps & {
    type: LineDivider | BoxDivider,
    color?: DividerColors,
    thickness?: string,
}
export default function Divider(props: DividerProps) {
    const {type, color = 'divider', thickness} = props;
    return (
        type === 'box'
            ? (
                <MuiBox
                    sx={{
                        py: '4px'
                    }}
                >
                    <MuiBox
                        sx={(theme) => ({
                            height: thickness ?? '6px',
                            width: '100%',
                            backgroundColor: color === 'divider'
                                    ? theme.palette.divider
                                    : theme.palette.fill.primary
                        })}
                    />
                </MuiBox>
            )
            : <MuiDivider
                sx={(theme)=>({
                    borderBottomWidth:thickness,
                    borderBottomColor: color === 'divider'
                            ? theme.palette.divider
                            : theme.palette.fill.primary
                })}
            />
    )
}