import {ReactNode} from "react";
import styled from "~/components/styled.tsx";
import {default as MuiIcon, IconProps as MuiIconProps} from '@mui/material/Icon';
import {default as MuiBox} from '@mui/material/Box';
import {
  AllPredefinedColors,
  TypographyColorTrails,
  isColorName,
  isSystemColor,
  isTypographyColors,
  isFill, isMajorColors,
} from "~/components/commons/ColorNames";
import WhatsAppLogo from "./IconWhatsApp.tsx";
import LineLogo from "./IconLine.tsx";
import WechatLogo from "./IconWechat.tsx";
import KakaoTalkLogo from "./IconKakaoTalk.tsx";
import MessengerLogo from "./IconMessenger.tsx";
import IconViber from "./IconViber.tsx";
import IconGimbab from "./IconGimbab.tsx";
import LogoRibbon from "./IconRibbon.tsx";
import IconPencil from "./IconPencil.tsx";
import IconPerfume from "./IconPerfume.tsx";
import IconETC from "./IconETC.tsx";
import IconCosmetic from "./IconCosmetic.tsx";

import IconEnglish from "~/components/Icon/IconEnglish.tsx";
import IconChineseTraditional from "~/components/Icon/IconChineseTraditional.tsx";
import IconChineseSimplified from "~/components/Icon/IconChineseSimplified.tsx";
import IconHome from "~/components/Icon/IconHome.tsx";
import IconMessage from "~/components/Icon/IconMessage.tsx";
import IconShop from "~/components/Icon/IconShop.tsx";
import IconTour from '~/components/Icon/IconTour.tsx';
import IconDiscovery from "~/components/Icon/IconDiscovery.tsx";
import IconBasket from "~/components/Icon/IconBasket.tsx";
import IconSend from '~/components/Icon/IconSend.tsx';
import IconImageAttachment from "~/components/Icon/IconImageAttachment.tsx";
import IconBack from "~/components/Icon/IconBack.tsx";
import IconClose from "~/components/Icon/IconClose.tsx";
import IconSofa from "~/components/Icon/IconSofa.tsx";
import IconBed from "~/components/Icon/IconBed.tsx";
import IconTranslate from "~/components/Icon/IconTranslate.tsx";


type ColorWithNone = AllPredefinedColors | 'none';

export type IconProps = Omit<MuiIconProps, 'color' | 'sx'> & {
  type?: 'circle' | 'square'
  width?: string,
  size?: string,
  border?: boolean,
  color?: AllPredefinedColors,
  backgroundColor?: ColorWithNone,
  children: string
}

export type IconFrameProps = {
  color?: AllPredefinedColors,
  backgroundColor?: ColorWithNone,
  children: ReactNode
}

function IconFrame(props: IconFrameProps) {
  return <MuiBox {...props}/>
}


const StyledIconFrame = styled(IconFrame)(({theme, color, backgroundColor = 'none'}) => {

  const checkColorValue = (color: ColorWithNone) => {
    const tailingColor = color.split('.').at(-1) as TypographyColorTrails;
    return isTypographyColors(color) && tailingColor ? theme.palette.typography[tailingColor] : isFill(color) ? theme.palette.fill.primary : color
  };

  const checkColorPalette = (color: ColorWithNone) => isColorName(color) ? theme.palette[color] : isSystemColor(color) ? theme.palette.system[color] : null;
  const checkMajorColor = (color: ColorWithNone) => checkColorPalette(color.split('.')[0] as ColorWithNone);

  const backgroundColorVal = checkColorPalette(backgroundColor)?.main ?? checkColorValue(backgroundColor);
  const colorVal = color ? checkColorPalette(color)?.main
      ?? checkColorValue(color)
      : isTypographyColors(backgroundColor) ? 'white'
          : isMajorColors(backgroundColor)
              ? checkMajorColor(backgroundColor)?.contrastText
              : checkColorPalette(backgroundColor)?.main

  return ({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: colorVal,
    backgroundColor: backgroundColorVal,
  })
})


export default function Icon(props: IconProps) {
  const {
    type = 'circle',
    border = false,
    size = '1rem',
    color,
    width,
    backgroundColor,
    children
  } = props;


  switch (children.toLowerCase().replace(/_/gi, '')) {
    case 'imageattachment':
      return (
          <StyledIconFrame color={color} backgroundColor={backgroundColor}>
            <IconImageAttachment width={width} height={width}/>
          </StyledIconFrame>
      )
    case 'send':
      return (
          <StyledIconFrame color={color} backgroundColor={backgroundColor}>
            <IconSend width={width} height={width}/>
          </StyledIconFrame>
      )

    case 'back':
      return (
          <StyledIconFrame color={color} backgroundColor={backgroundColor}>
            <IconBack width={width} height={width}/>
          </StyledIconFrame>
      )
    case 'close':
      return (
          <StyledIconFrame color={color} backgroundColor={backgroundColor}>
            <IconClose width={width} height={width}/>
          </StyledIconFrame>
      )
    case 'shoppingbasket':
    case 'basket':
      return (
          <IconBasket width={width} height={width}/>
      )
    case 'homenavigation':
    case 'home':
      return (
          <IconHome width={width} height={width}/>
      )
    case 'discoverynavigation':
    case 'discovery':
      return (
          <IconDiscovery width={width} height={width}/>
      )
    case 'tournavigation':
    case 'tour':
      return (
          <IconTour width={width} height={width}/>
      )
    case 'messagenavigation':
    case 'message':
      return (
          <IconMessage width={width} height={width}/>
      )
    case 'shopnavigation':
    case 'shop':
      return (
          <IconShop width={width} height={width}/>
      )
    case 'whatsapp':
      return (
          <WhatsAppLogo width={width} height={width}/>
      );
    case 'line':
      return (
          <LineLogo width={width} height={width}/>
      );

    case 'wechat':
      return (
          <WechatLogo width={width} height={width}/>
      )

    case 'viber':
      return (
          <IconViber width={width} height={width}/>
      )

    case 'kakaotalk':
      return (
          <KakaoTalkLogo width={width} height={width}/>
      )

    case 'facebookmessenger' :
      return (
          <MessengerLogo width={width} height={width}/>
      )

    case 'gimbab' :
    case 'food' :
      return (
          <IconGimbab width={width} height={width}/>
      )

    case 'clothes':
    case 'ribbon' :
    case 'doll' :
      return (
          <LogoRibbon width={width} height={width}/>
      )

    case 'pencil':
      return (
          <IconPencil width={width} height={width}/>
      )

    case 'perfume':
    case 'alcohol':
      return (
          <IconPerfume width={width} height={width}/>
      )

    case 'etc':
      return (
          <IconETC width={width} height={width}/>
      )
    case 'living':
      return (
          <IconBed width={width} height={width}/>
      )
    case 'bed':
      return (
          <IconBed width={width} height={width}/>
      )

    case 'sofa':
      return (
          <IconSofa width={width} height={width}/>
      )

    case 'cosmetic':
    case 'cosmetics':
      return (
          <IconCosmetic width={width} height={width}/>
      )

    case 'english':
      return (
          <IconEnglish width={width} height={width}/>
      )

    case 'chinesetraditional':
      return (
          <IconChineseTraditional width={width} height={width}/>
      )
    case 'chinesesimplified':
      return (
          <IconChineseSimplified width={width} height={width}/>
      )

    case 'translate':
      return (
          <StyledIconFrame color={color}>
            <IconTranslate width={width} height={width}/>
          </StyledIconFrame>
      )

    case 'sms':
      return (
          <StandardIcon
              type={type}
              width={width}
              size={size}
              color={color}
              backgroundColor={backgroundColor}
          >
            sms
          </StandardIcon>
      )

    case 'tel':
      return (
          <StandardIcon
              type={type}
              width={width}
              size={size}
              color={color}
              backgroundColor={backgroundColor}
          >
            call
          </StandardIcon>
      )

    case 'others':
      return (
          <StandardIcon
              type={type}
              width={width}
              size={size}
              color={color}
              backgroundColor={backgroundColor}
          >
            more_horiz
          </StandardIcon>
      )


    case 'fancy' :
      return (
          <StandardIcon
              type={type}
              size={width}
              width={width}
              color={'primary'}
          >
            shopping_bag
          </StandardIcon>
      )


    default:
      return (
          border
              ? (
                  <OutlinedIcon
                      type={type}
                      width={width}
                      size={size}
                      color={color}
                      backgroundColor={backgroundColor}
                  >
                    {children}
                  </OutlinedIcon>
              )
              : (
                  <StandardIcon
                      type={type}
                      width={width}
                      size={size}
                      color={color}
                      backgroundColor={backgroundColor}
                  >
                    {children}
                  </StandardIcon>
              )
      )
  }
}


export type StandardIconProps = Omit<IconProps, 'type' | 'border'> & {
  type: 'circle' | 'square'
};

export function StandardIcon(props: StandardIconProps) {
  const {
    type,
    width,
    size,
    color,
    backgroundColor = 'none',
    children,
  } = props

  return (
      <MuiIcon
          sx={(theme) => {
            const borderRadius = type === 'circle' ? '100%' : '4px';

            const checkColorValue = (color: ColorWithNone) => {
              const tailingColor = color.split('.').at(-1) as TypographyColorTrails;
              return isTypographyColors(color) && tailingColor ? theme.palette.typography[tailingColor] : isFill(color) ? theme.palette.fill.primary : color
            };

            const checkColorPalette = (color: ColorWithNone) => isColorName(color) ? theme.palette[color] : isSystemColor(color) ? theme.palette.system[color] : null;
            const checkMajorColor = (color: ColorWithNone) => checkColorPalette(color.split('.')[0] as ColorWithNone);

            const backgroundColorVal = checkColorPalette(backgroundColor)?.main ?? checkColorValue(backgroundColor);
            const colorVal = color ? checkColorPalette(color)?.main
                ?? checkColorValue(color)
                : isTypographyColors(backgroundColor) ? 'white'
                    : isMajorColors(backgroundColor)
                        ? checkMajorColor(backgroundColor)?.contrastText
                        : checkColorPalette(backgroundColor)?.main

            const dimension = width ? width : `min(calc(${size} + 4px), 48px)`;

            return ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius,
              backgroundColor: backgroundColorVal,
              color: colorVal,
              fontSize: size,
              width: dimension,
              height: dimension,
            })
          }}
      >
        {children}
      </MuiIcon>
  )
}

export type OutlinedIconProps = Omit<IconProps, 'type' | 'border'> & {
  type: 'circle' | 'square'
};

export function OutlinedIcon(props: OutlinedIconProps) {
  const {
    type,
    width,
    size,
    color,
    backgroundColor = 'inherit',
    children,
  } = props


  return (
      <MuiIcon
          sx={(theme) => {
            const borderRadius = type === 'circle' ? '100%' : '20px';

            const checkColorValue = (color: ColorWithNone) => {
              const tailingColor = color.split('.').at(-1) as TypographyColorTrails;
              return isTypographyColors(color) && tailingColor ? theme.palette.typography[tailingColor] : isFill(color) ? theme.palette.fill.primary : color
            };

            const checkColorPalette = (color: ColorWithNone) => isColorName(color) ? theme.palette[color] : isSystemColor(color) ? theme.palette.system[color] : null;

            const backgroundColorVal = checkColorPalette(backgroundColor)?.light ?? checkColorValue(backgroundColor);
            const colorVal = color ? checkColorPalette(color)?.main ?? checkColorValue(color) : isTypographyColors(backgroundColor) ? 'white' : checkColorPalette(backgroundColor)?.main
            const borderColorVal = colorVal;


            const dimension = width ? width : `min(max(calc(${size} * 1.5), calc(${size} + 4px)), 48px)`;

            return ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: 'solid 1px',
              borderRadius,
              borderColor: borderColorVal,
              backgroundColor: backgroundColorVal,
              color: colorVal,
              fontSize: size,
              width: dimension,
              height: dimension,

            })
          }}
      >
        {children}
      </MuiIcon>
  )
}
