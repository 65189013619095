import useKupStore from "~kup/store";

type UseDelivery = {
  threshold: number,
  isFree: boolean,
  fee: number,
  fulfilled: number,
  fulfilledPercentage: number,
  left: number,
  leftPercentage: number,
}
export default function useDelivery(fulfilled: number): UseDelivery {
  const {
    threshold,
    deliveryFee,
  } = useKupStore(state => ({threshold: state.freeDeliveryThreshold, deliveryFee: state.deliveryFee}));
  const isFree = fulfilled > threshold;
  const left = threshold - fulfilled;
  const fulfilledPercentage = Math.min(fulfilled / threshold, 1);
  const leftPercentage = 1 - fulfilledPercentage;
  const fee = left > 0 ? deliveryFee : 0;
  return (
      {
        fulfilled,
        fulfilledPercentage,
        left,
        leftPercentage,
        threshold,
        isFree,
        fee,
      }
  )
}