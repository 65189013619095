import Container from "~/components/Container.tsx";
import Stack from "~/components/Stack.tsx";
import Button from "~/components/Button.tsx";
import FillBox from "~/components/FillBox.tsx";
import ImageView from "~/components/ImageView.tsx";
import Headline from "~/components/Headline.tsx";
import Popover from "~/components/ModalPopover.tsx";
import {BasketProductItem} from "~kup/models/Basket.ts";
import useLoading from "~/hooks/useLoading.tsx";
import useKupStore from "~kup/store";


export type RemoveItemsPopoverProps = {
  open?: boolean,
  onClose: () => void,
  onRemoved: () => void,
  removeItems: BasketProductItem[]
}

export default function RemoveItemsPopover(props: RemoveItemsPopoverProps) {
  const {open, onClose, onRemoved, removeItems} = props;
  const {basket, setBasket} = useKupStore();
  const {loading, startLoading, stopLoading} = useLoading();

  const handleRemove = () => {
    startLoading()
    basket.removeItems(removeItems)
        .then(setBasket)
        .then(onRemoved)
        .finally(stopLoading);
  }

  return <Popover open={open}
                  title={'Remove selected items?'}
                  onClose={onClose}
                  fixedBottomPosition={'sticky'}
                  fixedBottom={
                    <Container
                        pb={'28px'}
                        pt={'12px'}
                        px={'20px'}
                        background={'paper'}
                        sx={() => ({ zIndex: 1,})}
                    >
                      <Stack
                          column
                          gap={'8px'}
                      >
                        <Button
                            loading={loading}
                            variant={'contained'}
                            color={'error'}
                            onClick={handleRemove}
                            size={'medium'}
                        >
                          Remove
                        </Button>
                        <Button
                            disabled={loading}
                            variant={'contained'}
                            color={'fill'}
                            size={'medium'}
                        >
                          Cancel
                        </Button>
                      </Stack>
                    </Container>
                  }
  >
    <Container
        px={'20px'}
        pb={'8px'}
    >
      <Stack
          column
          gap={'8px'}
      >
        {
          removeItems.map((item) => {
            const key = 'remove' + item.key;
            return (
                <FillBox
                    key={key}
                    color={'fill'}
                    lead={item.thumbnail && <ImageView borderRadius={'8px'} width={'60px'} src={item.thumbnail}/>}
                    layoutBoxProps={{gap: '16px'}}
                >
                  <Headline
                      subHeadline={item.productName}
                      headline={item.optionSelectionName}
                      description={`${item.stock.quantity} left`}
                  />
                </FillBox>
            );
          })
        }
      </Stack>
    </Container>
  </Popover>
}