import {ElementType} from "react";
import {default as MuiButton, ButtonProps as MuiButtonProps,} from "@mui/material/Button";
import {default as MuiCircularProgress} from "@mui/material/CircularProgress"

type Size = 'small' | 'medium' | 'large';
export type ButtonProps<E extends ElementType> = {
  disabled?: boolean
  size?: Size,
  loading?: boolean
  component?: E
} & MuiButtonProps<E>

const progressSizes: { [size in Size]: number } = {
  'large': 28,
  'medium': 24,
  'small': 22
}

export default function Button<E extends ElementType>(props: ButtonProps<E>) {
  const {children, loading, disabled = false, size = 'large', variant = 'contained', ...restProps} = props;
  const progressSize = progressSizes[size];
  return (
      <MuiButton
          {...restProps}
          variant={variant}
          disabled={disabled || loading}
          size={size}
      >
        {
          loading
              ? <MuiCircularProgress size={progressSize} color={'inherit'}/>
              : children
        }
      </MuiButton>
  )
}
