import {useTranslation} from "react-i18next";
import Paper from "~/components/Paper.tsx";
import ButtonBase from "~/components/ButtonBase.tsx";
import Typography from "~/components/Typography.tsx";
import useKupStore from "~kup/store";
import {useNavigate} from "react-router-dom";


export default function HeaderMenu() {
  const {t} = useTranslation();
  const {basket} = useKupStore();
  const navigate = useNavigate();
  const handleMoveToBasket = ()=>{
    navigate('/shop/basket');
  }
  const handleMoveToMyOrders = ()=>{
    navigate('/shop/orders');
  }
  return (

      <Paper
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
      >
        <ButtonBase
            sx={(theme) => ({
              display: "block",
              width: "100%",
              flex: 1,
              paddingY: "12px",
              "&::after": {
                right: 0,
                content: "\"\"",
                position: "absolute",
                height: "calc(100% - 24px)",
                width: "1px",
                backgroundColor: theme.palette.divider,
              },
            })}
            onClick={handleMoveToMyOrders}
        >
          <Typography
              component={"span"}
              variant={"BaseS_B"}
              color={"text.secondary"}
              fontWeight={'500'}
          >
            {t("shop-main-myOrder")}
          </Typography>
        </ButtonBase>
        <ButtonBase
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              flex: 1,
              paddingY: "12px",
            }}
            onClick={handleMoveToBasket}
        >
          <Typography
              component={"span"}
              variant={"BaseS_B"}
              color={"text.secondary"}
          >
            {t("shop-main-cart")}
          </Typography>
          <Typography
              component={"span"}
              variant={"Caption_B"}
              sx={(theme) => ({
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "18px",
                minHeight: "18px",
                background: theme.palette.primary.main,
                borderRadius: "100%",
                color: theme.palette.primary.contrastText,
                marginLeft: "8px",
              })}
          >
            {basket.count}
          </Typography>
        </ButtonBase>
      </Paper>
  );
}
