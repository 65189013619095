export default function Discovery(props:{width?:string, height?:string}){
  const {width, height} = props;
  return (
      <svg width={width} height={height} viewBox="0 0 81 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M73.6825 15.6522L70.3347 6.10871L72.9869 5.84783L75.0521 12.3478L76.8347 6.56523C76.9507 6.2174 77.1463 5.92755 77.4217 5.69566C77.7115 5.46378 78.0376 5.3261 78.3999 5.28262L80.1391 5.1087L74.9217 20H72.1825L73.6825 15.6522Z"
            fill={"currentColor"} />
        <path
            d="M63.9003 6.10871L66.509 5.56523V6.8261C66.8279 6.34784 67.3061 5.92755 67.9438 5.56523C68.5815 5.20291 69.2264 4.96378 69.8786 4.84784V8.02176H69.8569C69.654 7.99277 69.509 7.97828 69.4221 7.97828C68.8569 7.97828 68.2771 8.19567 67.6829 8.63045C67.3496 8.87683 67.0743 9.14495 66.8569 9.4348C66.654 9.72466 66.538 9.94205 66.509 10.087V14.4783C66.509 14.8551 66.3713 15.1739 66.096 15.4348C65.8351 15.7102 65.5163 15.8479 65.1395 15.8479H63.9003V6.10871Z"
            fill={"currentColor"} />
        <path
            d="M60.7098 9.39137C60.5214 8.91311 60.2243 8.5218 59.8185 8.21745C59.4272 7.91311 58.9344 7.76093 58.3402 7.76093C58.1518 7.76093 58.0069 7.76818 57.9054 7.78267C57.1953 7.86963 56.6373 8.09427 56.2315 8.45658C55.8402 8.80441 55.5431 9.26094 55.3402 9.82615L60.7098 9.39137ZM52.8185 11.0435C52.8185 9.50731 53.246 8.21021 54.1011 7.15224C54.9562 6.07977 56.1083 5.4276 57.5576 5.19571C57.862 5.15223 58.1663 5.13049 58.4707 5.13049C59.3692 5.13049 60.1591 5.34064 60.8402 5.76093C61.5359 6.16673 62.0721 6.76093 62.4489 7.54354C62.8402 8.31166 63.0359 9.21746 63.0359 10.2609C63.0359 10.5943 63.0214 10.8479 62.9924 11.0218V11.1957L55.362 11.7175C55.4924 12.2102 55.7895 12.674 56.2533 13.1088C56.7315 13.5291 57.1953 13.732 57.6446 13.7175H57.775C57.9924 13.703 58.2243 13.6305 58.4707 13.5001C58.7315 13.3696 59.0431 13.1885 59.4054 12.9566C59.6228 12.8262 59.8547 12.7609 60.1011 12.7609C60.3765 12.7609 60.6373 12.8406 60.8837 13.0001L62.2315 13.7609L62.1228 13.9131C61.5866 14.6233 60.9199 15.174 60.1228 15.5653C59.3257 15.9566 58.4707 16.1522 57.5576 16.1522C56.6011 16.1522 55.7605 15.9421 55.0359 15.5218C54.3257 15.1015 53.775 14.5073 53.3837 13.7392C53.0069 12.9566 52.8185 12.058 52.8185 11.0435Z"
            fill={"currentColor"} />
        <path
            d="M43.7373 6.10867L46.3895 5.89128L48.5199 12.1087L50.4547 6.43476C50.5417 6.1594 50.6938 5.93476 50.9112 5.76084C51.1431 5.58693 51.404 5.48548 51.6938 5.45649L53.7156 5.30432L49.5199 15.8478H47.5417L43.7373 6.10867Z"
            fill={"currentColor"} />
        <path
            d="M38.9299 8.06521C38.2778 8.06521 37.727 8.31884 37.2778 8.82608C36.843 9.33333 36.6256 10.0145 36.6256 10.8696C36.6256 11.6522 36.8067 12.2609 37.1691 12.6957C37.5459 13.1304 38.0241 13.3478 38.6038 13.3478C39.256 13.3478 39.7995 13.1015 40.2343 12.6087C40.6691 12.1159 40.8864 11.4348 40.8864 10.5652C40.8864 9.76811 40.698 9.15217 40.3212 8.71739C39.9589 8.2826 39.4951 8.06521 38.9299 8.06521ZM34.0821 11.0435C34.0821 10 34.2777 9.07971 34.6691 8.2826C35.0748 7.4855 35.6256 6.84782 36.3212 6.36956C37.0169 5.8913 37.8067 5.58695 38.6908 5.45651C38.9807 5.41303 39.2488 5.3913 39.4951 5.3913C40.3357 5.3913 41.0893 5.5797 41.756 5.95651C42.4372 6.33333 42.9734 6.8913 43.3647 7.63043C43.756 8.36956 43.9517 9.27536 43.9517 10.3478C43.9517 11.5217 43.7125 12.5362 43.2343 13.3913C42.7705 14.2319 42.1328 14.8696 41.3212 15.3044C40.5241 15.7536 39.6473 15.9783 38.6908 15.9783C37.8067 15.9783 37.0169 15.7826 36.3212 15.3913C35.6256 15.0145 35.0748 14.4565 34.6691 13.7174C34.2777 12.9783 34.0821 12.087 34.0821 11.0435Z"
            fill={"currentColor"} />
        <path
            d="M29.8953 7.97827C29.2141 7.97827 28.6417 8.23914 28.1779 8.76088C27.7286 9.26813 27.504 9.95654 27.504 10.8261C27.504 11.6232 27.6924 12.2391 28.0692 12.6739C28.446 13.1087 28.9243 13.3261 29.504 13.3261C29.7938 13.3261 30.0909 13.2681 30.3953 13.1522C30.7141 13.0218 30.9895 12.8406 31.2214 12.6087C31.4533 12.3768 31.7431 12.2609 32.0909 12.2609C32.2214 12.2609 32.3518 12.2826 32.4823 12.3261L34.1996 12.9783C33.7504 13.9493 33.1272 14.6884 32.3301 15.1957C31.533 15.7174 30.6199 15.9783 29.5909 15.9783C28.7069 15.9783 27.917 15.7826 27.2214 15.3913C26.5257 15.0145 25.975 14.4565 25.5692 13.7174C25.1779 12.9783 24.9822 12.087 24.9822 11.0435C24.9822 9.49277 25.4098 8.21016 26.2648 7.19566C27.1199 6.18117 28.2286 5.55798 29.5909 5.32609C29.8808 5.26812 30.1924 5.23914 30.5257 5.23914C31.3373 5.23914 32.0547 5.43479 32.6779 5.82609C33.3156 6.2174 33.8228 6.79711 34.1996 7.56523L31.6562 8.93479C31.4967 8.61595 31.2504 8.37682 30.917 8.2174C30.5982 8.05798 30.2576 7.97827 29.8953 7.97827Z"
            fill={"currentColor"} />
        <path
            d="M19.1033 13.2391C19.5815 13.4275 20.0888 13.5217 20.625 13.5217C20.9583 13.5217 21.2409 13.4782 21.4728 13.3913C21.7192 13.3043 21.8714 13.1739 21.9294 13C21.9728 12.8695 21.9946 12.7536 21.9946 12.6522C21.9946 12.4203 21.9149 12.2391 21.7554 12.1087C21.596 11.9782 21.4149 11.8913 21.212 11.8478C21.154 11.8188 20.9583 11.7753 20.625 11.7174C20.3062 11.6594 19.9438 11.5797 19.538 11.4782C18.9583 11.3478 18.4511 11.1304 18.0163 10.8261C17.6685 10.6087 17.4076 10.3188 17.2337 9.9565C17.0743 9.57969 16.9946 9.17389 16.9946 8.73911C16.9946 8.1449 17.1395 7.61592 17.4293 7.15215C17.7917 6.58693 18.2699 6.13766 18.8641 5.80432C19.4728 5.47099 20.2047 5.30432 21.0598 5.30432C21.7554 5.30432 22.4076 5.4565 23.0163 5.76084C23.6395 6.0507 24.1685 6.41302 24.6033 6.8478L23.0598 8.5652C22.7265 8.21737 22.3496 7.96375 21.9294 7.80433C21.5091 7.63041 21.1105 7.54346 20.7337 7.54346C20.3424 7.54346 20.038 7.61592 19.8207 7.76085C19.6612 7.8623 19.538 7.97824 19.4511 8.10867C19.3641 8.22462 19.3207 8.3478 19.3207 8.47824C19.3207 8.62317 19.3714 8.74636 19.4728 8.8478C19.6033 8.97824 19.7627 9.07969 19.9511 9.15215C20.1395 9.21012 20.4583 9.30433 20.9076 9.43476C21.2699 9.52172 21.7337 9.65216 22.2989 9.82607C23.125 10.1449 23.712 10.5217 24.0598 10.9565C24.4076 11.3768 24.5815 11.8695 24.5815 12.4348C24.5815 12.913 24.4656 13.4275 24.2337 13.9782C24.0163 14.529 23.6105 15.0145 23.0163 15.4348C22.4366 15.8551 21.6467 16.0652 20.6467 16.0652C19.7627 16.0652 19.0018 15.913 18.3641 15.6087C17.7409 15.3043 17.2699 15.029 16.9511 14.7826C16.6322 14.5362 16.4438 14.3913 16.3859 14.3478L16.8206 13.5652C16.9366 13.3478 17.1033 13.1811 17.3206 13.0652C17.538 12.9348 17.7699 12.8696 18.0163 12.8696C18.2482 12.8696 18.4583 12.9203 18.6467 13.0217L19.1033 13.2391Z"
            fill={"currentColor"} />
        <path
            d="M15.1736 5.52175V14.3478C15.1736 14.7536 15.0287 15.1015 14.7388 15.3913C14.449 15.6957 14.0939 15.8478 13.6736 15.8478H12.3692V5.93479L15.1736 5.52175ZM12.0432 2.17392C12.0432 1.62319 12.2533 1.12319 12.6736 0.673914C13.0939 0.224638 13.5721 0 14.1084 0C14.5142 0 14.8475 0.137681 15.1084 0.413044C15.3837 0.688407 15.5214 1.04348 15.5214 1.47826C15.5214 2.04348 15.3113 2.54348 14.891 2.97827C14.4707 3.41305 13.9852 3.63044 13.4345 3.63044C13.0432 3.63044 12.7098 3.49276 12.4345 3.2174C12.1736 2.94203 12.0432 2.59421 12.0432 2.17392Z"
            fill={"currentColor"} />
        <path
            d="M4.50001 13.2391C5.18117 13.2102 5.77537 13 6.28262 12.6087C6.80436 12.2029 7.20291 11.6667 7.47827 11C7.75363 10.3333 7.89132 9.60871 7.89132 8.8261C7.89132 7.71016 7.63045 6.80436 7.10871 6.1087C6.58697 5.39856 5.88407 5.04349 5.00001 5.04349C4.89856 5.04349 4.73189 5.05798 4.50001 5.08696L2.80435 5.36957V13.3044L4.50001 13.2391ZM0 3.39131L4.50001 2.47826C4.83334 2.4058 5.2174 2.36957 5.65218 2.36957C6.65218 2.36957 7.57247 2.63044 8.41306 3.15218C9.26813 3.67392 9.94204 4.39856 10.4348 5.32609C10.942 6.25363 11.1957 7.30436 11.1957 8.47827C11.1957 9.81161 10.8841 11.0435 10.2609 12.1739C9.6377 13.3044 8.81161 14.2029 7.78262 14.8696C6.75363 15.5218 5.65943 15.8478 4.50001 15.8478H1.52174C1.10145 15.8478 0.739132 15.6957 0.434783 15.3913C0.144928 15.1015 0 14.7464 0 14.3261V3.39131Z"
            fill={"currentColor"} />
      </svg>

  )
}