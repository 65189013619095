import Stack from './Stack';
import Container from './Container';
import {Typography} from "@mui/material";
import {TypographyProps} from "~/components/Typography.tsx";
import {ReactNode} from "react";

type ValueListItemProps = {
  label: string|ReactNode,
  value: string|ReactNode,
  subValue?: string|ReactNode,
  labelProps?: TypographyProps
  valueProps?: TypographyProps
  subValueProps?: TypographyProps
}

export default function ValuePair(props: ValueListItemProps) {
  const {label, value, subValue, labelProps = {}, valueProps = {}, subValueProps = {color:'typography.tertiary'}} = props;
  return (
      <Container
          py={'12px'}
          px={'20px'}
      >
        <Stack
            row
            justifyContent={'stretch'}
        >
          <Typography
              flex={0}
              variant={'BaseS'}
              color={'typography.secondary'}
              {...labelProps}
          >
            {label}
          </Typography>
          <Stack
              column
              flex={1}
          >
            <Typography
                variant={'BaseM_B'}
                color={'typography.primary'}
                textAlign={'right'}
                {...valueProps}
            >
              {value}
            </Typography>
            <Typography
                variant={'Caption'}
                color={'typography.primary'}
                textAlign={'right'}
                {...subValueProps}
            >
              {subValue}
            </Typography>
          </Stack>
        </Stack>
      </Container>
  )
}