
export type PromotionType = 'ON_TOUR' | 'DURATION' | 'QUANTITY';

export interface PromotionResponse {
  id: string;
  type: PromotionType;
  title: string;
  subtitle: string;
  description: string;
  promotionImageUrls: string[];
  isActive: boolean;
  startDate?: Date;
  endDate?: Date;
}

export class Promotion {
  id: string;
  type: PromotionType;
  title: string;
  subtitle: string;
  description: string;
  promotionImageUrls: string[];
  isActive: boolean;
  startDate?: Date;
  endDate?: Date;

  constructor(raw: PromotionResponse) {
    this.id = raw.id;
    this.type = raw.type;
    this.title = raw.title;
    this.subtitle = raw.subtitle;
    this.description = raw.description;
    this.promotionImageUrls = raw.promotionImageUrls;
    this.isActive = raw.isActive;
    this.startDate = raw.startDate? new Date(raw.startDate) : undefined;
    this.endDate = raw.endDate? new Date(raw.endDate) : undefined;
  }
}
