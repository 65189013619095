import Dialog from '~/components/Dialog';
import Typography from '~/components/Typography';
import Container from "~/components/Container.tsx";

export type PayOnDeliveryConfirmProps = {
  open: boolean,
  onConfirm: () => void,
  onClose: () => void
}

export default function PayOnDeliveryConfirm(props: PayOnDeliveryConfirmProps) {
  const {open, onConfirm, onClose} = props;
  return (
      <Dialog
          title={'Pay on delivery between 9PM ~ 12AM'}
          open={open}
          onClose={onClose}
          onConfirm={onConfirm}
      >
        <Container
            px={'20px'}
            stackProps={{
              gap: '8px'
            }}
        >
          <Typography color={'typography.secondary'}>
            The delivery will take place between
            <Typography color={'primary'} component={'span'} sx={{px: '4px'}} fontWeight={'bold'}>
              8 PM and 11 PM,
            </Typography>
            and payment is made in person upon delivery.
          </Typography>
          <Typography color={'typography.secondary'}>
            If we cannot reach you or if delivery cannot be made within
            <Typography color={'primary'} component={'span'} sx={{px: '4px'}} fontWeight={'bold'}>15 minutes of
              arrival,
            </Typography> the order may be automatically refunded.
          </Typography>
          <Typography color={'typography.secondary'}>
            We will contact you before delivery.
          </Typography>
          <Typography color={'typography.secondary'} fontWeight={'bold'}>
            Please check your contact information and notification settings.
          </Typography>
        </Container>
      </Dialog>
  )
}